import { ReactNode } from "react";

interface Props {
  visible: boolean;
  setVisibility: (v: boolean) => void;
  children: ReactNode;
  className?: string;
}

const Modal = ({ visible, setVisibility, className, children }: Props) => {
  return (
    <dialog className={`modal ${visible && "modal-open"}`}>
      <div className={`modal-box ${className}`}>
        <form method="dialog">
          <button
            onClick={() => setVisibility(false)}
            className="btn btn-circle btn-ghost btn-sm fixed right-2 top-2"
          >
            ✕
          </button>
        </form>

        {children}
      </div>
    </dialog>
  );
};

export default Modal;
