import { useRef } from "react";
import Auth from "./components/Auth";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Search from "./components/Search";
import Statistics from "./components/Statistics";
import Stories from "./components/Stories";
import Online from "./components/Online";

const Landing = () => {
  const storiesRef = useRef<HTMLDivElement>(null);
  const statisticsRef = useRef<HTMLDivElement>(null);

  function scrollToStatistics() {
    if (statisticsRef.current) {
      statisticsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  function scrollToStories() {
    if (storiesRef.current) {
      storiesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <div className="no-scrollbar mb-5 overflow-auto">
      {/* first section, app hero */}
      <Hero />

      {/* online users */}
      <Online />

      {/* app auth */}
      <Auth />

      {/* app search */}
      <Search />

      {/* app success stories */}
      <Stories reference={storiesRef} />

      {/* app download links */}
      {/* <AppDownload /> */}

      {/* app statistics  */}
      <Statistics reference={statisticsRef} />

      {/* footer */}
      <Footer
        scrollToStatistics={scrollToStatistics}
        scrollToStories={scrollToStories}
      />
    </div>
  );
};

export default Landing;
