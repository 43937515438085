import { useQuery } from "@tanstack/react-query";
import utils from "api/utils";
import ms from "ms";
import TAds from "types/TAds";
import queryClient from "./queryClient";

export const refreshAdsQuery = () =>
  queryClient.invalidateQueries({ queryKey: ["ads"] });

const useAds = () => {
  const query = useQuery({
    queryKey: ["ads"],
    queryFn: () => utils.ads().then((res) => res.data),
    staleTime: ms("2h"),
  });

  const setAds = (ads: TAds[]) => {
    queryClient.setQueryData<TAds[]>(["ads"], () => ads);
  };

  return {
    setAds,
    ads: query.data!,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export default useAds;
