import List from "components/lists/List";

const ChatSkeletons = ({ length = 10 }: { length?: number }) => {
  const data = Array.from({ length }, (_, index) => index);

  return (
    <div className="">
      <List
        data={data}
        keyExtractor={(_, index) => index.toString()}
        renderItem={(index) => {
          const isSender = index % 2 === 0; // Alternating left/right bubbles for sender and receiver

          return (
            <div
              className={`mx-10 my-4 flex animate-pulse ${
                isSender ? "justify-end" : "justify-start"
              }`}
            >
              {!isSender && (
                <div className="mr-2 flex-shrink-0">
                  <span className="block h-8 w-8 rounded-full bg-base-300"></span>
                </div>
              )}

              <div className={`ml-4 w-[60%] ${isSender ? "text-right" : ""}`}>
                <div className="h-8 rounded-lg bg-base-300"></div>
                <div className="mt-2 h-4 w-[40%] rounded-lg bg-base-300"></div>
              </div>

              {isSender && (
                <div className="ml-2 flex-shrink-0">
                  <span className="block h-8 w-8 rounded-full bg-base-300"></span>
                </div>
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

export default ChatSkeletons;
