import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/ar";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);
dayjs.extend(relativeTime);
dayjs.locale("ar");

function getTimeZone() {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone;
}

export const dd_mm_yyyy = (time: string) => dayjs(time).format("DD-MM-YYYY");

export const mm_dd_yyyy = (time: string) => dayjs(time).format("MM-DD-YYYY");

export const timeAgo = (time: string) => dayjs(time).fromNow();

export const amPm_12h = (date: string) =>
  dayjs(date)
    .format("YYYY-MM-DD hh:mm a")
    .replace("am", "ص")
    .replace("pm", "م");

export const amPm_12hTime = (date: string) =>
  dayjs(date).format("hh:mm a").replace("am", "ص").replace("pm", "م");

export const amPm = (date: string) =>
  dayjs(date)
    .tz(getTimeZone())
    .format("YYYY-MM-DD hh:mm a")
    .replace("am", "ص")
    .replace("pm", "م");

export const timeFormat = (time: string) => {
  return amPm(time) === "Invalid Date" ? amPm_12h(time) : amPm(time);
};
