import chatsApi from "api/chats";
import EmptyPage from "components/Empty";
import ChatListItem from "components/lists/ChatListItem";
import List from "components/lists/List";
import UserSkeletons from "components/theme/skeleton/users";
import constants from "configs/constants";
import useBadges from "queries/badges";
import useChats, { readMessage } from "queries/chats";
import useUser from "queries/user";
import { BiChat } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import TChat from "types/TChat";
import hasKeys from "utils/hasKeys";
import sort from "utils/sort";
import { timeAgo } from "utils/time";

interface IChatClicked {
  _id: string;
  receiver: string;
  sender: string;
  seen: boolean;
}

const ChatsPage = () => {
  const { user } = useUser();
  const { setBadges } = useBadges();
  const { chats, isLoading, isError, error, ...actions } = useChats();
  const navigate = useNavigate();

  const onChatClick = ({ _id, receiver, sender, seen }: IChatClicked) => {
    if (sender === receiver && !seen) {
      chatsApi.read(_id);
      readMessage(receiver);
      setBadges((b) => ({ ...b, chats: Math.max(0, b.chats - 1) }));
    }
    navigate(`/thread/${receiver}/${_id}`);
  };

  if (isLoading) return <UserSkeletons />;
  if (isError) throw new Error(error?.name);
  if (!chats.length)
    return <EmptyPage content={constants.EMPTY_EMAIL_LIST} Icon={BiChat} />;

  return (
    <div>
      <List<TChat>
        infinite
        hasNextPage={actions.hasNextPage}
        next={actions.fetchNextPage}
        data={sort.chatMessages(chats)}
        title={constants.BAR_CONVERSATIONS}
        keyExtractor={(item) => item._id.toString()}
        renderItem={({ _id, messageSeen, receiver, lastMessage }) => {
          if (!lastMessage || !hasKeys(lastMessage)) return null;

          const isMe = lastMessage.user._id == user._id;
          const senderName = isMe ? "أنت" : receiver.name;
          const seen = isMe ? true : messageSeen;
          const message = `${senderName}: ${
            !lastMessage.visible ? constants.MESSAGE_REMOVED : lastMessage?.text
          }`;

          return (
            <ChatListItem
              isRead={seen}
              received={isMe}
              title={receiver.name}
              guardian={receiver.is_guardian}
              sent={isMe && messageSeen}
              image={receiver.profile_picture}
              time={timeAgo(lastMessage.createdAt)}
              online={receiver.is_active && receiver.is_online}
              subTitle={message}
              onClick={() => {
                onChatClick({
                  _id,
                  receiver: receiver._id,
                  sender: lastMessage.user._id,
                  seen,
                });
              }}
            />
          );
        }}
      />
    </div>
  );
};

export default ChatsPage;
