import { useQuery } from "@tanstack/react-query";
import search from "api/search";
import ms from "ms";
import queryClient from "./queryClient";

export const refreshMatchedQuery = () =>
  queryClient.invalidateQueries({ queryKey: ["matched"] });

const useMatched = () => {
  const query = useQuery({
    queryKey: ["matched"],
    queryFn: () => search.matched().then((res) => res.data),
    staleTime: ms("2h"),
  });

  return {
    matched: query.data!,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export default useMatched;
