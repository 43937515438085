import TUser from "types/TUser";
import UserItem from "types/UserItem";

const isRemoved = (user: UserItem | TUser) => {
  if (user.is_removed || user.status === "canceled")
    return `تم حظر هذا الحساب لمخالفته ضوابط وقوانين عمل الموقع`;

  return "";
};

export default isRemoved;
