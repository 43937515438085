import configs from "api/configs";
import legals from "api/legals";
import Loading from "components/theme/Loading";
import InitQueries from "queries/init";
import { ReactNode, useEffect, useState } from "react";
import Socket from "./Socket";

interface Props {
  children: ReactNode;
}

const App = ({ children }: Props) => {
  const [ready, setReady] = useState(false);
  const { isLoading, isError } = InitQueries();
  const showLoading = isLoading || isError || !ready;

  const getContact = async () => {
    if (!localStorage.getItem("contacts")) {
      const response = await legals.contacts();
      if (response.ok) localStorage.setItem("contacts", response.data || "");
    }
  };

  const getPrivacy = async () => {
    if (!localStorage.getItem("privacy")) {
      const response = await legals.privacy();
      if (response.ok) localStorage.setItem("privacy", response.data || "");
    }
  };

  const getTerms = async () => {
    if (!localStorage.getItem("terms")) {
      const response = await legals.terms();
      if (response.ok) localStorage.setItem("terms", response.data || "");
    }
  };

  const getAbout = async () => {
    if (!localStorage.getItem("about")) {
      const response = await legals.about();
      if (response.ok) localStorage.setItem("about", response.data || "");
    }
  };

  const getImages = async () => {
    if (!localStorage.getItem("avatars")) {
      const maleImages = await configs.avatars("male");
      const femaleImages = await configs.avatars("female");

      if (maleImages.ok && femaleImages.ok)
        localStorage.setItem(
          "avatars",
          JSON.stringify({ male: maleImages.data, female: femaleImages.data }),
        );
    }
  };

  const getOptions = async () => {
    const opts = localStorage.getItem("options");
    const isLegacy = opts && JSON.parse(opts).general;

    if (!opts || isLegacy) {
      const response = await configs.options();
      if (response.ok)
        localStorage.setItem("options", JSON.stringify(response.data));
    }
  };

  const getCountries = async () => {
    if (!localStorage.getItem("countries")) {
      const response = await configs.countries();

      if (response.ok)
        localStorage.setItem(
          "countries",
          JSON.stringify(
            response.data!.map((country) => ({
              label: country.name,
              value: country.code,
              cities: country.cities,
            })),
          ),
        );
    }
  };

  useEffect(() => {
    (async () => {
      // get app info (terms, privacy, about)
      await getTerms();
      await getContact();
      await getPrivacy();
      await getAbout();

      // get static user pictures
      await getImages();

      // get options data for building accounts
      await getOptions();

      // get countries data
      await getCountries();

      setReady(true);
    })();
  }, []);

  return (
    <Socket>
      {showLoading ? <Loading visible bg="bg-white" /> : children}
    </Socket>
  );
};

export default App;
