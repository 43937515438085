import { useEffect, useState } from "react";
import { timeAgo } from "utils/time";

interface Props {
  time: string;
  className?: string;
}

function TimeAgo({ time, className }: Props) {
  const [lastSeen, setLastSeen] = useState(timeAgo(time));

  useEffect(() => {
    const interval = setInterval(() => {
      setLastSeen(timeAgo(time));
    }, 60000);

    return () => clearInterval(interval);
  }, [time]);

  return <span className={`text-primary ${className}`}>{`${lastSeen}`}</span>;
}

export default TimeAgo;
