import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
} from "@tanstack/react-query";
import chats from "api/chats";
import { UpdateUser } from "api/types/users";
import users from "api/users";
import dayjs from "dayjs";
import { chunk } from "lodash";
import ms from "ms";
import Badges from "types/Badges";
import GotMessage from "types/GotMessage";
import { default as Message, default as TMessage } from "types/Message";
import TChat from "types/TChat";
import TUser from "types/TUser";
import UserItem from "types/UserItem";
import queryClient from "./queryClient";
import hasKeys from "utils/hasKeys";

interface Update {
  receiver?: UserItem;
  lastMessage?: TMessage;
  messageSeen?: boolean;
}

interface UpdateChat {
  userId: string;
  update: (c: TChat) => Update;
}

interface Receiver {
  id: string;
  type: string;
  receiver: string;
  message: string;
}

interface Delete {
  userId: string;
  messageId: string;
  message?: Message;
  fromAll?: boolean;
}

const LIMIT_CHAT_LIST = 20;

const isQueryExist = () => {
  const hasQuery = queryClient.getQueryData<InfiniteData<Array<TChat>>>([
    "chats",
  ]);

  if (!hasQuery) return false;

  return !!hasQuery.pages;
};

export const refreshChatsQuery = () => {
  queryClient.invalidateQueries({ queryKey: ["chats"] });
};

const setBadge = (set: (badges: Badges) => Badges) => {
  const badges = queryClient.getQueryData<Badges>(["badges"])!;
  queryClient.setQueryData<Badges>(["badges"], () => set(badges));
};

const newMessage = ({ messageId, message, type, sender }: GotMessage) => {
  return {
    _id: messageId,
    text: message,
    createdAt: dayjs().toString(),
    sent: false,
    type,
    received: true,
    reported: false,
    visible: true,
    unsent: false,
    user: sender,
  };
};

const useChats = () => {
  const query = useInfiniteQuery({
    queryKey: ["chats"],
    queryFn: ({ pageParam }) => chats.get(pageParam).then((res) => res.data!),
    getNextPageParam: (lastPage, allPage) => {
      return lastPage.length === LIMIT_CHAT_LIST
        ? allPage.length + 1
        : undefined;
    },
    select: (data) => data.pages.flatMap((x) => x),
    initialPageParam: 1,
    staleTime: ms("2h"),
  });

  const send = ({ id, type, receiver, message }: Receiver) => {
    const sender = queryClient.getQueryData<TUser>(["user"])!;

    return updateChat({
      userId: receiver,
      update: (chat) =>
        ({
          ...chat,
          messageSeen: false,
          lastMessage: newMessage({
            messageId: id,
            message,
            type,
            sender,
            chatId: chat._id,
          }),
        }) as TChat,
    });
  };

  const { mutate: remove } = useMutation({
    mutationFn: chats.unsent,

    onMutate: ({ userId, messageId, message }: Delete) => {
      return updateChat({
        userId,
        update: (chat) => ({
          ...chat,
          lastMessage:
            chat.lastMessage?._id === messageId
              ? message || ({} as Message)
              : chat.lastMessage,
        }),
      });
    },
  });

  return {
    ...query,
    send,
    remove,
    chats: query.data!,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export const addNewChat = (props: { chatId: string; receiver: UserItem }) => {
  if (!isQueryExist()) return [];

  return queryClient.setQueryData<InfiniteData<Array<TChat>>>(
    ["chats"],
    (chats) => {
      const result = [
        {
          _id: props.chatId,
          messageSeen: true,
          receiver: props.receiver,
          lastMessage: {},
        } as TChat,
        ...chats!.pages.flat(),
      ];
      return { ...chats!, pages: chunk(result, LIMIT_CHAT_LIST) };
    },
  );
};

export const updateChatUser = (userId: string, update: UpdateUser) => {
  if (!isQueryExist()) return;

  const results = queryClient.setQueryData<InfiniteData<Array<TChat>>>(
    ["chats"],
    (chats) => {
      const result = chats!.pages
        .flat()
        .map((c) =>
          c.receiver._id === userId
            ? { ...c, receiver: { ...c.receiver, ...update } }
            : c,
        );
      return { ...chats!, pages: chunk(result, LIMIT_CHAT_LIST) };
    },
  );

  return results?.pages.flat();
};

export const updateChat = ({ userId, update }: UpdateChat) => {
  if (!isQueryExist()) return [];

  return queryClient
    .setQueryData<InfiniteData<Array<TChat>>>(["chats"], (chats) => {
      const result = chats!.pages
        .flat()
        .map((c) => (c.receiver._id === userId ? (update(c) as TChat) : c));
      return { ...chats!, pages: chunk(result, LIMIT_CHAT_LIST) };
    })
    ?.pages.flat();
};

export const addChatMessage = async (got: GotMessage) => {
  if (!isQueryExist()) return setBadge((b) => ({ ...b, chats: 1 }));

  const me = queryClient.getQueryData<TUser>(["user"])!;
  const user = queryClient
    .getQueryData<InfiniteData<Array<TChat>>>(["chats"])!
    .pages.flat()
    .find((c) => c.receiver._id === got.sender._id)?.receiver;

  // create new chat
  if (!user) {
    const { ok, data: senderInfo } = await users.getById(got.sender._id);
    if (ok) {
      const receiver = { ...senderInfo!.profile, ...senderInfo!.account };
      const newChat = {
        _id: got.chatId,
        messageSeen: false,
        receiver,
        lastMessage: newMessage(got),
      };

      queryClient.setQueryData<InfiniteData<Array<TChat>>>(
        ["chats"],
        (chats) => ({
          ...chats!,
          pages: chunk(
            [newChat as TChat, ...chats!.pages.flat()],
            LIMIT_CHAT_LIST,
          ),
        }),
      );

      setBadge((b) => ({ ...b, chats: b.chats + 1 }));
    }
  }
  // add new message
  else {
    const result = updateChat({
      userId: got.sender._id,
      update: (chat) =>
        ({
          ...chat,
          lastMessage: newMessage(got),
          messageSeen: false,
        }) as TChat,
    });

    setBadge((b) => ({
      ...b,
      chats: (result || []).length
        ? result!.filter(
            (c) =>
              !c.messageSeen &&
              hasKeys(c.lastMessage) &&
              c.lastMessage?.user._id !== me._id,
          ).length
        : b.chats + 1,
    }));

    return result;
  }
};

export const readMessage = (userId: string) => {
  return updateChat({
    userId,
    update: (chat) => ({ ...chat, messageSeen: true }),
  });
};

export const hideMessage = ({ userId, messageId, message }: Delete) => {
  return updateChat({
    userId,
    update: (chat) => ({
      ...chat,
      lastMessage:
        chat.lastMessage?._id === messageId
          ? message || ({} as Message)
          : chat.lastMessage,
    }),
  });
};

export const reportMessage = ({ userId, messageId }: Delete) => {
  return updateChat({
    userId,
    update: (chat) => ({
      ...chat,
      lastMessage:
        chat.lastMessage?._id === messageId
          ? { ...chat.lastMessage, reported: true }
          : chat.lastMessage,
    }),
  });
};

export default useChats;
