import { useForm as Form, Resolver } from "react-hook-form";
import { AnyObject } from "yup";

interface Props<T extends AnyObject> {
  defaultValues?: any;
  resolver: Resolver<T>;
}

function useForm<T extends AnyObject>(props: Props<T>) {
  const {
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = Form<T>({
    resolver: props.resolver,
    defaultValues: props.defaultValues,
  });

  // useEffect(() => {
  //   const subscription = watch((value, { name, type }) => {
  //     if (props.defaultValues) {
  //       if (type === "change" && !value[name as keyof typeof value]) {
  //         const resetValues = { ...value, [name as string]: "" };
  //         reset(resetValues as T, { keepDefaultValues: false });
  //       }
  //     }
  //   });
  //   return () => subscription.unsubscribe();
  // }, [watch, reset, props.defaultValues]);

  return { handleSubmit, control, errors, setError };
}

export default useForm;
