import React, { forwardRef, useImperativeHandle, useState } from "react";
import ShortUniqueId from "short-unique-id";
import { FiRefreshCw } from "react-icons/fi";
import constants from "configs/constants";
import classNames from "classnames";

export interface CaptchaRef {
  isValid: boolean;
  refreshCaptcha: () => void;
  setError: (v: boolean) => void;
}

const random4Digit = () =>
  new ShortUniqueId({ length: 4, dictionary: "alphanum" }).rnd();

const Captcha = forwardRef((_, ref) => {
  const [captchaValue, setCaptchaValue] = useState(random4Digit());
  const [inputValue, setInputValue] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(false);

  const refreshCaptcha = () => {
    setCaptchaValue(random4Digit());
    setInputValue("");
    setIsValid(false);
    setError(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value.length <= 4) {
      setInputValue(value);
      setError(false);
    }

    if (value.length === 4) {
      const isMatch = value.toLowerCase() === captchaValue.toLowerCase();
      setIsValid(isMatch);
      setError(!isMatch);
    } else if (isValid) {
      setIsValid(false);
    }
  };

  useImperativeHandle(ref, () => ({ isValid, setError, refreshCaptcha }));

  return (
    <div className="flex items-center justify-center gap-x-6">
      <div className="flex gap-x-3">
        <div className="rounded-lg bg-base-300 p-6 py-1 text-xl font-bold italic tracking-widest">
          {captchaValue}
        </div>

        <button onClick={refreshCaptcha} className="text-xl text-primary">
          <FiRefreshCw />
        </button>
      </div>

      <input
        value={inputValue}
        maxLength={4}
        onChange={handleInputChange}
        placeholder={constants.ENTER_CODE}
        className={classNames({
          "input input-bordered input-xs w-28 p-4 px-2 text-sm": true,
          "input-error": error,
          "input-success": isValid,
        })}
      />
    </div>
  );
});

export default Captcha;
