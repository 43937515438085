import StepProgressBar from "components/StepProgressBar";
import constants from "configs/constants";

import props, { Type } from "pages/users/utils/preferencesProps";
import { useState } from "react";

import { injectToken } from "api/client-v2";
import preferences from "api/preferences";
import Loading from "components/theme/Loading";
import formatPreferences from "pages/users/utils/formatPreferences";
import StepFour from "pages/users/utils/steps/preferences/Four";
import StepOne from "pages/users/utils/steps/preferences/One";
import StepThree from "pages/users/utils/steps/preferences/Three";
import StepTow from "pages/users/utils/steps/preferences/Tow";
import { AiOutlinePoweroff } from "react-icons/ai";
import { Navigate, useLoaderData } from "react-router-dom";
import TPreference from "types/TPreference";
import expiredToken from "utils/expiredToken";
import refreshPage from "utils/refreshPage";
import storage from "utils/storage";

interface Build {
  gender?: string;
  token?: string;
  is_guardian?: boolean;
  prefs?: Type;
  route?: string;
  activeStep?: number;
}

const BuildPreferences = () => {
  const state = useLoaderData() as Build;
  const isPropsPassed = state && Object.keys(state).length;

  const gender = state?.gender;
  const token = state?.token;
  const prefs_ = state?.prefs;
  const is_guardian = state?.is_guardian;
  const [prefs, setPrefs] = useState(prefs_ || { ...props.pref, gender });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [uploadError, setUploadError] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(state?.activeStep || 0);

  const onSubmit = async () => {
    setLoading(true);
    setUploadError("");

    const data = formatPreferences({
      preferences: { ...prefs },
      action: "create",
    });

    try {
      injectToken(token!);
      const prefs = await preferences.create(data as TPreference);

      if (prefs.ok) {
        storage.remove("build");
        storage.store("guardian", false);
        storage.storeToken(prefs.data!.token, prefs.data!.refresh_token);
        refreshPage("/");
      } else {
        setUploadError(constants.ERRORS.INFO_UPLOAD + ".");
        return setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const nextStep = async (step: number) => {
    const thereIsError = props
      .step(step)
      .filter((e) => !prefs[e as keyof typeof prefs]);

    if (step === 4 && !thereIsError.length) onSubmit();
    else if (!thereIsError.length) {
      setCurrentStep(step);
      persistData(step);
    } else setErrorMessages(thereIsError);
  };

  async function persistData(step: number) {
    try {
      const isExpiry = expiredToken(token || "");
      if (isExpiry) {
        storage.remove("build");
        refreshPage("/login");
      } else {
        storage.store(
          "build",
          JSON.stringify({
            prefs,
            gender,
            is_guardian,
            token,
            activeStep: step,
            route: "/build/preferences",
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  function logout() {
    storage.remove("build");
    refreshPage("/");
  }

  if (!isPropsPassed) return <Navigate to="/login" />;
  if (state.route !== "/build/preferences")
    return <Navigate to="/build/profile" />;

  return (
    <div className="h-[100%] overflow-hidden pl-[2px]">
      <div
        onClick={logout}
        className="absolute left-4 top-4 z-10 flex cursor-pointer flex-col items-center font-semibold text-primary"
      >
        <AiOutlinePoweroff className="text-xl" />
        <p className="text-xs">{constants.OUT}</p>
      </div>

      <Loading visible={loading} />
      <StepProgressBar
        title={constants.PARTNER_PREFERENCES}
        next={() => nextStep(currentStep + 1)}
        previous={() => setCurrentStep((s) => s - 1)}
        className="h-[100%] pb-16"
        containerClassName="!h-[100vh]"
        currentStep={currentStep}
        steps={[
          {
            label: constants.BIRTH_AFFILIATION,
            content: (
              <StepOne
                prefs={prefs}
                setPrefs={setPrefs}
                errors={errorMessages}
                setErrors={setErrorMessages}
              />
            ),
          },
          {
            label: constants.STATUS,
            content: (
              <StepTow
                prefs={prefs}
                setPrefs={setPrefs}
                errors={errorMessages}
                setErrors={setErrorMessages}
              />
            ),
          },
          {
            label: constants.SEMBLANCE,
            content: (
              <StepThree
                prefs={prefs}
                setPrefs={setPrefs}
                errors={errorMessages}
                setErrors={setErrorMessages}
              />
            ),
          },
          {
            label: constants.BIO,
            content: (
              <StepFour
                prefs={prefs}
                setPrefs={setPrefs}
                errors={errorMessages}
                setErrors={setErrorMessages}
                uploadError={uploadError}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default BuildPreferences;
