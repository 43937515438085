import { SUCCESS_MARRIED } from "../strings";

const MarriedSuccessful = () => {
  return (
    <div className="font-bold">
      {SUCCESS_MARRIED}
      <br />
      <br />
      اذا رغبت في العوده الي استخدام حسابك مرة اخري في حال لم يحدث اتفاق أو لأي
      سبب آخر يمكنك مراسلة الدعم الفني
    </div>
  );
};

export default MarriedSuccessful;
