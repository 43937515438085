import auth from "api/auth";
import { useState } from "react";
import refreshPage from "utils/refreshPage";
import storage from "utils/storage";

const useAuth = () => {
  const [token, setTokenState] = useState(storage.getToken());
  const [isGuardian, setIsGuardian] = useState(!!storage.get("guardian"));

  function setToken(t: string) {
    setTokenState(t);
    storage.storeToken(t);
  }

  function setRefresh(t: string) {
    storage.storeToken("" + token, t);
  }

  const logout = async () => {
    await auth.logout();
    storage.removeToken();
    refreshPage("/");
  };

  return {
    isGuardian,
    isAuth: !!token,
    token,
    setIsGuardian,
    setToken,
    setRefresh,
    resetToken: storage.removeToken,
    logout,
  };
};

export default useAuth;
