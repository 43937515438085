import { create } from "zustand";

import UserItem from "types/UserItem";

export interface ThreadState {
  id: string | undefined;
  receiver: UserItem | undefined;
  visible: boolean;
  fromSupport?: boolean;
  showMessageStatus: boolean;
}

interface States {
  thread: ThreadState;
  setThread: (v: ThreadState) => void;
  updateThread: (v: ThreadState) => void;
  resetThread: () => void;
  toggleSeen: (seen: boolean) => void;
}

const defValues = {
  visible: false,
  id: undefined,
  receiver: undefined,
  showMessageStatus: true,
  fromSupport: false,
};

const useThread = create<States>()((set) => ({
  thread: defValues,
  setThread: (thread: ThreadState) => set({ thread }),
  updateThread: (update: ThreadState) =>
    set((state) => ({ thread: { ...state.thread, ...update } })),
  toggleSeen: (seen: boolean) =>
    set((state) => ({ thread: { ...state.thread, seen } })),
  resetThread: () => set({ thread: defValues }),
}));

export default useThread;
