import useAuth from "hooks/useAuth";
import Thread from "pages/support/Thread";
import useUser from "queries/user";
import { Navigate, Outlet } from "react-router-dom";
import useThread from "stores/thread";

const EngagedContainer = () => {
  const { isAuth } = useAuth();
  const { engaged } = useUser();
  const th = useThread((s) => s.thread);

  if (!isAuth) return <Navigate to="/login" />;
  if (!engaged) return <Navigate to="/" />;

  return (
    <>
      <Outlet />
      {th.visible && th.fromSupport && <Thread />}
    </>
  );
};

export default EngagedContainer;
