import Avatar from "components/Avatar";
import Modal from "components/core/Modal";
import List from "components/lists/List";
import constants from "configs/constants";
import { useLandingStories } from "queries/stories";
import { useState } from "react";
import { GiDiamondRing } from "react-icons/gi";
import TStory from "types/TStory";

interface Props {
  reference: React.RefObject<HTMLDivElement>;
}

const Stories = ({ reference }: Props) => {
  const { stories, isLoading, isError } = useLandingStories();
  const [selectedStory, setSelectedStory] = useState<TStory | undefined>();

  if (isError || (stories && !stories.length)) return null;
  return (
    <section
      ref={reference}
      className="mx-auto mt-12 max-w-5xl overflow-hidden rounded-3xl"
    >
      <p className="mr-5 text-center text-3xl font-bold">
        {constants.HISTORY_USERS}
      </p>

      <div className="divider divider-vertical mx-auto my-0 w-20"></div>

      <List
        horizontal
        data={stories}
        loading={isLoading}
        containerClassName="h-72 max-sm:mx-auto max-sm:w-[95%] rounded-3xl flex justify-center border-[0.1rem] border-gray-200 bg-white p-1 px-2 pb-5 shadow-md transition-all"
        itemClassName="h-72"
        keyExtractor={(item, index) => item.id || index.toString()}
        renderItem={(item) => {
          const { younger, girl } = item;

          return (
            <div
              onClick={() => setSelectedStory(item)}
              className="avatar-group relative flex h-48 w-48 cursor-pointer flex-col rounded-3xl border-[0.1rem] border-gray-100 bg-white p-1 shadow-lg transition-all hover:border-gray-200 hover:opacity-95 hover:shadow-xl"
            >
              <div className="p-5 px-8">
                <div className="avatar absolute z-10 mr-10 ring ring-accent">
                  <div className="w-16">
                    <img src={girl.profile_picture} />
                  </div>
                </div>
                <div className="avatar -mt-2 h-fit ring ring-primary">
                  <div className="w-16">
                    <img src={younger.profile_picture} />
                  </div>
                </div>
              </div>
              <div className=" flex w-full items-center justify-center gap-x-1 px-1 text-base">
                <p className="line-clamp-2 w-[50%] text-center font-semibold text-primary">
                  {younger.name}
                </p>
                <GiDiamondRing className="text-xl" />
                <p className="line-clamp-2 w-[50%] text-center font-semibold text-accent">
                  {girl.name}
                </p>
              </div>
            </div>
          );
        }}
      />
      <Modal
        className="max-w-4xl"
        visible={!!selectedStory}
        setVisibility={() => setSelectedStory(undefined)}
      >
        {!!selectedStory && (
          <div>
            <div className="text-xl font-medium">
              <div className="flex items-center justify-evenly">
                <div className="item-center flex flex-col items-center justify-center gap-y-3">
                  <Avatar src={selectedStory.younger.profile_picture} />
                  <span className="text-xl">{selectedStory.younger.name}</span>
                </div>
                <GiDiamondRing className="text-5xl text-accent" />
                <div className="item-center flex flex-col items-center justify-center gap-y-3">
                  <Avatar src={selectedStory.girl.profile_picture} />
                  <span className="text-xl">{selectedStory.girl.name}</span>
                </div>
              </div>
            </div>
            <div className="divider mx-6"></div>
            <div className="flex flex-col leading-7 md:flex-row">
              <p className="md:w-1/2">{selectedStory.younger_message}</p>
              <div className="divider divider-vertical md:divider-horizontal"></div>
              <p className="md:w-1/2">{selectedStory.girl_message}</p>
            </div>
          </div>
        )}
      </Modal>
    </section>
  );
};

export default Stories;
