function toastDefaultStyle(element: HTMLElement | null, position: string) {
  if (!element) return;
  element.style.position = "fixed";
  element.style.zIndex = "999999";

  element.style.top = "";
  element.style.left = "";
  element.style.right = "";
  element.style.bottom = "";
  element.style.transform = "";

  if (position === "top") {
    element.style.top = "0";
    element.style.left = "50%";
    element.style.transform = "translateX(-50%)";
  } else if (position === "bottom") {
    element.style.bottom = "0";
    element.style.left = "50%";
    element.style.transform = "translateX(-50%)";
  } else if (position === "left") {
    element.style.top = "0";
    element.style.left = "0";
    element.style.bottom = "0";
  } else {
    element.style.top = "0";
    element.style.right = "0";
  }
  element.style.margin = "0 auto";
}

function toastsAnimation(element: HTMLElement | null) {
  if (!element) return;
  setTimeout(() => {
    element.classList.remove("scale-0");
    element.classList.add("scale-100");
  }, 200);
}

function moveProgressBar(element: HTMLElement | null, duration: number) {
  if (!element) return;
  const target = element;
  let width = 1;
  const id = setInterval(frame, duration);
  function frame() {
    if (width >= 100) {
      clearInterval(id);
      target.classList.add("scale-0");
      setTimeout(() => {
        target.remove();
      }, 200);
    } else {
      width++;
      const progressBar = target.querySelector(
        ".progress",
      ) as HTMLProgressElement | null;
      if (progressBar) {
        progressBar.value = width;
      } else {
        clearInterval(id);
      }
    }
  }
}

function removeWindToast(element: Event) {
  const target = element.target as HTMLElement;
  const parent = target.parentElement?.parentElement?.parentElement
    ?.parentElement as HTMLElement | null;
  parent?.remove();
}

function content(message: string) {
  return {
    error: `<div class="alert alert-error"><button onclick="window.removeWindToast(event)"><svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></button><span >${message}</span> <div class="text-lg"><progress class="progress w-full hidden" value="50" max="100"></progress></div></div>`,
    success: `<div class="alert alert-success text-gray-700"><button onclick="window.removeWindToast(event)"><svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></button><span >${message}</span> <div class="text-lg"><progress class="progress w-full hidden" value="50" max="100"></progress></div></div>`,
    info: `<div class="alert alert-info"><button onclick="window.removeWindToast(event)"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-current shrink-0 w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></button><span >${message}</span> <div class="text-lg"><progress class="progress w-full hidden" value="50" max="100"></progress></div></div>`,
    warning: `<div class="alert alert-warning"><button onclick="window.removeWindToast(event)"><svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg></button><span>${message}</span> <div class="text-lg"><progress class="progress w-full hidden" value="50" max="100"></progress></div></div>`,
  };
}

interface Props {
  message: string;
  type?: "warning" | "success" | "info" | "error";
  duration?: number;
  position?: "top" | "bottom" | "right" | "left";
}

const Toast = ({ message, type, duration, position }: Props) => {
  const body = document.querySelector("body");
  let toasty = document.getElementById("wind-notify");

  if (!toasty) {
    toasty = document.createElement("div");
    toasty.id = "wind-notify";
    toasty.className = "h-fit";
    body?.appendChild(toasty);
  }

  toastDefaultStyle(toasty, position || "left");

  const toastyMessage = document.createElement("div");
  toastyMessage.className =
    "p-3 block transform transition-all duration-150 ease-out scale-0";

  toasty.appendChild(toastyMessage);
  toastsAnimation(toastyMessage);

  toastyMessage.innerHTML = content(message)[type || "error"];
  moveProgressBar(toastyMessage, duration || 40);
};

(
  window as unknown as Record<string, (element: Event) => void>
).removeWindToast = removeWindToast;
export default Toast;
