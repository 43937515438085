import apiConfig from "configs/api-v2";
import apiClient from "./client-v2";
import Note from "types/Note";

const endpoints = apiConfig.endpoints;

const get = () => apiClient.get<Note>(endpoints.notes);

export default {
  get,
};
