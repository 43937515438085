import chatsApi from "api/chats";
import engageApi from "api/engage";
import storiesApi from "api/stories";
import EmptyPage from "components/Empty";
import Badge from "components/core/Badge";
import List from "components/lists/List";
import { Tooltip } from "components/theme/Tooltip";
import UserSkeletons from "components/theme/skeleton/users";
import constants from "configs/constants";
import dayjs from "dayjs";
import useAuth from "hooks/useAuth";
import useToast from "hooks/useToast";
import NotificationPage from "pages/notification";
import useBadges from "queries/badges";
import useChats, { addNewChat } from "queries/chats";
import useEngage from "queries/engage";
import queryClient from "queries/queryClient";
import useUser from "queries/user";
import useWallet from "queries/wallets";
import { useState } from "react";
import { BiSupport } from "react-icons/bi";
import { GiDiamondRing } from "react-icons/gi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TGuardian from "types/TGuardian";
import RealInfo from "types/TRealInfo";
import hasKeys from "utils/hasKeys";
import userLogout from "utils/userLogout";
import EngageListItem from "../components/EngageListItem";
import EngageCancel from "../components/engaged/EngageCancel";
import EngageNotes from "../components/engaged/EngageNotes";
import EngageStory from "../components/engaged/EngageStory";
import GuardianInfo from "../components/engaged/GuardianInfo";
import MarriedNotes from "../components/engaged/MarriedNotes";
import MarriedSuccessful from "../components/engaged/MarriedSuccessful";
import Swear from "../components/engaged/Swear";
import { ASK_FOR_INFO, IN_ENGAGE_FOOTER } from "../components/strings";

const EngagedPage = () => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();

  const engage = useEngage();
  const { user, setUser, engaged: isEngaged } = useUser();
  const { wallet } = useWallet();
  const { chats } = useChats();
  const { badges } = useBadges();

  const engagements = engage && engage.data;
  const isMale = user.gender === "male";
  const isEngagedUser = user.status === "engaged";
  const isMarriedUser = user.status === "married";

  const myFiance =
    engagements && engagements.find((e) => e.status === "accepted");
  const swear = myFiance ? (myFiance.swear ? myFiance.swear : false) : true;
  const guardian = myFiance && myFiance.guardian;
  const myEngageUsername = myFiance && myFiance.user.name;
  const isTargetMarried = myFiance && myFiance.user.status === "married";

  const [cancelUser, setCancelUser] = useState("");
  const [showStory, setShowStory] = useState(false);
  const [showGuardianForm, setShowGuardianForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const gotMarried = async (story: string) => {
    const id = myFiance!.user._id;
    const props = isMale
      ? { younger: user._id, girl: id, younger_message: story }
      : { younger: id, girl: user._id, girl_message: story };

    setLoading(true);
    const result = await storiesApi.add(props);
    if (result.ok) {
      setUser({ status: "married" });
      toast.success(constants.TOAST.STORY_SAVED);
    }

    //  console.log(result.data);
    setLoading(false);
  };

  const onCancel = async (id: string, reason: string) => {
    setLoading(true);

    const response = await engageApi.cancel(id, reason);
    if (response.ok) {
      queryClient.invalidateQueries({ queryKey: ["chats"] });
      engage.update({
        engageId: id,
        update: {
          status: "canceled",
          removed: { reason, from: user.name, time: Date.now().toString() },
        },
        updateUser: { status: "accepted" },
      });
    } else toast.error(constants.ERRORS.UNEXPECTED_ERROR);

    setLoading(false);
  };

  const sendGuardianInfo = async (guardian: TGuardian) => {
    engage.setGuardian({ id: myFiance!.user._id, ...guardian });
    toast.success(constants.TOAST.INFO_SENT);
  };

  const sendInfo = async (info: RealInfo) => {
    engage.setInfo({ id: myFiance!.user._id, ...info });
    if (!user.real_info || !user.real_info.name) setUser({ real_info: info });

    toast.success(constants.TOAST.REQUEST_SENT);
  };

  const requestForInfo = (name: string, young_info: boolean) => {
    const isPaidAccount =
      user.real_info && user.real_info.name && wallet.type === "paid";

    if (isMale && !isPaidAccount) {
      if (user.has_requested_payment)
        return toast.warn(constants.HAS_REQUESTED_PAYMENT);
      navigate("/pay");
    } else if (isMale && isPaidAccount) sendInfo(user.real_info as RealInfo);
    else {
      if (!young_info)
        return toast.error(`انتظري حتي يقوم ${name} بارسال طلب البيانات`);
      setShowGuardianForm(true);
    }
  };

  const onSendMessage = async () => {
    const receiver = myFiance!.user;
    const th = chats.find((th) => th.receiver._id === receiver._id);
    let id;

    if (th) id = th._id;
    else {
      const thread = await chatsApi.create(user._id);

      if (thread.ok) {
        id = thread.data!.id;
        addNewChat({ chatId: id, receiver });
      } else return toast.error(constants.ERRORS.UNEXPECTED_ERROR);
    }

    navigate(`thread/${receiver._id}/${id}`);
  };

  const isCanceled = (id: string, status: string) => {
    const isCa = "canceled" === status;
    return !isCa && !isMarriedUser && !isTargetMarried
      ? () => setCancelUser(id)
      : undefined;
  };

  const marriageAction = (guardian: any) => {
    return isEngagedUser && hasKeys(guardian)
      ? () => setShowStory(true)
      : undefined;
  };

  const getInfoAction = (name: string, pass_girl_info: any, guardian: any) => {
    return isEngagedUser && !hasKeys(guardian)
      ? () => requestForInfo(name, pass_girl_info)
      : undefined;
  };

  const Content = () => {
    const askForInfo = myFiance && myFiance.pass_girl_info;

    return (
      <>
        {isEngagedUser && askForInfo && !hasKeys(guardian) ? (
          ASK_FOR_INFO(myEngageUsername!)
        ) : isEngagedUser && !hasKeys(guardian) ? (
          <EngageNotes isMale={isMale} username={myEngageUsername!} />
        ) : isEngagedUser && !isTargetMarried && hasKeys(guardian) ? (
          <MarriedNotes isMale={isMale} guardian={guardian as TGuardian} />
        ) : null}

        {(isMarriedUser || isTargetMarried) && <MarriedSuccessful />}
      </>
    );
  };

  if (!isAuth) return <Navigate to="/login" />;
  if (!isEngaged) return <Navigate to="/" />;

  if (engage.isLoading || loading) return <UserSkeletons />;
  if (engage.isError) throw new Error(engage.error?.message);
  else if (!engagements?.length)
    return (
      <EmptyPage
        content={constants.EMPTY_ENGAGEMENT_LIST}
        Icon={GiDiamondRing}
      />
    );

  return (
    <div className="relative mx-auto max-w-6xl">
      <List
        data={[myFiance]}
        Footer={IN_ENGAGE_FOOTER}
        keyExtractor={(item, i) => item?.user.uid || i.toString()}
        renderItem={(item) => {
          if (!item) return null;

          const { name, profile_picture, _id, is_guardian } = item.user;
          const { status, createdAt, guardian } = item;
          const { pass_girl_info } = item;

          return (
            <EngageListItem
              hover={false}
              title={name}
              isMale={isMale}
              guardian={is_guardian}
              status={isMarriedUser || isTargetMarried ? "success" : status}
              image={profile_picture}
              subTitle={dayjs(createdAt).format("YYYY-MM-DD")}
              cancelAction={isCanceled(_id, status)}
              onMessage={onSendMessage}
              logoutAction={userLogout}
              marriageAction={marriageAction(guardian)}
              getInfoAction={getInfoAction(name, pass_girl_info, guardian)}
              to={`profile/${item.user.uid}`}
              Content={Content}
            />
          );
        }}
      />

      <Swear engage={myFiance} visible={!swear} onSwear={engage.swear} />

      <EngageCancel
        visible={!!cancelUser}
        close={() => setCancelUser("")}
        onClick={(reason) => onCancel(cancelUser, reason)}
      />

      <GuardianInfo
        visible={isEngagedUser && showGuardianForm}
        close={setShowGuardianForm}
        onClick={sendGuardianInfo}
      />

      <EngageStory
        visible={
          !!(isEngagedUser && guardian && hasKeys(guardian) && showStory)
        }
        onClick={gotMarried}
        close={setShowStory}
      />

      {/* notifications & supports */}
      <div className="absolute -top-5 left-12 flex gap-x-6">
        <div className="dropdown dropdown-left dropdown-bottom">
          <label tabIndex={0}>
            <button>
              <div className="indicator">
                <Tooltip text={constants.BAR_NOTIFICATIONS}>
                  <IoMdNotificationsOutline className="icon" />
                </Tooltip>
                <Badge value={badges?.notifications} className="m-1" />
              </div>
            </button>
          </label>

          <NotificationPage />
        </div>

        <label tabIndex={0}>
          <Link to="/engaged/support" target="_blank">
            <div className="indicator">
              <Tooltip text={constants.BAR_SUPPORT}>
                <BiSupport className="icon" />
              </Tooltip>
              <Badge value={badges?.support} className="m-1" />
            </div>
          </Link>
        </label>
      </div>
    </div>
  );
};

export default EngagedPage;
