import { useEffect, useState } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent =
        window.navigator.userAgent ||
        window.navigator.vendor ||
        (window as any).opera;
      const mobileCheck =
        /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent,
        );
      setIsMobile(mobileCheck);
    };

    checkIfMobile();

    // Optionally, listen for window resize to handle dynamic changes in device orientation
    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
