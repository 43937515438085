// import { useEffect } from "react";
// import { BiSun } from "react-icons/bi";
// import { MdOutlineDarkMode } from "react-icons/md";
// import useUtils from "stores/utils";

// const DARK = "dark";
// const LIGHT = "winter";
// const DEFAULT_THEME = localStorage.getItem("theme");

// const ThemeSwitcher = () => {
//   const theme = useUtils((store) => store.theme);
//   const setTheme = useUtils((store) => store.setTheme);
//   const isDark = DEFAULT_THEME === DARK;

//   const toggleTheme = () => {
//     setTheme(theme === DARK ? LIGHT : DARK);
//   };

//   useEffect(() => {
//     if (theme !== localStorage.getItem("theme"))
//       localStorage.setItem("theme", theme);

//     document.querySelector("html")?.setAttribute("data-theme", theme!);
//   }, [theme]);

//   return (
//     <label className="swap swap-rotate">
//       <input type="checkbox" onClick={toggleTheme} />

//       <BiSun className={`swap-${!isDark ? "on" : "off"} icon fill-current`} />

//       <MdOutlineDarkMode
//         className={`swap-${!isDark ? "off" : "on"} icon fill-current`}
//       />
//     </label>
//   );
// };

// export default ThemeSwitcher;

const ThemeSwitcher = () => {
  return null;
};

export default ThemeSwitcher;
