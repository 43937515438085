import List from "components/lists/List";
import { Tooltip } from "components/theme/Tooltip";
import constants from "configs/constants";
import TPreference from "types/TPreference";
import TProfile from "types/TProfile";
import { labels } from "./utils";

interface Props {
  user: TProfile | TPreference;
  titleOne: string;
  titleTow: string;
}

const PROP_WITH_TOW_VALUES = ["age", "weight", "height"];

const arYesOrNo = (value: string | boolean) => {
  return value === true ? "نعم" : value === false ? "ﻻ" : value;
};

type Item = { label: any; name: any; Icon: any; index: any };

const _renderItem = (
  info: TProfile | TPreference,
  { label, name, Icon, index }: Item,
) => {
  type Key = keyof typeof info;
  const value = info[name as Key];
  const noChildren = name === "children" && !value;

  const content = value
    ? PROP_WITH_TOW_VALUES.includes(name) && typeof value === "string"
      ? (value as string)
          .replace(/_/g, " الى ")
          .replace(/cm/g, "")
          .replace(/kg/g, "")
      : arYesOrNo(value as string)
    : noChildren
    ? constants.NOT_FOUND
    : constants.NOT_REQUIRE;

  const displayValue = Array.isArray(value) ? value.join(" | ") : content;

  return (
    <div key={index.toString()} className="m-2 flex items-center gap-x-1">
      <Icon />
      <Tooltip text={displayValue}>
        <div className="flex gap-x-2">
          <span className="line-clamp-1 font-bold text-accent">{`${label}: `}</span>
          <span className="line-clamp-1 font-bold text-primary">{`${displayValue}`}</span>
        </div>
      </Tooltip>
    </div>
  );
};

const ListItems = ({ info }: { info: TPreference | TProfile }) => {
  return (
    <>
      <div className="grid h-fit grid-cols-2 grid-rows-1 gap-x-2 max-lg:grid-cols-1">
        <List
          data={labels.slice(0, 9)}
          containerClassName="!mt-0 !pb-0 md:mr-2"
          itemClassName="!overflow-hidden"
          keyExtractor={(_, index) => index.toString()}
          renderItem={(item, index) => _renderItem(info, { ...item, index })}
        />

        <List
          data={labels.slice(9)}
          containerClassName="!mt-0 !pb-0 md:mr-2"
          itemClassName="my-2 !overflow-hidden"
          keyExtractor={(_, index) => index.toString()}
          renderItem={(item, index) => _renderItem(info, { ...item, index })}
        />
      </div>
    </>
  );
};

const Info = ({ user, titleOne, titleTow }: Props) => {
  const description = (user as TProfile).bio
    ? (user as TProfile).bio
    : (user as TPreference).description;

  return (
    <div>
      <div className="mb-5 pr-5 text-xl font-bold text-primary">
        <span>{titleOne}</span>
        <div className="divider mr-3 w-28  before:bg-primary after:bg-primary" />
      </div>

      <div className="m-5 rounded-lg border-2 border-secondary">
        <ListItems info={user} />
      </div>

      {!!description && (
        <>
          <div className="mb-5 pr-5 text-xl font-bold text-primary">
            <span>{titleTow}</span>
            <div className="divider mr-3 w-28 before:bg-primary after:bg-primary" />
          </div>

          <div className="m-5 mt-4 h-[200px] w-[90%] overflow-auto rounded-xl border-2 border-secondary bg-base-100 p-2">
            <span className="font-bold text-accent">{description}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default Info;
