import TPreference from "types/TPreference";
import TProfile from "types/TProfile";

type Profile = TProfile & { age: number };
type gender = "male" | "female";

const measureMatchedMePercent = (
  opGender: gender,
  src: TPreference,
  target: Profile,
) => {
  let matchedIn = 0;
  const isMale = opGender !== "male";
  // const DIVED_BY = isMale ? 7 : 8

  // matched nationality
  if (!src.nationality || src.nationality.includes(target.nationality))
    matchedIn += 20;

  // matched clan
  if (!src.clan || src.clan.includes(target.clan)) matchedIn += 10;

  // matched relationship
  if (
    !src.relationship_status ||
    src.relationship_status.includes(target.relationship_status)
  )
    matchedIn += 20;

  // matched age
  if (!src.age) matchedIn += isMale ? 20 : 18;
  else {
    const fromAge = +src.age.split("_")[0];
    const toAge = +src.age.split("_")[1];
    const targetAge = target.age;

    // if (isMale) fromAge -= 7;
    // else toAge += 7;

    if (fromAge <= targetAge && targetAge <= toAge)
      matchedIn += isMale ? 20 : 18;
  }

  // matched beauty
  if (!src.beauty || src.beauty.includes(target.beauty))
    matchedIn += isMale ? 4 : 3;
  else if (src.beauty) matchedIn += isMale ? 2 : 1;

  // matched country
  if (!src.country || src.country.includes(target.country)) matchedIn += 3;

  // matched financial
  if (
    !src.financial_status ||
    src.financial_status.includes(target.financial_status)
  )
    matchedIn += isMale ? 1 : 3;
  else if (src.financial_status && !isMale) matchedIn += 1;

  // matched prayers
  if (!src.prayers || src.prayers.includes(target.prayers)) matchedIn += 5;
  else if (src.prayers) matchedIn += 2;

  // matched health
  if (!src.health || src.health.includes(target.health)) matchedIn += 4;

  // matched education
  if (!src.education || src.education.includes(target.education))
    matchedIn += 4;
  else if (src.education) matchedIn += 2;

  // matched skin_color
  if (!src.skin_color || src.skin_color.includes(target.skin_color))
    matchedIn += 2;
  else if (src.skin_color) matchedIn += 1;

  // matched occupation
  if (!src.occupation || src.occupation.includes(target.occupation))
    matchedIn += 2;

  // matched weight
  if (!src.weight) matchedIn += 2;
  else {
    const fromWeight = +src.weight.replace(/ kg/g, "").split("_")[0];
    const toWeight = +src.weight.replace(/ kg/g, "").split("_")[1] + 10;
    const targetWeight = target.weight;
    if (fromWeight <= targetWeight && targetWeight <= toWeight) matchedIn += 2;
  }

  // matched height
  if (!src.height) matchedIn += 2;
  else {
    const fromHeight = +src.height.replace(/ cm/g, "").split("_")[0];
    const toHeight = +src.height.replace(/ cm/g, "").split("_")[1] + 5;
    const targetHeight = target.height;
    if (fromHeight <= targetHeight && targetHeight <= toHeight) matchedIn += 2;
  }

  return matchedIn > 100 ? 100 : matchedIn;
};

const measureForMePercent = (
  opGender: gender,
  src: TPreference,
  target: Profile,
) => {
  let matchedIn = 0;
  const isMale = opGender !== "male";

  // matched nationality
  if (!src.nationality || src.nationality.includes(target.nationality))
    matchedIn += 20;

  // matched clan
  if (!src.clan || src.clan.includes(target.clan)) matchedIn += 10;

  // matched age
  if (!src.age) matchedIn += 20;
  else {
    let fromAge = +src.age.split("_")[0];
    let toAge = +src.age.split("_")[1];
    const targetAge = target.age;

    if (isMale) {
      fromAge -= 5;
      toAge += 2;
    } else {
      fromAge -= 2;
      toAge += 10;
    }

    if (fromAge <= targetAge && targetAge <= toAge) matchedIn += 20;
  }

  if (isMale) {
    // matched beauty
    if (!src.beauty || src.beauty.includes(target.beauty)) matchedIn += 5;
    else if (src.beauty) matchedIn += 2;
  }

  if (!isMale) {
    // matched country
    if (!src.country || src.country.includes(target.country)) matchedIn += 5;
  }

  if (!isMale) {
    // matched financial
    if (
      !src.financial_status ||
      src.financial_status.includes(target.financial_status)
    )
      matchedIn += 2;
  }

  // matched prayers
  if (!src.prayers || src.prayers.includes(target.prayers)) matchedIn += 5;
  else if (src.prayers) matchedIn += 2;

  // matched health
  if (!src.health || src.health.includes(target.health))
    matchedIn += isMale ? 5 : 4;

  // matched education
  if (!src.education || src.education.includes(target.education))
    matchedIn += isMale ? 5 : 4;
  else if (src.education) matchedIn += 2;

  // matched skin_color
  if (!src.skin_color || src.skin_color.includes(target.skin_color))
    matchedIn += isMale ? 3 : 2;
  else if (src.skin_color) matchedIn += 1;

  if (!isMale) {
    // matched occupation
    if (!src.occupation || src.occupation.includes(target.occupation))
      matchedIn += 2;
  }
  // matched weight
  if (!src.weight) matchedIn += 2;
  else {
    const fromWeight = +src.weight.replace(/ kg/g, "").split("_")[0] - 20;
    const toWeight = +src.weight.replace(/ kg/g, "").split("_")[1] + 20;
    const targetWeight = target.weight;
    if (fromWeight <= targetWeight && targetWeight <= toWeight) matchedIn += 2;
  }

  // matched height
  if (!src.height) matchedIn += isMale ? 3 : 2;
  else {
    const fromHeight = +src.height.replace(/ cm/g, "").split("_")[0] - 10;
    const toHeight = +src.height.replace(/ cm/g, "").split("_")[1] + 10;
    const targetHeight = target.height;
    if (fromHeight <= targetHeight && targetHeight <= toHeight)
      matchedIn += isMale ? 3 : 2;
  }

  return matchedIn > 100 ? 100 : matchedIn;
};

export default (opGender: gender, src: TPreference, target: Profile) => {
  const matchedPercent = measureMatchedMePercent(opGender, src, target);
  const forMePercent = measureForMePercent(opGender, src, target);

  if (matchedPercent > forMePercent) return matchedPercent;
  else return forMePercent;
};
