import Toast from "components/theme/Toast";

type Position = "top" | "bottom" | "right" | "left";

export default () => ({
  success: (message: string, position?: Position, duration?: number) =>
    Toast({ type: "success", message, position, duration }),
  error: (message: string, position?: Position, duration?: number) =>
    Toast({ type: "error", message, position, duration }),
  info: (message: string, position?: Position, duration?: number) =>
    Toast({ type: "info", message, position, duration }),
  warn: (message: string, position?: Position, duration?: number) =>
    Toast({ type: "warning", message, position, duration }),
});
Toast;
