import flowers from "assets/flowers.png";
import constants from "configs/constants";
import Login from "pages/auth/Login";
import { BsFillArrowLeftSquareFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const register = [
  {
    id: "1",
    label: "أنا شاب وأرغب في التسجيل",
    state: { gender: "male" },
    color: "text-primary",
  },
  {
    id: "2",
    label: "أنا فتاة وأرغب في التسجيل",
    state: { gender: "female" },
    color: "text-secondary",
  },
  {
    id: "3",
    label: "أنا وسيط وأرغب في التسجيل",
    state: { gender: "male|female", is_guardian: true },
    color: "text-accent",
  },
];

const Auth = () => {
  const navigate = useNavigate();

  return (
    <section className="mt-8 flex overflow-hidden">
      <img className="-mr-48 h-96 w-96 max-lg:absolute" src={flowers} />

      <div className="flex w-full flex-col lg:flex-row">
        {/* login section */}
        <div className="mx-auto w-[90%] md:max-w-[60%] lg:mx-20 lg:flex-grow-0">
          <p className="text-center text-3xl font-bold lg:mr-5 lg:text-right">
            {constants.LOGIN}
          </p>

          <div className="divider divider-vertical mx-auto my-0 w-20 lg:mx-5"></div>

          <Login isLandingPage />
        </div>

        <div className="divider divider-horizontal mx-6"></div>

        {/* register section */}
        <div className="mx-auto w-[90%] md:max-w-[60%] lg:mx-0 lg:flex-grow">
          <p className="text-center text-3xl font-bold max-lg:mt-10 lg:mr-5 lg:text-right">
            {constants.SING_FOR_FREE}
          </p>

          <div className="divider divider-vertical mx-auto my-0 w-20 lg:mx-5"></div>

          <div className="flex flex-col pr-4 lg:px-20 lg:pt-10">
            <ul className="w-full">
              {register.map(({ state, label, id, color }) => {
                return (
                  <li
                    key={id}
                    onClick={() => navigate("/register", { state })}
                    className={`my-3 list-item w-fit cursor-pointer text-base md:text-xl ${color}`}
                  >
                    <p className="flex items-center gap-2">
                      {label}
                      <BsFillArrowLeftSquareFill />
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Auth;
