import useAds from "queries/ads";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import YouTube from "react-youtube";
import TAds from "types/TAds";
import textWithNewLines from "utils/textWithNewLines";
import youTubeId from "utils/youtubeId";

const Ads = () => {
  const { ads, isLoading, isError, error } = useAds();
  const [selectedAds, setSelectedAds] = useState<TAds | undefined>();

  if (isError) throw new Error(error?.name);
  if (isLoading)
    return (
      <div className="mx-auto my-3 flex h-36 w-[90%] animate-pulse flex-col items-center overflow-hidden rounded-3xl bg-base-300 p-1" />
    );

  if (!ads.length) return null;
  return (
    <div
      style={{ direction: "initial" }}
      className="h-36 overflow-hidden p-4 px-8"
    >
      {/* ads preview */}
      <div style={{ direction: "rtl" }} className="">
        <dialog className={`modal ${!!selectedAds && "modal-open"}`}>
          <div className="no-scrollbar modal-box">
            <form method="dialog">
              <button
                onClick={() => setSelectedAds(undefined)}
                className="btn btn-circle btn-ghost btn-sm absolute right-2 top-2"
              >
                ✕
              </button>
            </form>

            <div className="mt-8">
              {selectedAds?.video ? (
                <YouTube
                  iframeClassName="h-56 w-full rounded-lg"
                  videoId={youTubeId(selectedAds.video)}
                />
              ) : (
                <img src={selectedAds?.image} className="rounded-lg" />
              )}
            </div>

            {!!selectedAds?.content && (
              <p className="py-4 text-right leading-7">
                {textWithNewLines(selectedAds.content)}
              </p>
            )}
          </div>
        </dialog>
      </div>

      <Carousel
        autoPlay
        infiniteLoop
        onClickItem={(index) => setSelectedAds(ads[index])}
        showArrows
        showThumbs={false}
        stopOnHover
      >
        {ads.map((item, index) => {
          return (
            <div key={index.toString()}>
              <img
                className="h-32 w-[80%] rounded-md object-fill"
                src={item.image}
              />
              {!!item.title?.trim() && (
                <div className="legend cursor-pointer !bg-base-100 !p-1 !opacity-40 hover:!opacity-100">
                  <p className="text font-bold text-base-content">
                    {item.title}
                  </p>
                </div>
              )}
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Ads;
