import dayjs from "dayjs";
import TChat from "types/TChat";
import TSupport from "types/TSupport";

interface List {
  content?: { time: string }[];
  messages?: { time: string }[];
  createdAt?: string;
  account?: {
    last_seen: string;
    createdAt: string;
  };
  time?: string;
}

const supportMessage = (messagesList: TSupport[]): TSupport[] => {
  return !messagesList.length
    ? messagesList
    : messagesList.sort((msg1, msg2) => {
        const leastMessage1 = msg1.messages![msg1.messages!.length - 1];
        const leastMessage2 = msg2.messages![msg2.messages!.length - 1];

        return (
          Date.parse(dayjs(leastMessage2.time).toString()) -
          Date.parse(dayjs(leastMessage1.time).toString())
        );
      });
};

const chatMessages = (messagesList: TChat[]) => {
  return !messagesList.length
    ? messagesList
    : messagesList.sort((chat1, chat2) => {
        const leastMessageOfChat1 = chat1.lastMessage!;
        const leastMessageOfChat2 = chat2.lastMessage!;

        return (
          Date.parse(dayjs(leastMessageOfChat2.createdAt).toString()) -
          Date.parse(dayjs(leastMessageOfChat1.createdAt).toString())
        );
      });
};

const stories = (messagesList: List[]) => {
  return !messagesList.length
    ? messagesList
    : messagesList.sort((m1, m2) => {
        return Date.parse(m2.createdAt!) - Date.parse(m1.createdAt!);
      });
};

// i === 0, 1
const homeUsers = (i: number, messagesList: List[]) => {
  return !messagesList.length
    ? messagesList
    : messagesList.sort((m1, m2) => {
        const first = !i
          ? m1.account!.last_seen || m1.account!.createdAt
          : m1.account!.createdAt;
        const second = !i
          ? m2.account!.last_seen || m2.account!.createdAt
          : m2.account!.createdAt;
        return i
          ? Date.parse(first) - Date.parse(second)
          : Date.parse(second) - Date.parse(first);
      });
};

const withCreatedAtProp = <T>(messagesList: (T & List)[]): T[] => {
  return !messagesList.length
    ? messagesList
    : messagesList.sort((e1, e2) => {
        return (
          Date.parse(dayjs(e2.createdAt).toString()) -
          Date.parse(dayjs(e1.createdAt).toString())
        );
      });
};

const withTimeProp = <T>(messagesList: (T & List)[]): T[] => {
  return !messagesList.length
    ? messagesList
    : messagesList.sort((e1, e2) => {
        return (
          Date.parse(dayjs(e2.time).toString()) -
          Date.parse(dayjs(e1.time).toString())
        );
      });
};

export default {
  supportMessage,
  chatMessages,
  stories,
  homeUsers,
  withCreatedAtProp,
  withTimeProp,
};
