import AlertProvider from "components/core/Alert";
import { Outlet } from "react-router-dom";
import Navbar from "../../Navbar";

const PendingContainer = () => {
  return (
    <AlertProvider>
      <div className="h-screen overflow-hidden">
        {/* navbar */}
        <Navbar showIcons={false} />

        <div className="relative mx-5 grid grid-cols-1 grid-rows-1 justify-center gap-8 md:my-4 md:grid-cols-[100%]">
          {/* content */}
          <div className="no-scrollbar h-screen overflow-auto md:h-[85vh]">
            <Outlet />
          </div>
        </div>
      </div>
    </AlertProvider>
  );
};

export default PendingContainer;
