import EmptyPage from "components/Empty";
import List from "components/lists/List";
import UserListItem from "components/lists/UserListItem";
import UserSkeletons from "components/theme/skeleton/users";
import constants from "configs/constants";
import useBlocks from "queries/blocks";
import { BiBlock } from "react-icons/bi";

const BlocksPage = () => {
  const { isLoading, isError, error, blocks } = useBlocks();

  if (isLoading) return <UserSkeletons />;
  if (isError) throw new Error(error?.name);
  if (!blocks.length)
    return <EmptyPage content={constants.EMPTY_BLOCK_LIST} Icon={BiBlock} />;

  return (
    <div>
      <List
        data={blocks}
        title={constants.BLOCKED_USERS}
        keyExtractor={(item) => item.uid}
        renderItem={(item) => {
          return (
            <UserListItem
              age={item.age}
              city={item.city}
              country={item.country}
              guardian={item.is_guardian}
              iconFor="blocks"
              image={item.profile_picture}
              status={item.relationship_status}
              to={`profile/${item.uid}`}
              username={item.name}
            />
          );
        }}
      />
    </div>
  );
};

export default BlocksPage;
