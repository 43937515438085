import TextArea from "components/core/TextArea";
import TProps from "../../TProps";
import constants from "configs/constants";

interface Props {
  user: TProps;
  setUser: (v: TProps) => void;
  errors: string[];
  uploadError: boolean | string;
  setErrors: (v: string[]) => void;
}

const StepFour = ({ user, setUser, errors, uploadError, setErrors }: Props) => {
  const isPending = !!user.pending_bio;

  const validate = (propName: string) => {
    if (errors.includes(propName))
      setErrors(errors.filter((e) => e !== propName));
  };

  return (
    <div className="pt-14 md:pr-5">
      <p className="mb-2 text-lg font-bold">
        {constants.YOUR_BIO}
        {isPending && (
          <span className="font-bold text-warning">
            {` (${constants.PENDING_DESCRIPTION})`}
          </span>
        )}
      </p>
      <TextArea
        value={user.bio || ""}
        className="h-52 w-full md:w-[70%]"
        placeholder={constants.YOUR_BIO}
        error={errors.includes("bio")}
        setValue={(text) => {
          validate("bio");
          setUser({ ...user, bio: text });
        }}
        errorMessage={
          typeof uploadError === "string"
            ? uploadError
            : uploadError
            ? constants.ERRORS.UNEXPECTED_ERROR
            : undefined
        }
      />
    </div>
  );
};

export default StepFour;
