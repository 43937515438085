export default function textWithNewLines(text: string) {
  const lines = text.split("\n").map((line, index) => (
    <span key={index}>
      {line}
      {index < text.length - 1 && <br />}{" "}
    </span>
  ));

  return lines;
}
