import { useQuery } from "@tanstack/react-query";
import search from "api/search";
import ms from "ms";
import queryClient from "./queryClient";

export const refreshLastUsersQuery = () =>
  queryClient.invalidateQueries({ queryKey: ["last-users"] });

const useLastUsers = ({ all }: { all: boolean }) => {
  const query = useQuery({
    queryKey: all ? ["all-last-users"] : ["last-users"],
    queryFn: () => search.lastSeenUsers({ all }).then((res) => res.data),
    staleTime: ms("2h"),
  });

  return {
    lastUsers: query.data!,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export default useLastUsers;
