import EmptyPage from "components/Empty";
import Pagination from "components/Pagination";
import List from "components/lists/List";
import RowListItem from "components/lists/RowListItem";
import SearchSkeletons from "components/theme/skeleton/search";
import constants from "configs/constants";
import useSearch from "queries/search";
import { useRef } from "react";
import { BsSearch } from "react-icons/bs";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const GuestSearchResult = () => {
  const [, setSearchParams] = useSearchParams();
  const { state, search } = useLocation();
  const navigate = useNavigate();

  const params = useRef(state || {}).current;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const page = Math.max(+search.split("=")[1], 1);

  const results = useSearch({ page, params, guest: true });
  const users = results.data?.results;
  const hasNext = results.data?.next;
  const hasPrevious = results.data?.previous;

  function handlePages(page: number) {
    if (containerRef.current) containerRef.current.scrollTop = 0;
    setSearchParams({ page: `${page}` });
  }

  if (results.isError) throw new Error(results.error.message);
  return (
    <div className="relative">
      <div
        className="max-w-4xl overflow-auto pb-40 md:h-[85vh] md:pb-20 lg:mx-auto"
        ref={containerRef}
      >
        {results.isFetching ? (
          <SearchSkeletons />
        ) : !users?.length ? (
          <EmptyPage content={constants.EMPTY_SEARCH_LIST} Icon={BsSearch} />
        ) : (
          <List
            wrapped
            horizontal
            data={users}
            keyExtractor={(item) => item.uid}
            renderItem={(item) => {
              return (
                <RowListItem
                  age={item.age}
                  city={item.city}
                  country={item.country}
                  guardian={item.is_guardian}
                  image={item.profile_picture}
                  online={item.is_active! || item.is_online!}
                  status={item.relationship_status}
                  onClick={() =>
                    navigate(`/guest/users/${item.uid}`, {
                      state: { gender: params.gender },
                    })
                  }
                  username={item.name}
                />
              );
            }}
          />
        )}
      </div>

      <div className="fixed bottom-0 z-10 flex w-full justify-center bg-base-100 py-2 md:absolute">
        <Pagination
          page={+page}
          hasNext={!!hasNext}
          hasPrevious={!!hasPrevious}
          next={handlePages}
          previous={handlePages}
        />
      </div>
    </div>
  );
};

export default GuestSearchResult;
