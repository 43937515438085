interface Props {
  value: string | number;
  className?: string;
}

const Badge = ({ value, className }: Props) => {
  if (!value) return null;

  return (
    <span
      className={`badge indicator-item badge-error badge-xs p-2 font-bold text-white ${className}`}
    >
      {+value < 9 ? value : "9+"}
    </span>
  );
};

export default Badge;
