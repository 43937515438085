import apiConfig from "configs/api-v2";
import Message from "types/Message";
import TSupport from "types/TSupport";
import apiClient from "./client-v2";

interface IMessage {
  id: string;
  message: string;
}

interface ISubject {
  subject: string;
  message: string;
}

const endpoints = apiConfig.endpoints;

const get = (page:number) => apiClient.get<TSupport[]>(`${endpoints.supports}?page=${page}`);

const create = (data: ISubject) =>
  apiClient.post<TSupport>(endpoints.supports, data);

const append = (data: IMessage) => apiClient.patch(endpoints.supports, data);

const thread = (id: string, page: number) =>
  apiClient.get<Message[]>(`${endpoints.supports}/${id}?page=${page}`);

const read = (id: string) =>
  apiClient.get(endpoints.supports_read.replace(":id", id));

const remove = (id: string) => apiClient.delete(`${endpoints.supports}/${id}`);

const removeAll = () => apiClient.delete(`${endpoints.supports}/all`);

export default {
  get,
  read,
  create,
  append,
  thread,
  remove,
  removeAll,
};
