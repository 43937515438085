import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import stories from "api/stories";
import ms from "ms";
import queryClient from "./queryClient";
import TStory from "types/TStory";

const LIMIT_STORIES = 2;

export const useLandingStories = () => {
  const query = useQuery({
    queryKey: ["stories-landing"],
    queryFn: () => stories.getForLanding().then((res) => res.data),
    staleTime: ms("2h"),
  });

  return {
    stories: query.data!,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

const useStories = () => {
  const query = useInfiniteQuery({
    queryKey: ["stories"],
    queryFn: ({ pageParam }) => stories.get(pageParam).then((res) => res.data),
    getNextPageParam: (lastPage, allPage) => {
      return lastPage?.length === LIMIT_STORIES
        ? allPage.length + 1
        : undefined;
    },
    select: (data) => data.pages.flatMap((x) => x),
    initialPageParam: 1,
    staleTime: ms("2h"),
  });

  const { mutate: addStory } = useMutation({
    mutationFn: stories.add,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["stories"] });
    },
  });

  return {
    ...query,
    addStory,
    stories: (query.data as TStory[])!,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export default useStories;
