import HorizontalSkeletons from "components/theme/skeleton/horizontal";
import UserSkeletons from "components/theme/skeleton/users";
import VerticalSkeletons from "components/theme/skeleton/vertical";
import { ReactNode } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

interface Props<T> {
  title?: string;
  data: T[];
  renderItem: (item: T, index: number) => ReactNode;
  keyExtractor: (item: T, index: number) => string;
  itemClassName?: string;
  containerClassName?: string;
  Header?: ReactNode;
  Footer?: ReactNode;
  FirstRow?: ReactNode;
  horizontal?: boolean;
  wrapped?: boolean;
  loading?: boolean;
  infinite?: boolean;
  next?: () => void;
  hasNextPage?: boolean;
  fixedHeight?: boolean;
}

function List<T>({
  title,
  data,
  renderItem,
  Footer,
  Header,
  FirstRow,
  keyExtractor,
  itemClassName,
  containerClassName,
  horizontal,
  wrapped,
  loading = false,
  infinite = false,
  next,
  hasNextPage,
  fixedHeight = true,
}: Props<T>) {
  //

  if (loading && horizontal) return <HorizontalSkeletons />;
  if (loading && !horizontal) return <VerticalSkeletons />;
  if (!data) return null;

  return (
    <>
      {Header}
      {!!title && (
        <h1 className="md:top-18 fixed top-24 z-20 w-full self-center overflow-hidden border-gray-100 bg-base-100 p-4 text-center text-2xl font-bold max-sm:top-0 max-sm:hidden md:top-20 md:w-[64.8vw] md:rounded-t-3xl md:border-[0.1rem] md:border-b-0 md:border-r-0 lg:w-[64.85vw] xl:w-[64.9vw]">
          {title}
        </h1>
      )}
      <div
        className={
          containerClassName +
          ` md:pb-0 ${title ? "sm:mt-[5rem]" : "mt-8"} ${
            horizontal ? "mt-4 pb-0" : "pb-28"
          }`
        }
      >
        {!!FirstRow && !data.length && FirstRow}
        {infinite ? (
          <InfiniteScroll
            loader={<UserSkeletons length={2} />}
            next={next!}
            height={fixedHeight ? "70vh" : undefined}
            className={fixedHeight ? "!h-[80vh] md:!h-[70vh]" : undefined}
            hasMore={!!hasNextPage}
            dataLength={data.length}
            refreshFunction={() => data}
            pullDownToRefreshThreshold={50}
          >
            <div
              className={`no-scrollbar ${
                !data.length && "h-52"
              } gap-x-5 ${itemClassName} ${
                horizontal
                  ? "flex h-48  flex-row items-center overflow-x-auto px-5"
                  : "overflow-y-auto"
              } ${wrapped && "h-full flex-wrap justify-around"}`}
            >
              {data.map((item, index) => {
                return (
                  <div key={keyExtractor(item, index)}>
                    {!!FirstRow && index === 0 && FirstRow}
                    {renderItem(item, index)}
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        ) : (
          <div
            className={`no-scrollbar ${
              !data.length && "h-52"
            } gap-x-5 ${itemClassName} ${
              horizontal
                ? "flex h-48  flex-row items-center overflow-x-auto px-5"
                : "overflow-y-auto"
            } ${wrapped && "h-full flex-wrap justify-around"}`}
          >
            {data.map((item, index) => {
              return (
                <div key={keyExtractor(item, index)}>
                  {!!FirstRow && index === 0 && FirstRow}
                  {renderItem(item, index)}
                </div>
              );
            })}
          </div>
        )}
      </div>
      {Footer}
    </>
  );
}

export default List;
