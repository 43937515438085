import User from "types/User";

const userItem = (user: User) => {
  return {
    _id: user.account._id,
    uid: user.account.uid,
    name: user.account.name,
    age: user.account.age,
    is_guardian: user.account.is_guardian,
    profile_picture: user.account.profile_picture,
    city: user.profile.city,
    country: user.profile.country,
    relationship_status: user.profile.relationship_status,
  };
};

export default {
  userItem,
};
