import constants from "configs/constants";
import { useState } from "react";
import { BiSend } from "react-icons/bi";

interface Props {
  visible: boolean;
  close: (v: boolean) => void;
  onClick: (story: string) => void;
}

const EngageStory = ({ visible, close, onClick }: Props) => {
  const [story, setStory] = useState("");
  const [error, setError] = useState(false);

  const onSubmit = () => {
    setError(false);

    if (!story) return setError(true);
    close(false);
    onClick(story);
  };

  return (
    <dialog
      id="show-modal"
      className={`modal modal-middle ${visible && "modal-open"}`}
    >
      <div className="modal-box flex w-[80%] flex-col pt-10 md:w-96">
        <form method="dialog">
          <button
            onClick={() => close(false)}
            className="btn btn-circle btn-ghost btn-sm absolute right-1 top-1"
          >
            ✕
          </button>
        </form>

        <span className="label-text mb-4">{constants.WRITE_STORY}</span>
        <textarea
          onFocus={() => {
            if (!story && error) setError(false);
          }}
          onChange={(e) => setStory(e.target.value)}
          className={`textarea h-40 focus:outline-none ${
            !error ? "textarea-accent" : "textarea-error"
          }`}
        ></textarea>

        <button
          onClick={onSubmit}
          className="btn btn-circle btn-accent mt-8 self-center"
        >
          <BiSend className="text-xl text-white" />
        </button>
      </div>
    </dialog>
  );
};

export default EngageStory;
