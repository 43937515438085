const user = {
  nationality: null,
  age: null,
  country: null,
  city: null,
  clan: null,
  origin: null,
  relationship_status: null,
  children: null,
  financial_status: null,
  health: null,
  occupation: null,
  education: null,
  prayers: null,
  height: null,
  weight: null,
  beauty: null,
  skin_color: null,
  profile_picture: null,
  bio: "",
};

const children = [
  {
    label: "لا يوجد",
    value: 0,
  },
  {
    label: "طفل واحد",
    value: 1,
  },
  {
    label: "طفلين",
    value: 2,
  },
  {
    label: "ثلاثة أطفال",
    value: 3,
  },
  {
    label: "أربعة أطفال",
    value: 4,
  },
  {
    label: "خمسة أطفال",
    value: 5,
  },

  {
    label: "ستة أطفال",
    value: 6,
  },
  {
    label: "سبعة أطفال",
    value: 7,
  },
  {
    label: "ثمانية أطفال",
    value: 8,
  },
  {
    label: "تسعة أطفال",
    value: 9,
  },
  {
    label: "عشرة أطفال",
    value: 10,
  },
  {
    label: "احدى عشر طفل",
    value: 11,
  },
  {
    label: "اثنى عشر طفل",
    value: 12,
  },
  {
    label: "ثلاثة عشر طفل",
    value: 13,
  },
  {
    label: "اربعة عشر طفل",
    value: 14,
  },
  {
    label: "خمسة عشر طفل",
    value: 15,
  },
  {
    label: "ستة عشر طفل",
    value: 16,
  },
  {
    label: "سبعة عشر طفل",
    value: 17,
  },
  {
    label: "ثمانية عشر طفل",
    value: 18,
  },
  {
    label: "تسعة عشر طفل",
    value: 19,
  },
  {
    label: "عشرون طفل",
    value: 20,
  },
];

const step = (index: number) => {
  return index === 1
    ? ["nationality", "age", "country", "city", "clan", "origin"]
    : index === 2
    ? [
        "relationship_status",
        "children",
        "financial_status",
        "health",
        "occupation",
      ]
    : index === 3
    ? [
        "education",
        "prayers",
        "height",
        "weight",
        "beauty",
        "skin_color",
        // "profile_picture",
      ]
    : ["bio"];
};

export default {
  user,
  children,
  step,
};
