import prefsApi from "api/preferences";
import { UpdatePreferences } from "api/types/users";
import StepProgressBar from "components/StepProgressBar";
import Loading from "components/theme/Loading";
import constants from "configs/constants";
import useToast from "hooks/useToast";
import useUser from "queries/user";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import getValueOfKey, { KeysOf } from "utils/getValueOfKey";
import hasKeys from "utils/hasKeys";
import props, { Type } from "../utils/preferencesProps";
import StepFour from "../utils/steps/preferences/Four";
import StepOne from "../utils/steps/preferences/One";
import StepThree from "../utils/steps/preferences/Three";
import StepTow from "../utils/steps/preferences/Tow";
import formatPreferences from "../utils/formatPreferences";

const EditPreferences = () => {
  const { user, setUser } = useUser();
  const navigate = useNavigate();

  const preferences = user.preferences || {};

  const currentData: Type = {
    ...props.pref,
    ...preferences,
    gender: user.gender,
    description: preferences.description || preferences.pending_description,
    ageFrom: preferences.age?.split("_")[0] || constants.NOT_REQUIRE,
    ageTo: preferences.age?.split("_")[1] || constants.NOT_REQUIRE,
    weightFrom: preferences.weight?.split("_")[0] || constants.NOT_REQUIRE,
    weightTo: preferences.weight?.split("_")[1] || constants.NOT_REQUIRE,
    heightFrom: preferences.height?.split("_")[0] || constants.NOT_REQUIRE,
    heightTo: preferences.height?.split("_")[1] || constants.NOT_REQUIRE,
  };

  for (const key in currentData) {
    if (getValueOfKey(currentData, key as KeysOf<Type>) === null) {
      (currentData[key as KeysOf<Type>] as string[]) = [constants.NOT_REQUIRE];
    }
  }

  const [prefs, setPrefs] = useState(currentData);
  const [currentStep, setCurrentStep] = useState(0);
  const [uploadError, setUploadError] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const onSubmit = async () => {
    setLoading(true);
    setUploadError(false);

    const data = formatPreferences({
      preferences: { ...prefs },
      action: "update",
      initData: currentData,
    });

    if (hasKeys(data)) {
      const isDescUpdated = !!data.description;
      const update = await prefsApi.update(data as UpdatePreferences);
      if (isDescUpdated) {
        data.pending_description = data.description;
        delete data.description;
      }

      const preferences = { ...user.preferences, ...data } as UpdatePreferences;
      if (update.ok) {
        setUser({ preferences });
        toast.success(
          isDescUpdated
            ? constants.TOAST.PENDING_RESPONSE
            : constants.TOAST.UPDATE_PREF_SUCCESSFUL,
        );

        navigate("/");
      } else setUploadError(true);
    }

    setLoading(false);
  };

  const nextStep = async (step: number) => {
    if (step === 4) onSubmit();
    else setCurrentStep(step);
  };

  return (
    <>
      {loading && (
        <div className="relative">
          <Loading visible />
        </div>
      )}
      <div className="h-[100%] overflow-hidden pl-[2px]">
        <StepProgressBar
          next={() => nextStep(currentStep + 1)}
          previous={() => setCurrentStep((s) => s - 1)}
          className="!h-full pb-40 md:pb-16"
          currentStep={currentStep}
          steps={[
            {
              label: constants.BIRTH_AFFILIATION,
              content: (
                <StepOne
                  prefs={prefs}
                  setPrefs={setPrefs}
                  errors={errorMessages}
                  setErrors={setErrorMessages}
                />
              ),
            },
            {
              label: constants.STATUS,
              content: (
                <StepTow
                  prefs={prefs}
                  setPrefs={setPrefs}
                  errors={errorMessages}
                  setErrors={setErrorMessages}
                />
              ),
            },
            {
              label: constants.SEMBLANCE,
              content: (
                <StepThree
                  prefs={prefs}
                  setPrefs={setPrefs}
                  errors={errorMessages}
                  setErrors={setErrorMessages}
                />
              ),
            },
            {
              label: constants.BIO,
              content: (
                <StepFour
                  prefs={prefs}
                  setPrefs={setPrefs}
                  errors={errorMessages}
                  setErrors={setErrorMessages}
                  uploadError={uploadError}
                />
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

export default EditPreferences;
