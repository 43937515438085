import { ENGAGE_NOTE_FEMALE, ENGAGE_NOTE_MALE } from "../strings";

interface Props {
  username: string;
  isMale: boolean;
}

const EngageNotes = ({ username, isMale }: Props) => {
  return (
    <span>
      نبارك لكما الارتباط، انت و
      <span className="text-primary">{` ${username} `}</span>
      بينكما ارتباط خطبة
      <br />
      <br />
      {isMale ? ENGAGE_NOTE_MALE : ENGAGE_NOTE_FEMALE}
    </span>
  );
};

export default EngageNotes;
