import useAuth from "hooks/useAuth";
import LandingNavbar from "pages/landing/Navbar";
import { Navigate, Outlet } from "react-router-dom";

const Public = () => {
  const { isAuth } = useAuth();

  if (isAuth) return <Navigate to="/" />;
  return (
    <div>
      <LandingNavbar />
      <Outlet />
    </div>
  );
};

export default Public;
