import Avatar from "components/Avatar";
import constants from "configs/constants";

interface Props {
  image: string;
  username: string;
  login: () => void;
}

const ListItem = ({ image, username, login }: Props) => {
  return (
    <div
      className={`mx-auto my-3 flex h-40 w-[10rem] flex-col items-center overflow-hidden rounded-3xl border-[0.1rem] border-gray-200 bg-white p-1 shadow-lg transition-all hover:border-gray-200 hover:opacity-95 hover:shadow-2xl`}
    >
      <div className="mt-1 px-2 pl-3">
        <Avatar src={image} bordered />
      </div>
      <div className="flex w-full flex-col">
        <h3 className="mb-2 w-5/6 self-center truncate text-center text-lg">
          {username}
        </h3>

        <div className="flex w-5/6 flex-col self-center truncate">
          <button
            onClick={login}
            className="btn btn-primary btn-outline btn-xs mt-2 w-full text-xs text-white"
          >
            {constants.LOGIN}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
