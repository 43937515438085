import auth from "api/auth";
import classNames from "classnames";
import constants from "configs/constants";
import useToast from "hooks/useToast";
import useUser from "queries/user";
import { useState } from "react";
import { Link } from "react-router-dom";

const EmailVerify = () => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { user, engaged, unAccepted } = useUser();

  async function onSendEmail() {
    if (!user.email) return toast.warn(constants.ERRORS.ADD_EMAIL);

    setLoading(true);
    const result = await auth.sendEmail(user.email);
    if (result.ok) {
      toast.success(constants.TOAST.EMAIL_SENT_SUCCESSFULLY);
      setTimeout(() => setLoading(false), 2 * 60 * 1000);
    } else {
      toast.error(constants.ERRORS.UNEXPECTED_ERROR);
      setLoading(false);
    }
  }

  if (user.is_email_verified || engaged || unAccepted) return null;
  return (
    <div className="absolute z-50 mt-2 flex w-fit animate-pulse items-center gap-x-6 rounded-e-md bg-red-300 p-2 animate-delay-[2000ms] animate-duration-[4000ms] animate-infinite hover:animate-none">
      <div className="flex flex-col">
        <span className="text-sm">{constants.VERIFY_YOUR_EMAIL}</span>
        <span className="text-[10px]">{`(${constants.SPAM})`}</span>
      </div>
      <span
        onClick={onSendEmail}
        className={classNames({
          "link-hover link-secondary link text-sm": true,
          "btn-disabled text-gray-500": loading,
        })}
      >
        {constants.SEND_LINK}
      </span>
      <Link
        to="/edit/general"
        className="link-hover link-secondary link text-sm"
      >
        {constants.EDIT_EMAIL}
      </Link>
    </div>
  );
};

export default EmailVerify;
