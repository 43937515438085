import TimeAgo from "components/TimeAgo";
import constants from "configs/constants";

const ProfileStatus = ({ online = false, last_seen = "" }) => {
  const bColor = online ? "badge-success" : "badge-primary";
  const color = online ? "text-success" : "text-primary";
  return (
    <>
      {online && <div className={`badge badge-xs ${bColor}`} />}
      <div className={`text-center text-base font-bold ${color}`}>
        <div>
          {!online ? (
            <div className="flex flex-col gap-y-2">
              <span className="text-xl">{`${constants.LAST_VISIT}`}</span>
              <TimeAgo className="!text-accent" time={last_seen} />
            </div>
          ) : (
            <div className="text-center text-xl font-bold">
              <span>{`${constants.ONLINE_NOW}`}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileStatus;
