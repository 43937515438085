import classNames from "classnames";
import Avatar from "components/Avatar";
import { BiTrash } from "react-icons/bi";

interface Props {
  title: string;
  content: string;
  image?: string;
  time: string;
  isRead: boolean;
  onClick?: () => void;
  onDelete?: () => void;
}

const SupportListItem = (props: Props) => {
  const { title, content, image, time, isRead, onClick } = props;

  return (
    <div
      className={classNames({
        "mx-auto my-3 flex w-[90%] cursor-pointer flex-row overflow-hidden rounded-3xl border-[0.1rem] border-gray-200 bg-white p-1 shadow-lg transition-all hover:border-gray-200 hover:bg-base-200 hover:opacity-90 hover:shadow-xl":
          true,
        "bg-white": isRead,
        "indicator bg-gray-200 font-bold": !isRead,
      })}
    >
      <div onClick={onClick} className="flex w-[85%] flex-row md:w-[90%]">
        <div className="mt-1 px-2 pl-3">
          <Avatar src={image} />
        </div>
        <div className="flex-1">
          <h3 className={"mb-1 w-44 truncate text-lg md:w-96"}>{title}</h3>
          <div className="flex  items-center gap-1 text-xs">
            <p className="w-44 truncate md:w-96">{content}</p>
          </div>
          <div className="mt-1 flex items-center gap-1 text-xs">
            {!!time && <span className="gap-1">{time}</span>}
          </div>
        </div>
      </div>

      <div className="flex items-center">
        <div
          onClick={props.onDelete}
          className={`btn btn-circle z-10 ml-2 flex items-center justify-center bg-gray-100 text-2xl text-primary`}
        >
          <BiTrash />
        </div>
      </div>
    </div>
  );
};

export default SupportListItem;
