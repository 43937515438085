import Button from "components/core/Button";
import constants from "configs/constants";
import RealInfo from "types/TRealInfo";

import { yupResolver } from "@hookform/resolvers/yup";
import schema from "components/forms/schema";
import useForm from "hooks/useForm";

import apiClientV2 from "api/client-v2";
import TapPayIcon from "assets/tap-payment";
import FormFiled from "components/forms/FormFiled";
import ProseSkeletons from "components/theme/skeleton/prose";
import useAuth from "hooks/useAuth";
import useIsMobile from "hooks/useIsMobile";
import useToast from "hooks/useToast";
import useNotes from "queries/notes";
import useUser from "queries/user";
import { useEffect, useState } from "react";
import { FaRegAddressCard } from "react-icons/fa";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { LuUser } from "react-icons/lu";
import { Navigate, useNavigate } from "react-router-dom";
import deepEqual from "utils/deepEqual";
import Notes from "./Notes";
import Tabs from "./Tabs";
import useWallet from "queries/wallets";

const Payments = () => {
  const { isAuth } = useAuth();
  const { engaged, male } = useUser();
  const { wallet } = useWallet();
  const { user, updateUser, setUser } = useUser();
  const { before_payment, payment_accounts, isLoading, isError, error } =
    useNotes();

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("tap");
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const toast = useToast();
  const isMobile = useIsMobile();

  const isPaidOrPendingAccount =
    wallet.type === "paid" || user.has_requested_payment;

  const isCash = tab === "cash";
  const youngInfo = user.real_info || {};
  const { handleSubmit, errors, control } = useForm<RealInfo>({
    resolver: yupResolver(schema.youngInfo),
    defaultValues: youngInfo,
  });

  const getPaymentUrl = async () => {
    const response = await apiClientV2.post("create-payment");
    if (response.ok) {
      if (isMobile) window.location.href = response.data as string;
      else setUrl(response.data as string);
    } else setLoading(false);
  };

  async function onDone() {
    if (isCash) {
      updateUser({ has_requested_payment: true });
      toast.warn("يمكنك طلب البيانات الفتاه بعد ان يأكد دفعك من قبل الادارة");
    } else setUser({ has_requested_payment: true });
    navigate("/engaged");
  }

  const closeModal = () => {
    setUrl("");
    setLoading(false);
  };

  const submit = async (info: RealInfo) => {
    setLoading(true);
    const nInfo = {
      national_number: info.national_number,
      birthday: info.birthday,
      name: info.name.trim(),
      phone: info.phone,
    };

    if (!deepEqual(youngInfo, nInfo)) updateUser({ real_info: nInfo });
    if (isCash) onDone();
    else getPaymentUrl();
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.event === "checkout:onClose") closeModal();
    };

    if (url) {
      document.addEventListener("click", closeModal);
      window.addEventListener("message", handleMessage);
    }

    return () => {
      document.removeEventListener("click", closeModal);
      window.removeEventListener("message", handleMessage);
    };
  }, [url]);

  useEffect(() => {
    if (url) {
      const iframe = document.querySelector("iframe");
      const intervalId = setInterval(() => {
        try {
          if (iframe && iframe.contentWindow) {
            const currentUrl = iframe.contentWindow.location.href;
            if (currentUrl?.includes(import.meta.env.VITE_DOMAIN)) {
              onDone();
            }
          }
        } catch (error) {
          console.error("Error accessing iframe URL:", error);
        }
      }, 500);
      return () => clearInterval(intervalId);
    }
  }, [url]);

  if (!isAuth) return <Navigate to="/login" />;
  if (!engaged) return <Navigate to="/" />;
  if (!male || isPaidOrPendingAccount) return <Navigate to="/engaged" />;
  if (isLoading) return <ProseSkeletons />;
  if (isError) throw new Error(error?.name);

  return (
    <div className="mx-auto mb-5 mt-3 flex max-w-6xl flex-col gap-y-4 px-[10%] md:px-[18%]">
      <Tabs isCash={isCash} setTab={setTab} />

      <Notes
        isCash={isCash}
        before_payment={before_payment}
        payment_accounts={payment_accounts}
      />

      <span className="label-text mt-2 text-lg font-bold leading-6">
        {constants.PLEASE_FILL_INFO}
      </span>
      <div className="flex w-full flex-col gap-x-2 gap-y-4 p-1 md:flex-row">
        <div className="flex flex-col gap-4 md:w-1/2">
          <FormFiled
            Icon={LuUser}
            control={control}
            error={errors.name}
            name="name"
            placeholder={constants.NAME}
          />
          <FormFiled
            type="number"
            Icon={FaRegAddressCard}
            control={control}
            error={errors.national_number}
            name="national_number"
            placeholder={constants.NATIONAL_NUM}
          />
        </div>

        <div className="flex flex-col gap-4 md:w-1/2">
          <FormFiled
            type="tel"
            name="phone"
            control={control}
            error={errors.phone}
            placeholder={constants.PHONE}
            Icon={HiOutlineDevicePhoneMobile}
          />
          <FormFiled
            type="date"
            name="birthday"
            control={control}
            error={errors.birthday}
            placeholder={constants.BIRTHDAY}
          />
        </div>
      </div>

      {isCash ? (
        <Button
          outline
          className="mt-5 min-w-[30%] !rounded-full"
          onClick={handleSubmit(submit)}
          color="btn-primary"
        >
          {constants.SEND_PAY_REQUEST}
        </Button>
      ) : (
        <Button
          outline
          loading={loading}
          Icon={() => <TapPayIcon />}
          className="mt-5 min-w-[12rem] !rounded-full"
          color="btn-info"
          onClick={handleSubmit(submit)}
        >
          {constants.TAP_PAY}
        </Button>
      )}

      <dialog className={`modal ${!!url && "modal-open"}`}>
        <iframe
          src={url}
          title="Alafaf Checkout"
          className="mx-auto h-[70vh] w-[90%] max-w-2xl rounded-xl md:h-[90vh] md:w-full"
        />
      </dialog>
    </div>
  );
};

export default Payments;
