export interface Type {
  gender?: string | null;
  age?: string | null;
  weight?: string | null;
  height?: string | null;
  nationality?: string[] | null;
  country?: string[] | null;
  city?: string[] | null;
  ageFrom?: string | null;
  ageTo?: string | null;
  weightFrom?: string | null;
  weightTo?: string | null;
  heightFrom?: string | null;
  heightTo?: string | null;
  education?: string[] | null;
  occupation?: string[] | null;
  relationship_status?: string[] | null;
  financial_status?: string[] | null;
  health?: string[] | null;
  prayers?: string[] | null;
  beauty?: string[] | null;
  skin_color?: string[] | null;
  clan?: string[] | null;
  origin?: string[] | null;
  description?: string;
  pending_description?: string;
}

const pref = {
  nationality: null,
  country: null,
  city: null,
  ageFrom: null,
  ageTo: null,
  weightFrom: null,
  weightTo: null,
  heightFrom: null,
  heightTo: null,
  education: null,
  occupation: null,
  relationship_status: null,
  financial_status: null,
  health: null,
  prayers: null,
  beauty: null,
  skin_color: null,
  clan: null,
  origin: null,
};

const step = (index: number) => {
  return index === 1
    ? ["nationality", "country", "clan", "origin"]
    : index === 2
    ? [
        "education",
        "occupation",
        "relationship_status",
        "financial_status",
        "health",
        "prayers",
      ]
    : index === 3
    ? [
        "beauty",
        "skin_color",
        "ageFrom",
        "ageTo",
        "heightFrom",
        "heightTo",
        "weightFrom",
        "weightTo",
      ]
    : ["description"];
};

const unnecessary = [
  "ageFrom",
  "ageTo",
  "heightFrom",
  "heightTo",
  "weightFrom",
  "weightTo",
];

export default {
  pref,
  step,
  unnecessary,
};
