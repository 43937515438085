// import supportApi from "api/support";
// import ChatUi from "components/chat";
// import useSupport, { addSupportMessage } from "queries/support";
// import useUser from "queries/user";
// import useThread from "stores/thread";
// import UserItem from "types/UserItem";
// import bsonId from "utils/bsonId";
// import { alafaf } from ".";

// interface Format {
//   owner: UserItem;
//   target: UserItem;
//   messages: {
//     message: string;
//     time: string;
//     fromUser: boolean;
//   }[];
// }

// const messagesFormat = ({ owner, target, messages }: Format) => {
//   return [...messages].reverse().map((message) => {
//     return {
//       _id: bsonId(),
//       text: message.message,
//       createdAt: message.time,
//       visible: true,
//       received: true,
//       unsent: false,
//       sent: true,
//       reported: false,
//       type: "text",
//       user: message.fromUser ? owner : target,
//     };
//   });
// };

// const Thread = () => {
//   const { user } = useUser();
//   const support = useSupport();

//   const th = useThread((store) => store.thread);
//   const resetThread = useThread((store) => store.resetThread);

//   const chat = support.chats.find((s) => s._id === th.id)!;
//   const subject = chat?.subject;
//   const subjectId = chat?._id;
//   const content = chat?.content || [];
//   const isSeen = content[content.length - 1]?.fromUser ? true : chat?.seen;

//   const messages = messagesFormat({
//     owner: { ...user.profile, ...user },
//     target: alafaf,
//     messages: content,
//   });

//   const onSend = (message: string) => {
//     if (!message) return;

//     const props = {
//       subjectId,
//       subject,
//       content: message,
//       isNewSubject: false,
//       fromUser: true,
//     };

//     supportApi.sendMessage(props);
//     addSupportMessage({ id: subjectId, subject, message, fromUser: true });
//   };

//   const makeMessageAsRead = () => {
//     const messages = chat.content;
//     if (!messages[messages.length - 1].fromUser && !chat.seen)
//       support.readMessage({ subjectId });
//   };

//   const Empty = () => {};

//   return (
//     <div className="z-50">
//       <ChatUi
//         visible
//         isTheadSeen={isSeen}
//         color="secondary"
//         showMessageStatus={false}
//         showBubbleActions={false}
//         messages={messages || []}
//         onSendMessage={onSend}
//         receiver={alafaf}
//         sender={user}
//         setVisibility={resetThread}
//         onFocus={makeMessageAsRead}
//         //
//         hideRemovedMessage={Empty}
//         removeMessageFromMe={Empty}
//         removeTargetMessage={Empty}
//         removeMessageFromAll={Empty}
//         reportMessage={Empty}
//         //
//         fetchNextPage={Empty}
//         hasNextPage={false}
//       />
//     </div>
//   );
// };

// export default Thread;

import supports from "api/support";
import supportImage from "assets/support.png";
import ChatUi from "components/chat";
import events from "configs/events";
import useEvent from "hooks/useEvent";
import useBadges from "queries/badges";
import useSupport, { addSupportMessage } from "queries/support";
import useSupportThread, {
  addSupportThreadMessage,
} from "queries/supportThread";
import useUser from "queries/user";
import { useRef } from "react";
import useThread from "stores/thread";
import { alafaf } from ".";

const Thread = () => {
  const { user } = useUser();
  const { chats, readMessage } = useSupport();

  const th = useThread((store) => store.thread);
  const resetThread = useThread((store) => store.resetThread);

  const chat = chats.find((s) => s._id === th.id)!;
  const id = chat?._id;
  const messages = chat?.messages || [];
  const seen = messages[messages.length - 1]?.is_from_user ? true : chat?.seen;

  const threadQuery = useSupportThread(th.id || "");
  const { setBadges } = useBadges();
  const isFocus = useRef(false);

  const onSend = (message: string) => {
    if (!message) return;

    addSupportMessage({ id, message, is_from_user: true });

    addSupportThreadMessage({ id, message, user });

    supports.append({ id, message });
  };

  const makeMessageAsRead = () => {
    isFocus.current = true;
    if (!seen) {
      readMessage(id);
      setBadges((b) => ({ ...b, support: Math.max(0, b.support - 1) }));
    }
  };

  useEvent({
    event: events.NEW_SUPPORT_MESSAGE,
    listener: () => {
      if (isFocus.current) makeMessageAsRead();
    },
  });

  const Empty = () => {};

  return (
    <div className="z-50">
      <ChatUi
        visible
        defaultPicture={supportImage}
        showMessageStatus={false}
        showBubbleActions={false}
        color={th.fromSupport ? "secondary" : "primary"}
        isTheadSeen={seen!}
        messages={threadQuery.thread || []}
        onSendMessage={onSend}
        receiver={{ ...alafaf, name: chat.subject, uid: user.uid }}
        reportMessage={Empty}
        sender={user}
        setVisibility={resetThread}
        onFocus={makeMessageAsRead}
        onBlur={() => (isFocus.current = false)}
        //
        hideRemovedMessage={Empty}
        removeMessageFromMe={Empty}
        removeTargetMessage={Empty}
        removeMessageFromAll={Empty}
        //
        fetchNextPage={threadQuery.fetchNextPage}
        hasNextPage={threadQuery.hasNextPage}
      />
    </div>
  );
};

export default Thread;
