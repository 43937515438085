import List from "components/lists/List";
import UserListItem from "components/lists/UserListItem";
import UserSkeletons from "components/theme/skeleton/users";
import constants from "configs/constants";
import useProposalUsers from "queries/proposalUsers";
import { useEffect, useRef, useState } from "react";
import UserItem from "types/UserItem";
import FilterList from "./FilterList";

const ProposalUsersPage = () => {
  const { proposalUsers, isLoading, isError, error } = useProposalUsers({
    all: true,
  });
  const [users, setUsers] = useState<UserItem[]>(proposalUsers);
  const initData = useRef(proposalUsers);

  const onFilter = (list: UserItem[] | string) => {
    if (list === "reset") setUsers(initData.current);
    else if (typeof list !== "string") setUsers([...list]);
  };

  const Filter = (
    <div className="flex w-full justify-end">
      <FilterList onSort={onFilter} list={initData.current} />
    </div>
  );

  useEffect(() => {
    if (!initData.current) initData.current = proposalUsers;
    setUsers(proposalUsers);
  }, [proposalUsers]);

  if (isLoading) return <UserSkeletons />;
  if (isError) throw new Error(error?.name);

  return (
    <div>
      <List
        data={users}
        FirstRow={Filter}
        itemClassName="!min-h-[40vh]"
        title={constants.PROPOSAL_MEMBERS}
        keyExtractor={(item) => item.uid}
        renderItem={(item) => {
          return (
            <UserListItem
              age={item.age}
              city={item.city}
              username={item.name}
              country={item.country}
              guardian={item.is_guardian}
              image={item.profile_picture}
              online={item.is_active && item.is_online}
              status={item.relationship_status}
              to={`/proposals/profile/${item.uid}`}
              state={{ ids: proposalUsers.map((e) => e.uid) }}
            />
          );
        }}
      />
    </div>
  );
};

export default ProposalUsersPage;
