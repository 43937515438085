export interface PropsType {
  is_guardian?: boolean | string | null;
  status?: boolean | string | null;
  children?: string[] | number[] | null;
  name?: string | null;
  age?: string;
  gender?: string;
  ageFrom?: string | number | null;
  ageTo?: string | number | null;
  weight?: string;
  weightFrom?: string | number | null;
  weightTo?: string | number | null;
  height?: string;
  heightFrom?: string | number | null;
  heightTo?: string | number | null;
  nationality?: string[] | null;
  city?: string[] | null;
  country?: string[] | null;
  education?: string[] | null;
  occupation?: string[] | null;
  relationship_status?: string[] | null;
  financial_status?: string[] | null;
  health?: string[] | null;
  prayers?: string[] | null;
  beauty?: string[] | null;
  skin_color?: string[] | null;
  clan?: string[] | null;
  origin?: string[] | null;
}

export default {
  is_guardian: null,
  status: null,
  name: "",
  ageFrom: null,
  ageTo: null,
  weightFrom: null,
  weightTo: null,
  heightFrom: null,
  heightTo: null,
  nationality: null,
  city: null,
  country: null,
  education: null,
  occupation: null,
  relationship_status: null,
  financial_status: null,
  health: null,
  prayers: null,
  beauty: null,
  skin_color: null,
  clan: null,
  origin: null,
  children: null,
};
