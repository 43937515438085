import chatsApi from "api/chats";
import ChatUi from "components/chat";
import constants from "configs/constants";
import events from "configs/events";
import useEvent from "hooks/useEvent";
import useThreadRef from "hooks/useThreadRef";
import useToast from "hooks/useToast";
import useBadges from "queries/badges";
import useBlocked from "queries/blocked";
import useChats, {
  hideMessage,
  readMessage,
  reportMessage,
} from "queries/chats";
import useEngage from "queries/engage";
import useThreadQuery, {
  addThreadMessage,
  removeThMessage,
} from "queries/thread";
import useUser from "queries/user";
import { useRef } from "react";
import useThread from "stores/thread";
import Message from "types/Message";
import bsonId from "utils/bsonId";
import isRemoved from "utils/isRemoved";

interface Remove {
  chatId: string;
  messageId: string;
}

const Thread = () => {
  const toast = useToast();
  const { user } = useUser();
  const { chats, ...chat } = useChats();
  const engagements = useEngage();

  const th = useThread((store) => store.thread);
  const { thread } = useThreadRef(th);
  const threadQuery = useThreadQuery({ chatId: th.id || "" });
  const resetThread = useThread((store) => store.resetThread);
  const { setBadges } = useBadges();
  const selectedChat = (chats || []).find(
    (c) => c?._id === th.id && c?.lastMessage?.user?._id !== user._id,
  );
  const seen = selectedChat ? selectedChat?.messageSeen : true;

  const isFocus = useRef(false);
  const { isBlockedMe } = useBlocked(th.receiver?._id || "");
  const isThisMyEngage = engagements.data
    ? !!engagements.data.find(
        (e) => e.user._id === th.receiver?._id && e.status === "accepted",
      )
    : false;

  const getPreviousMessage = (
    messageId: string,
    fn: (p: Remove) => Message[] | undefined,
  ) => {
    return fn({ chatId: thread.current.id!, messageId })!.filter(
      (m) => !m.unsent && m._id !== messageId,
    )[0];
  };

  const onSend = (message: string) => {
    if (!message) return;
    const receiver = thread.current.receiver!;

    const id = bsonId();
    const isReceiverEngage = ["engaged", "married"].includes(receiver!.status!);

    // blocked user
    if (isBlockedMe.me) return toast.error(constants.TOAST.USER_BLOCKED_YOU);

    if (isBlockedMe.who)
      return toast.error(`لقد قام ${receiver!.name} بحظر التواصل معك`);

    // if target in (engaged, married, delete) status => not accepted action
    if (isRemoved(receiver)) return toast.error(isRemoved(receiver));
    else if (!isThisMyEngage && isReceiverEngage)
      return toast.error(constants.TOAST.GIRL_ENGAGED_PROFILE);
    else {
      chat.send({ id, type: "text", receiver: receiver._id, message });
      addThreadMessage({
        messageId: id,
        message,
        sender: user,
        chatId: thread.current.id!,
        type: "text",
      });

      chatsApi.send({ id, type: "text", receiver: receiver._id, message });
    }
  };

  const reportingMessage = (messageId: string) => {
    const receiver = thread.current.receiver!;
    const th = threadQuery.thread.find((th) => th._id == messageId)!;

    if (th.reported) toast.warn(constants.TOAST.YOU_HAVE_REPORT_BEFORE);
    else {
      chatsApi.report({ receiver: receiver._id, messageId, message: th.text });
      toast.success(constants.TOAST.REPORT_SUCCESSFUL);
      threadQuery.reportMessage({ chatId: thread.current.id!, messageId });
      reportMessage({ userId: receiver._id, messageId });
    }
  };

  const hidingMessage = (messageId: string) => {
    const message = getPreviousMessage(messageId, threadQuery.unsentMessage);

    const receiver = thread.current.receiver!;
    hideMessage({ userId: receiver._id, messageId, message });
    chatsApi.hide({ receiver: receiver._id, messageId, fromAll: false });
  };

  const hideRemovedMessage = (messageId: string) => {
    const receiver = thread.current.receiver!;
    const message = getPreviousMessage(messageId, threadQuery.unsentMessage);

    chat.remove({ userId: receiver._id, messageId, message });
    threadQuery.unsentMessage({ chatId: thread.current.id!, messageId });
  };

  const removeMessageFromAll = (messageId: string) => {
    const receiver = thread.current.receiver!;
    const current = threadQuery.thread.find((th) => th._id === messageId);
    const message = getPreviousMessage(messageId, removeThMessage);

    hideMessage({
      userId: receiver._id,
      messageId,
      message: message || { ...current, text: constants.MESSAGE_REMOVED },
    });

    chatsApi.hide({ receiver: receiver._id, messageId, fromAll: true });
  };

  const makeMessageAsRead = () => {
    isFocus.current = true;
    const receiver = thread.current.receiver!;
    if (!seen) {
      chatsApi.read(th.id!);
      readMessage(receiver._id);
      setBadges((b) => ({ ...b, chats: Math.max(0, b.chats - 1) }));
    }
  };

  // on receive new message
  useEvent({
    event: events.GOT_MESSAGE,
    listener: () => {
      if (isFocus.current) makeMessageAsRead();
    },
  });

  return (
    <div className="z-50">
      <ChatUi
        visible
        alphabeticNumber
        showMessageStatus={th.showMessageStatus}
        showBubbleActions={!th.fromSupport}
        color={th.fromSupport ? "secondary" : "primary"}
        isTheadSeen={seen!}
        messages={threadQuery.thread || []}
        onSendMessage={onSend}
        receiver={th.receiver || user}
        reportMessage={reportingMessage}
        sender={user}
        setVisibility={resetThread}
        onFocus={makeMessageAsRead}
        onBlur={() => (isFocus.current = false)}
        //
        hideRemovedMessage={hideRemovedMessage}
        removeMessageFromMe={hidingMessage}
        removeTargetMessage={hidingMessage}
        removeMessageFromAll={removeMessageFromAll}
        //
        fetchNextPage={threadQuery.fetchNextPage}
        hasNextPage={threadQuery.hasNextPage}
      />
    </div>
  );
};

export default Thread;
