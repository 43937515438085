import StepProgressBar from "components/StepProgressBar";
import Modal from "components/core/Modal";
import Loading from "components/theme/Loading";
import constants from "configs/constants";
import { useEffect, useState } from "react";

import PrStepFour from "pages/users/utils/steps/profile/Four";
import PrStepOne from "pages/users/utils/steps/profile/One";
import PrStepThree from "pages/users/utils/steps/profile/Three";
import PrStepTow from "pages/users/utils/steps/profile/Tow";

import PfStepFour from "pages/users/utils/steps/preferences/Four";
import PfStepOne from "pages/users/utils/steps/preferences/One";
import PfStepThree from "pages/users/utils/steps/preferences/Three";
import PfStepTow from "pages/users/utils/steps/preferences/Tow";

import { yupResolver } from "@hookform/resolvers/yup";
import guardians from "api/guardians";
import girl from "assets/girl.png";
import young from "assets/young.png";
import Button from "components/core/Button";
import FormFiled from "components/forms/FormFiled";
import schema from "components/forms/schema";
import useForm from "hooks/useForm";
import useToast from "hooks/useToast";
import TProps from "pages/users/utils/TProps";
import pfProps, { Type } from "pages/users/utils/preferencesProps";
import prProps from "pages/users/utils/profileProps";
import queryClient from "queries/queryClient";
import { RiUser3Line } from "react-icons/ri";
import User from "types/User";
import UserItem from "types/UserItem";
import * as Yup from "yup";
import auth from "api/auth";
import formatPreferences from "pages/users/utils/formatPreferences";

const genders = [
  {
    id: "1",
    src: young,
    label: constants.YOUNG,
    gender: "male",
    is_guardian: false,
  },
  {
    id: "2",
    src: girl,
    label: constants.GIRL,
    gender: "female",
    is_guardian: false,
  },
];

interface Props {
  setVisibility: (v: boolean) => void;
}

interface Value {
  label: string;
  value: number;
}

const CreateUser = ({ setVisibility }: Props) => {
  const [name, setName] = useState("");
  const [gender, setGender] = useState("male");
  const [profile, setProfile] = useState({ ...prProps.user, gender } as TProps);
  const [preferences, setPrefs] = useState({ ...pfProps.pref, gender } as Type);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const toast = useToast();

  const isPrefSteps = currentStep > 3;
  const isFirstStart = !name;

  const { control, errors, handleSubmit } = useForm<
    Yup.InferType<typeof schema.name>
  >({ resolver: yupResolver(schema.name), defaultValues: { name: "" } });

  async function onSetName({ name = "" }) {
    const isValidName = await auth.checkUserExistence({ username: name });
    if (isValidName.ok) setName(name);
    else {
      setUploadError(
        isValidName.data?.message || constants.ERRORS.UNEXPECTED_ERROR,
      );
      setTimeout(() => setUploadError(""), 2000);
    }
  }

  const refactorProfile = () => {
    const data = { ...profile };
    delete data.age;
    delete data.profile_picture;

    data.weight = (data.weight as Value)!.value;
    data.height = (data.height as Value)!.value;
    data.children = (data.children as Value)!.value;

    return {
      account: {
        age: profile.age,
        profile_picture: profile.profile_picture || undefined,
        name,
        gender,
        is_guardian: true,
        completed_profile: 1,
      },
      profile: data,
    };
  };

  const nextStep = async (step: number) => {
    const props = isPrefSteps ? pfProps : prProps;
    const user = isPrefSteps ? preferences : profile;
    const theStep = isPrefSteps ? step % 4 : step;
    const thereIsError = props
      .step(theStep)
      .filter((e) => !user[e as keyof typeof user]);

    if (step === 8 && !thereIsError.length) register();
    else if (!thereIsError.length) {
      setCurrentStep(step);
    } else setErrorMessages(thereIsError);
  };

  const register = async () => {
    setLoading(true);
    setUploadError("");

    const { account, profile } = refactorProfile();
    const prefs = formatPreferences({
      preferences: { ...preferences },
      action: "create",
    });

    try {
      const response = await guardians.createUser({
        account,
        profile,
        preferences: prefs,
      } as User);

      if (response.ok) {
        queryClient.setQueryData<UserItem[]>(["guardians"], (guardians) => [
          ...(guardians || []),
          response.data!,
        ]);
        toast.success(constants.SUCCESS.CREATE, "bottom");
        setVisibility(false);
      } else {
        setUploadError(constants.ERRORS.INFO_UPLOAD + ".");
        return setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPrefs((p) => ({ ...p, gender }));
    setProfile((p) => ({ ...p, gender }));
  }, [gender]);

  return (
    <Modal
      visible
      setVisibility={setVisibility}
      className="no-scrollbar h-[95vh] !max-h-[95vh] !max-w-screen-lg overflow-hidden"
    >
      <div className="h-[100%] overflow-hidden pl-[2px]">
        <Loading visible={loading} />
        {isFirstStart ? (
          <div className="mx-auto mt-10 flex h-full w-[60%] flex-col gap-y-10">
            <p className="mb-8 text-center font-bold md:text-2xl">
              بيانات الاولية للعضو
            </p>
            <div className="join flex justify-center">
              {genders.map((item) => {
                const isSelected = item.gender === gender;
                return (
                  <div
                    key={item.id}
                    onClick={() => setGender(item.gender)}
                    className={`mx-1 flex flex-col overflow-hidden rounded-xl bg-base-200 hover:bg-primary ${
                      isSelected && "bg-primary"
                    }`}
                  >
                    <img
                      src={item.src}
                      className={`btn join-item border-0 bg-transparent hover:bg-primary`}
                    />
                    <span
                      className={`mt-1 cursor-pointer text-center text-xs ${
                        isSelected && "text-white"
                      }`}
                    >
                      {item.label}
                    </span>
                  </div>
                );
              })}
            </div>

            <div>
              <FormFiled
                type="text"
                name="name"
                alphabetic
                maxLength={20}
                Icon={RiUser3Line}
                control={control}
                error={errors.name}
                placeholder={constants.NAME}
                containerClassName="border-accent"
              />
              {!errors.name && (
                <span className="mr-2 text-xs text-success">
                  {constants.NAME_IN_ARABIC_ONLY}
                </span>
              )}
            </div>

            {!!uploadError && (
              <span className={`-mt-6 text-sm text-error`}>{uploadError}</span>
            )}

            <Button
              outline
              color="btn-accent"
              onClick={handleSubmit(onSetName)}
              className="mx-auto mt-16 border-accent"
            >
              {constants.NEXT}
            </Button>
          </div>
        ) : !isPrefSteps ? (
          <StepProgressBar
            title={"1- " + constants.YOUR_GUARDIAN_PREFERENCES}
            next={() => nextStep(currentStep + 1)}
            previous={() => setCurrentStep((s) => s - 1)}
            className="h-[100%] pb-72"
            containerClassName="max-md:!h-[100vh]"
            currentStep={currentStep}
            steps={[
              {
                label: constants.BIRTH_AFFILIATION,
                content: (
                  <PrStepOne
                    user={profile}
                    setUser={setProfile}
                    errors={errorMessages}
                    setErrors={setErrorMessages}
                  />
                ),
              },
              {
                label: constants.STATUS,
                content: (
                  <PrStepTow
                    user={profile}
                    setUser={setProfile}
                    errors={errorMessages}
                    setErrors={setErrorMessages}
                  />
                ),
              },
              {
                label: constants.SEMBLANCE,
                content: (
                  <PrStepThree
                    user={profile}
                    setUser={setProfile}
                    errors={errorMessages}
                    setErrors={setErrorMessages}
                  />
                ),
              },
              {
                label: constants.BIO,
                content: (
                  <PrStepFour
                    user={profile}
                    setUser={setProfile}
                    errors={errorMessages}
                    uploadError={uploadError}
                    setErrors={setErrorMessages}
                  />
                ),
              },
            ]}
          />
        ) : (
          <StepProgressBar
            title={"2- " + constants.YOUR_GUARDIAN_PARTNER_PREFERENCES}
            next={() => nextStep(currentStep + 1)}
            previous={() => setCurrentStep((s) => s - 1)}
            className="h-[100%] pb-72"
            containerClassName="max-md:!h-[100vh]"
            currentStep={currentStep % 4}
            forceShowPrevious
            steps={[
              {
                label: constants.BIRTH_AFFILIATION,
                content: (
                  <PfStepOne
                    prefs={preferences}
                    setPrefs={setPrefs}
                    errors={errorMessages}
                    setErrors={setErrorMessages}
                  />
                ),
              },
              {
                label: constants.STATUS,
                content: (
                  <PfStepTow
                    prefs={preferences}
                    setPrefs={setPrefs}
                    errors={errorMessages}
                    setErrors={setErrorMessages}
                  />
                ),
              },
              {
                label: constants.SEMBLANCE,
                content: (
                  <PfStepThree
                    prefs={preferences}
                    setPrefs={setPrefs}
                    errors={errorMessages}
                    setErrors={setErrorMessages}
                  />
                ),
              },
              {
                label: constants.BIO,
                content: (
                  <PfStepFour
                    prefs={preferences}
                    setPrefs={setPrefs}
                    errors={errorMessages}
                    setErrors={setErrorMessages}
                    uploadError={uploadError}
                  />
                ),
              },
            ]}
          />
        )}
      </div>
    </Modal>
  );
};

export default CreateUser;
