import { keepPreviousData, useQuery } from "@tanstack/react-query";
import search from "api/search";
import ms from "ms";
import { PropsType } from "pages/search/props";

interface Params {
  page: number;
  params: PropsType;
  guest?: boolean;
}

const useSearch = ({ page, params, guest = false }: Params) => {
  return useQuery({
    queryKey: ["search", { ...params, page, guest }],
    queryFn: () =>
      guest
        ? search.getForGuest(params, page).then((res) => res.data)
        : search.get(params, page).then((res) => res.data),
    staleTime: ms("30m"),
    placeholderData: keepPreviousData,
  });
};

export default useSearch;
