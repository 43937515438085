import * as Sentry from "@sentry/react";
import constants from "configs/constants";
import { useEffect } from "react";
import { PiSmileySadThin } from "react-icons/pi";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import LandingNavbar from "./Navbar";

const LandingError = () => {
  const error = useRouteError();
  const isRouteError = isRouteErrorResponse(error);

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <div>
      <LandingNavbar />
      <div className="flex h-[85vh] flex-col items-center justify-end">
        <div className="no-scrollbar flex h-full items-center justify-center">
          <article className="prose  text-center">
            <PiSmileySadThin className="m-auto self-center text-center text-8xl" />
            <p>
              {isRouteError
                ? constants.ROUTE_NOT_EXIST
                : constants.ERRORS.UNEXPECTED_ERROR}
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default LandingError;
