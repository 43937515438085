import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";

import authApi from "api/auth";
import { LoginParams } from "api/types/login";

import constants from "configs/constants";

import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/core/Button";
import CheckBox from "components/core/CheckBox";
import FormFiled from "components/forms/FormFiled";
import schema from "components/forms/schema";
import useForm from "hooks/useForm";
import Auth from "layouts/Auth";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { RiLockPasswordLine } from "react-icons/ri";
import refreshPage from "utils/refreshPage";
import storage from "utils/storage";

interface PhoneFormType {
  phone: string;
  password: string;
}

const Login = ({ isLandingPage = false }) => {
  const navigate = useNavigate();

  const [rememberMe, setRememberMe] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const user = JSON.parse((localStorage.getItem("user") as string) || "{}");

  const { control, errors, handleSubmit } = useForm<PhoneFormType>({
    resolver: yupResolver(schema.loginWithPhone),
    defaultValues: { phone: user.phone || "", password: user.password || "" },
  });

  const onSubmit = async ({ phone, password }: LoginParams) => {
    setLoading(true);
    setError("");

    function save(t: string, rt: string, guardian: boolean) {
      if (rememberMe) {
        const defData = { phone, password };
        localStorage.setItem("user", JSON.stringify(defData));
      }
      storage.store("guardian", guardian);
      storage.storeToken(t, rt);
      refreshPage("/");
    }

    try {
      const data: LoginParams = { phone, password, withPhone: true };
      const user = await authApi.login(data);
      if (user.ok) {
        const token = user.data?.token;
        const refresh_token = user.data?.refresh_token;

        if (user.data?.guardian) return save(token!, refresh_token!, true);

        const completed = user.data?.account.completed_profile;
        const gender = user.data?.account.gender;
        const is_guardian = user.data?.account.is_guardian;

        if (completed === -1) {
          const route = "/build/profile";
          const build = { route, gender, is_guardian, token };
          storage.store("build", JSON.stringify(build));
          navigate(route);
        } else if (completed === 0) {
          const route = "/build/preferences";
          const build = { route, gender, is_guardian, token };
          storage.store("build", JSON.stringify(build));
          navigate(route);
        } else save(token!, refresh_token!, false);
      } else if (user.data && user.data.message) setError(user.data.message);
      else setError(constants.ERRORS.UNEXPECTED_ERROR);

      setTimeout(() => setError(""), 4000);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const Wrapper = isLandingPage
    ? ({ children }: { children: ReactNode }) => <>{children}</>
    : Auth;

  return (
    <Wrapper>
      <div
        className={`flex flex-col items-center ${
          isLandingPage ? "mt-4" : "h-screen justify-end"
        }`}
      >
        <div
          className={`"no-scrollbar z-10 flex h-[65%] w-[98%] flex-col gap-y-5 overflow-y-auto rounded-tl-3xl rounded-tr-3xl border-[0.1rem] border-primary bg-white px-5 pb-5 pt-10 md:h-auto md:w-[90%] md:pb-20 ${
            !isLandingPage ? "border-b-0" : "rounded-2xl"
          }`}
        >
          <div>
            <FormFiled
              type="tel"
              name="phone"
              control={control}
              placeholder={constants.PHONE}
              error={errors.phone}
              Icon={HiOutlineDevicePhoneMobile}
              containerClassName=" border-primary"
            />
          </div>

          <div>
            <FormFiled
              type="password"
              name="password"
              control={control}
              error={errors.password}
              Icon={RiLockPasswordLine}
              placeholder={constants.PASSWORD}
              containerClassName="border-primary"
            />
          </div>

          {!!error && <p className="mr-3 text-xs text-error">{error}</p>}

          <CheckBox
            color="checkbox-primary"
            size="checkbox-sm"
            title={constants.REMEMBER_ME}
            checked={rememberMe}
            toggle={() => setRememberMe(!rememberMe)}
          />

          <div className="flex flex-col gap-y-1">
            <span
              onClick={() => navigate("/reset")}
              className="link-hover link mx-auto text-xs hover:text-primary"
            >
              {constants.ERRORS.FORGOT_PASSWORD}
            </span>
          </div>

          <Button
            outline
            loading={loading}
            className="w-[80%]"
            color="btn-primary"
            onClick={handleSubmit(onSubmit)}
          >
            {constants.LOGIN}
          </Button>

          {!isLandingPage && (
            <Button
              outline
              loading={loading}
              className="w-[80%]"
              color="btn-primary"
              onClick={() => navigate("/register")}
            >
              {constants.SING_FOR_FREE}
            </Button>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Login;
