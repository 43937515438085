import classNames from "classnames";
import Avatar from "components/Avatar";
import { ReactNode } from "react";

interface Props {
  title: string;
  subTitle: string;
  time?: string;
  image?: string;
  Icon?: ReactNode;
  onClick?: () => void;
  showChevron?: boolean;
  badge?: number;
  online?: boolean;
  isRead?: boolean;
  received?: boolean;
  sent?: boolean;
  guardian?: boolean;
}

const ChatListItem = ({
  title,
  subTitle,
  image,
  time,
  onClick,
  badge,
  online = false,
  isRead = true,
  received = false,
  sent = false,
  guardian,
}: Props) => {
  return (
    <div
      onClick={onClick}
      className={classNames({
        "mx-auto my-3 flex w-[90%] cursor-pointer flex-row overflow-hidden rounded-3xl border-[0.1rem] border-gray-200 p-1 shadow-lg transition-all hover:border-gray-200 hover:opacity-90 hover:shadow-xl":
          true,
        "bg-white": isRead,
        "indicator font-extrabold": !isRead,
      })}
    >
      <div className="mt-1 px-2 pl-3">
        <Avatar
          src={image}
          bordered={guardian}
          badge={badge}
          online={online}
          offline={!online}
        />
      </div>
      <div className="flex-1">
        <h3 className="mb-1 w-44 text-lg md:w-96 md:text-xl">{title}</h3>
        <div className="flex  items-center gap-1 text-xs">
          <p className="w-44 truncate md:w-96">{subTitle}</p>
        </div>
        <div className="mt-1 flex items-center gap-1 text-xs">
          {!!time && <span className="gap-1">{time}</span>}
          <span className=" font-extrabold text-[14] text-green-500">
            {!!sent && "✓"} {!!received && "✓"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChatListItem;
