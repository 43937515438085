import letter from "assets/letter.png";
import loves from "assets/loves.png";

const Hero = () => {
  return (
    <section className="relative mx-auto max-w-6xl overflow-hidden py-10">
      <div className="indicator mb-10 flex h-full w-full items-center justify-end">
        <span className="indicator-item indicator-middle absolute right-[50%] top-[50%] z-[2] -mr-[13%] h-fit w-[70%] overflow-hidden rounded-2xl border-[2px] border-base-300 bg-base-100 sm:-mr-[20%] sm:w-[50%] lg:max-w-[40%]">
          <div className="p-4 text-xl font-bold md:p-8 md:text-3xl lg:text-4xl">
            <div className="mb-1 whitespace-normal text-primary md:leading-[3rem]">
              {"موقع خطوبة "}
              <div className="text-secondary">
                اكبر واول موقع للزواج وفقا للضوابط الشرعية
              </div>
            </div>
          </div>
        </span>
        <div className="z-[1] ml-4 grid max-w-[85%] place-items-center object-contain">
          <img src={letter} className="sm:h-[50vh] md:h-[27rem]" />
        </div>
      </div>
      <img
        className="absolute -bottom-3 -left-20 h-52 w-52 md:flex"
        src={loves}
      />
    </section>
  );
};

export default Hero;
