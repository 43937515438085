import api from "configs/api-v2";
import apiClient from "./client-v2";

import { LoginParams, LoginResponse } from "./types/login";
import {
  GuardianParams,
  RegisterParams,
  RegisterResponse,
} from "./types/register";

const endpoints = api.endpoints;

interface Out {
  token: string;
  refresh_token: string;
  guardian: boolean;
}

const registerGuardian = (user: GuardianParams) =>
  apiClient.post<RegisterResponse, Error>(endpoints.register_guardian, user);
const register = (user: RegisterParams) =>
  apiClient.post<RegisterResponse, Error>(endpoints.register, user);
const login = (user: LoginParams) =>
  apiClient.post<LoginResponse, Error>(endpoints.login, user);
const logout = () => apiClient.get<Out>(endpoints.logout, {});

const resetPassword = (data: { email: string; newPassword: string }) =>
  apiClient.post<{ message: string }, Error>(endpoints.reset_password, data);

const checkUserExistence = (data: {
  username?: string;
  email?: string;
  phone?: string;
  guardian?: boolean;
}) => apiClient.post<unknown, Error>(endpoints.check_user_existence, data);

const sendOtp = (to: string) =>
  apiClient.get<string>(`${endpoints.send_otp}/${to}`);
const verifyOtp = (code: string, token: string) =>
  apiClient.get<{ message: string; token: string }, Error>(
    `${endpoints.verify_otp}/${code}?t=${token}`,
  );

const sendTwilioOtp = (to: string) =>
  apiClient.get<string>(`${endpoints.send_twilio_otp}/${to}`);
const verifyTwilioOtp = (to: string, code: string) =>
  apiClient.get<{ message: string; token: string }, Error>(
    `${endpoints.verify_twilio_otp}/${to}/${code}`,
  );

const verifyFirebaseAuthOtp = (to: string) =>
  apiClient.get<{ message: string; token: string }, Error>(
    `${endpoints.verify_firebase}/${to}`,
  );

const sendEmail = (to: string) =>
  apiClient.get(`${endpoints.send_email}${to}/verify-email`);

const checkToken = (id: string) =>
  apiClient.get<{ token: string }, Error>(`${endpoints.tokens}/${id}`);

export default {
  registerGuardian,
  register,
  login,
  logout,
  resetPassword,
  checkUserExistence,

  sendOtp,
  sendTwilioOtp,
  verifyTwilioOtp,
  verifyOtp,
  verifyFirebaseAuthOtp,

  sendEmail,

  checkToken,
};
