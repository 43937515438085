import { useQuery } from "@tanstack/react-query";
import utils from "api/utils";
import useAuth from "hooks/useAuth";
import ms from "ms";
import Badges from "types/Badges";
import queryClient from "./queryClient";

export const refreshBadgesQuery = () =>
  queryClient.invalidateQueries({ queryKey: ["badges"] });

const useBadges = () => {
  const { isAuth, isGuardian } = useAuth();

  const query = useQuery({
    queryKey: ["badges"],
    staleTime: ms("2h"),
    queryFn: () =>
      isAuth && !isGuardian
        ? utils.getBadges().then((res) => res.data)
        : Promise.resolve<Badges>({} as Badges),
  });

  const setBadges = (set: (badges: Badges) => Badges) => {
    const badges = queryClient.getQueryData<Badges>(["badges"])!;
    queryClient.setQueryData<Badges>(["badges"], () => set(badges));
  };

  return { badges: query.data!, setBadges };
};

export default useBadges;
