import api from "configs/api-v2";
import apiClient from "./client-v2";

const endpoints = api.endpoints;

const about = () => apiClient.get<string, Error>(endpoints.about);
const privacy = () => apiClient.get<string, Error>(endpoints.privacy);
const terms = () => apiClient.get<string, Error>(endpoints.terms);
const contacts = () => apiClient.get<string, Error>(endpoints.contacts);

export default {
  about,
  privacy,
  terms,
  contacts,
};
