import Avatar from "components/Avatar";
import { AiFillHeart } from "react-icons/ai";
import { BiBlock } from "react-icons/bi";
import { CgEye } from "react-icons/cg";
import { GiDiamondRing } from "react-icons/gi";
import { SlLike, SlLocationPin } from "react-icons/sl";
import { Link } from "react-router-dom";
import { amPm_12h } from "utils/time";

const icons = {
  likes: SlLike,
  visits: CgEye,
  blocks: BiBlock,
  favorites: AiFillHeart,
};

interface Props {
  age: number;
  city: string;
  country: string;
  guardian?: boolean;
  iconFor?: keyof typeof icons;
  image?: string;
  matchedPercent?: number;
  status: string;
  time?: string;
  to: string;
  username: string;
  online?: boolean;
  state?: any;
  className?: string;
  target?: React.HTMLAttributeAnchorTarget | undefined;
}

const UserListItem = ({
  age,
  city,
  country,
  guardian,
  iconFor,
  image,
  matchedPercent,
  status,
  to,
  time,
  username,
  online,
  state,
  className = "",
  target,
}: Props) => {
  const Icon = iconFor ? icons[iconFor] : null;

  return (
    <Link
      to={to}
      state={state}
      target={target}
      className={`mx-auto my-3 flex w-[90%] cursor-pointer flex-row overflow-hidden rounded-3xl border-[0.1rem] border-gray-200 bg-white p-1 shadow-lg transition-all hover:border-gray-200 hover:opacity-95 hover:shadow-xl ${className}`}
    >
      <div className="mt-1 px-2 pl-3">
        <Avatar
          online={online}
          offline={online === false ? true : undefined}
          src={image}
          bordered={guardian}
          badge={matchedPercent}
        />
      </div>
      <div className="flex-1">
        <h3 className="mb-1 w-44 truncate text-lg md:w-96 md:text-xl">
          {username}
        </h3>
        <div className="flex items-center gap-1 text-xs">
          <SlLocationPin className="text-error" />
          {country} / {city}
        </div>
        <div className="mt-1 flex items-center gap-1 text-xs">
          <GiDiamondRing className="text-accent" />
          {status} / العمر {age}
        </div>
      </div>
      <div
        className={`flex flex-col items-center justify-center ${
          time && "justify-between"
        }`}
      >
        {!!time && (
          <span className="text-[10px] md:text-xs">{amPm_12h(time)}</span>
        )}
        {!!iconFor && (
          <div
            className={`flex w-16 items-center justify-center text-lg text-primary md:text-2xl ${
              time && "mb-4"
            }`}
          >
            {!!Icon && <Icon />}
          </div>
        )}
      </div>
    </Link>
  );
};

export default UserListItem;
