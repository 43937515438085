import { useQuery } from "@tanstack/react-query";
import wallets from "api/wallets";
import useAuth from "hooks/useAuth";
import ms from "ms";
import TWallet from "types/TWallet";
import queryClient from "./queryClient";

export const refreshWalletQuery = () =>
  queryClient.invalidateQueries({ queryKey: ["wallet"] });

const useWallet = () => {
  const { isAuth, isGuardian } = useAuth();

  const query = useQuery({
    queryKey: ["wallet"],
    staleTime: ms("2h"),
    queryFn: () =>
      isAuth && !isGuardian
        ? wallets.get().then((res) => res.data)
        : Promise.resolve<TWallet>({} as TWallet),
  });

  const updateWallet = (update: TWallet) => {
    queryClient.setQueryData<TWallet>(["wallet"], () => update);
  };

  return {
    updateWallet,
    wallet: query.data!,
    isLoading: query.isLoading,
    isError: query.isError,
  };
};

export default useWallet;
