import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import chats from "api/chats";
import dayjs from "dayjs";
import { chunk } from "lodash";
import ms from "ms";
import GotMessage from "types/GotMessage";
import Message from "types/Message";
import queryClient from "./queryClient";

interface Remove {
  chatId: string;
  messageId: string;
}

interface UpdateThread {
  chatId: string;
  messageId: string;
  update: (c: Message) => Message;
}

const LIMIT_THREAD_LIST = 20;

const isQueryExist = (id: string) =>
  !!queryClient.getQueryData<InfiniteData<Array<Message>>>(["thread", id])
    ?.pages.length;

export const refreshThreadQuery = ({ threadId }: { threadId: string }) => {
  queryClient.invalidateQueries({ queryKey: ["thread", threadId] });
};

const useThreadQuery = ({ chatId = "" }) => {
  const query = useInfiniteQuery({
    queryKey: ["thread", chatId],
    queryFn: ({ pageParam = 1 }) =>
      chats.getThread(chatId, pageParam).then((res) => res.data!),
    getNextPageParam: (lastPage, allPage) => {
      return lastPage.length === LIMIT_THREAD_LIST
        ? allPage.length + 1
        : undefined;
    },
    select: (data) => data.pages.flatMap((x) => x),
    initialPageParam: 1,
    staleTime: ms("2h"),
  });

  const hideMessage = ({ chatId, messageId }: Remove) => {
    updateThread({
      chatId,
      messageId,
      update: (message) => ({ ...message, visible: false }),
    });
  };

  const unsentMessage = ({ chatId, messageId }: Remove) => {
    return queryClient
      .setQueryData<InfiniteData<Array<Message>>>(
        ["thread", chatId],
        (thread) => {
          const result = thread!.pages
            .flat()
            .filter((message) => message._id !== messageId);

          return {
            ...thread!,
            pages: !result.length ? [[]] : chunk(result, LIMIT_THREAD_LIST),
          };
        },
      )
      ?.pages.flat();
  };

  const reportMessage = ({ chatId, messageId }: Remove) => {
    updateThread({
      chatId,
      messageId,
      update: (message) => ({ ...message, reported: true }),
    });
  };

  return {
    ...query,
    reportMessage,
    hideMessage,
    unsentMessage,
    thread: query.data!,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export const updateThread = ({ chatId, messageId, update }: UpdateThread) => {
  if (!isQueryExist(chatId)) return;

  return queryClient
    .setQueryData<InfiniteData<Array<Message>>>(
      ["thread", chatId],
      (thread) => {
        const result = thread!.pages.flat().map((message) => {
          if (message._id === messageId) return update(message);
          return message;
        });

        return {
          ...thread!,
          pages: !result.length ? [[]] : chunk(result, LIMIT_THREAD_LIST),
        };
      },
    )
    ?.pages.flat();
};

export const removeThMessage = ({ messageId, chatId }: Remove) => {
  return updateThread({
    chatId,
    messageId,
    update: (message) => ({ ...message, visible: false }),
  });
};

export const readThMessages = ({ chatId }: { chatId: string }) => {
  if (!isQueryExist(chatId)) return;

  queryClient.setQueryData<InfiniteData<Array<Message>>>(
    ["thread", chatId],
    (thread) => {
      const result = thread!.pages
        .flat()
        .map((message) => ({ ...message, sent: true }));
      return { ...thread!, pages: chunk(result, LIMIT_THREAD_LIST) };
    },
  );
};

export const addThreadMessage = (got: GotMessage) => {
  if (!isQueryExist(got.chatId!)) return;

  queryClient.setQueryData<InfiniteData<Array<Message>>>(
    ["thread", got.chatId],
    (thread) => {
      const result = [
        {
          _id: got.messageId,
          text: got.message,
          createdAt: dayjs().toString(),
          sent: false,
          type: "text",
          received: true,
          reported: false,
          visible: true,
          unsent: false,
          user: got.sender,
        },
        ...thread!.pages.flat(),
      ];

      return { ...thread!, pages: chunk(result, LIMIT_THREAD_LIST) };
    },
  );
};

export default useThreadQuery;
