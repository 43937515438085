import Avatar from "components/Avatar";
import SelectPicker from "components/picker/Select";
import constants from "configs/constants";
import useStorage from "hooks/useStorage";
import TProps from "../../TProps";
import Props from "../../profileProps";

interface Value {
  male: string[];
  female: string[];
}

interface Props {
  withoutPicture?: boolean;
  user: TProps;
  setUser: (v: TProps) => void;
  errors: string[];
  setErrors: (v: string[]) => void;
}

const StepThree = ({
  user,
  setUser,
  errors,
  setErrors,
  withoutPicture = false,
}: Props) => {
  const { options, images } = useStorage();
  const gender = user.gender as keyof Value;
  const pictures = images[gender];

  const onChangePicture = async (profile_picture: string) => {
    setUser({ ...user, profile_picture });
  };

  const generateList = (start: number, end: number, label: string) => {
    const arr: { label: string; value: number }[] = [];
    for (let i = start; i <= end; i++)
      arr.push({ value: i, label: i + " " + label });
    return arr;
  };

  const validate = (propName: string) => {
    if (errors.includes(propName))
      setErrors(errors.filter((e) => e !== propName));
  };

  const { education, prayers, height, weight } = user;
  const { profile_picture, beauty, skin_color } = user;
  const education_options = options.education_options[gender];
  const prayers_options = options.prayers_options[gender];
  const weightList = generateList(40, 150, "كغ");
  const heightList = generateList(150, 250, "سم");
  const beauty_options = options.beauty_options[gender];
  const skin_color_options = options.skin_color_options[gender];

  return (
    <div className={`px-4 ${withoutPicture ? "h-full" : "h-[100vh]"}`}>
      <div className="mb-10 flex w-full justify-center md:mb-4">
        {!withoutPicture && (
          <Avatar
            size="lg"
            images={pictures}
            onEdit={onChangePicture}
            src={profile_picture || undefined}
          />
        )}
      </div>
      <div className="flex w-full max-md:mt-5 max-md:flex-col max-md:items-center max-md:pb-52">
        <div className="flex w-1/2 flex-col gap-5 max-md:w-[85%]">
          <SelectPicker
            options={education_options}
            label={constants.EDUCATION}
            value={education!}
            error={errors.includes("education")}
            setValue={(v) => {
              validate("education");
              setUser({ ...user, education: v as string });
            }}
          />

          <SelectPicker
            options={prayers_options}
            label={constants.PRAYERS}
            value={prayers!}
            error={errors.includes("prayers")}
            setValue={(v) => {
              validate("prayers");
              setUser({ ...user, prayers: v as string });
            }}
          />

          <SelectPicker
            options={weightList.map((e) => e.label)}
            label={constants.WEIGHT}
            value={(weight as { label: string })?.label || (weight as null)}
            error={errors.includes("weight")}
            setValue={(v) => {
              validate("weight");
              setUser({
                ...user,
                weight: weightList.find((e) => e.label === v),
              });
            }}
          />
        </div>

        <div className="divider divider-horizontal mx-10 mt-5 max-md:hidden"></div>

        <div className="flex w-1/2 flex-col gap-5 max-md:mt-5 max-md:w-[85%]">
          <SelectPicker
            options={heightList.map((e) => e.label)}
            label={constants.HEIGHT}
            value={(height as { label: string })?.label || (height as null)}
            error={errors.includes("height")}
            setValue={(v) => {
              validate("height");
              setUser({
                ...user,
                height: heightList.find((e) => e.label === v),
              });
            }}
          />

          <SelectPicker
            options={beauty_options}
            label={constants.BEAUTY}
            value={beauty!}
            error={errors.includes("beauty")}
            setValue={(v) => {
              validate("beauty");
              setUser({ ...user, beauty: v as string });
            }}
          />

          <SelectPicker
            options={skin_color_options}
            label={constants.SKIN_COLOR}
            value={skin_color!}
            error={errors.includes("skin_color")}
            setValue={(v) => {
              validate("skin_color");
              setUser({ ...user, skin_color: v as string });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StepThree;
