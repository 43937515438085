export default {
  CONNECT: "connect",
  LOGIN: "login",
  USER_STATUS: "user status",
  CHANGE_STATUS: "change status",
  ONLINE: "online users",
  OFFLINE: "offline users",
  MESSAGE_REMOVE: "message removed",
  MESSAGE_UPDATED: "message updated",
  GOT_MESSAGE: "new message",
  MESSAGE_READ: "message read",
  ADD_STORY: "add to story",
  YOUNG_ENGAGE: "young-engage",
  GUARDIAN_ENGAGE: "guardian-engage",
  SEND_ENGAGE: "send-engage",
  ACCEPT_ENGAGE: "accept-engage",
  CANCEL_ENGAGE: "cancel-engage",
  REMOVE_ENGAGE: "remove-engage",
  CHAT: "chat",

  EMAIL_VERIFIED: "email verified",

  NEW_SUPPORT_MESSAGE: "new support message",
  MAKE_SUPPORT_AS_READ: "read support message",
  DELETE_SUPPORT: "conversation deleted",

  UPDATE_ACCEPT: "update-accept",
  ACCOUNT_UPDATE: "account updated",
  ACCOUNT_ACCEPT: "account accepted",
  ACCOUNT_CANCEL: "account canceled",
  REMOVE_ACCOUNT: "remove account",
  RESET_ACCOUNT: "reset account",
  SUBSCRIPTION: "subscription updated",
  BLOCK_USERS: "blocked users",
  LIKE: "like",
  VISIT: "visit",
  ADS: "ads",
  UPDATE_CANCEL: "update-cancel",
  MAIL: "mail",
  MAKE_AS_READ: "make as read",
  SEND_MESSAGE: "chat message",
  REPORT_USER: "report message",
  HIDE_MESSAGE: "invisible message",
};
