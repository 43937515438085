import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import support from "api/support";
import { UserInfo } from "api/users";
import dayjs from "dayjs";
import { chunk } from "lodash";
import ms from "ms";
import { alafaf } from "pages/support";
import bsonId from "utils/bsonId";
import { Message } from "yup";
import queryClient from "./queryClient";

const LIMIT_THREAD_LIST = 20;

interface Got {
  id: string;
  message: string;
  user?: UserInfo;
}

const isQueryExist = (id: string) =>
  !!queryClient.getQueryData<InfiniteData<Array<Message>>>([
    "support-thread",
    id,
  ])?.pages.length;

export const refreshSupportThreadQuery = (id: string) => {
  queryClient.invalidateQueries({ queryKey: ["support-thread", id] });
};

const useSupportThread = (chatId = "") => {
  const query = useInfiniteQuery({
    queryKey: ["support-thread", chatId],
    queryFn: ({ pageParam = 1 }) =>
      support.thread(chatId, pageParam).then((res) => res.data!),
    getNextPageParam: (lastPage, allPage) => {
      return lastPage.length === LIMIT_THREAD_LIST
        ? allPage.length + 1
        : undefined;
    },
    select: (data) => data.pages.flatMap((x) => x),
    initialPageParam: 1,
    staleTime: ms("2h"),
  });

  return {
    ...query,
    thread: query.data!,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export const addSupportThreadMessage = ({ id, message, user }: Got) => {
  if (!isQueryExist(id)) return;
  const thread = queryClient.getQueryData<InfiniteData<Array<Message>>>([
    "support-thread",
    id,
  ]);

  queryClient.setQueryData<InfiniteData<Array<Message>>>(
    ["support-thread", id],
    () => {
      const result = [
        {
          _id: bsonId(),
          text: message,
          createdAt: dayjs().toString(),
          sent: false,
          type: "text",
          received: true,
          reported: false,
          visible: true,
          unsent: false,
          user: user || alafaf,
        },
        ...thread!.pages.flat(),
      ];

      return { ...thread!, pages: chunk(result, LIMIT_THREAD_LIST) };
    },
  );
};

export default useSupportThread;
