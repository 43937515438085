import constants from "configs/constants";
import dayjs from "dayjs";
import "dayjs/locale/ar";
import React, { useState } from "react";
import { BsCheckAll } from "react-icons/bs";
import { FiMoreHorizontal } from "react-icons/fi";
import { MdDelete, MdDeleteForever, MdOutlineReport } from "react-icons/md";
import { RxCheck } from "react-icons/rx";
import { Link } from "react-router-dom";
import Message from "types/Message";
import { amPm_12hTime } from "utils/time";
import Avatar from "../Avatar";
import classNames from "classnames";

interface User {
  _id: string;
  profile_picture: string;
  name: string;
  uid: string;
}

interface BubbleProps {
  message: Message;
  sender: User;
  receiver: User;
  pathToProfile: string;
  showMessageStatus?: boolean;
  showBubbleActions?: boolean;
  color?: "primary" | "secondary";
  previous: { index: number; time: string; id: string };
  next: { index: number; time: string; id: string };
  hideRemovedMessage: (messageId: string) => void;
  removeMessageFromAll: (messageId: string) => void;
  removeMessageFromMe: (messageId: string) => void;
  removeTargetMessage: (messageId: string) => void;
  reportMessage: (messageId: string) => void;
  defaultPicture?: string;
}

const Bubble: React.FC<BubbleProps> = ({
  message,
  sender,
  receiver,
  pathToProfile,
  previous,
  next,
  color = "primary",
  showMessageStatus = true,
  showBubbleActions = true,
  hideRemovedMessage,
  removeMessageFromMe,
  removeTargetMessage,
  removeMessageFromAll,
  reportMessage,
  defaultPicture,
}) => {
  const [showActions, setShowActions] = useState(false);

  const isPrimary = color === "primary";
  const bubbleColor = isPrimary
    ? "chat-bubble-primary bg-gradient-to-r from-primary to-primary-focus"
    : "chat-bubble-accent";

  const firstBubble = previous.index === -1;
  const lastBubble = next.index === 0;
  const isLargeMessage = message.text.length > 25;

  const isMe = message.user._id === sender._id;
  const isRead = message.sent && message.received;
  const pDate = dayjs(previous.time).format("YYYY-MM-DD");
  const cDate = dayjs(message.createdAt).format("YYYY-MM-DD");
  const nDate = dayjs(next.time).format("YYYY-MM-DD");
  const isNewDay =
    dayjs(pDate).diff(cDate, "days") === 0 &&
    dayjs(cDate).diff(nDate, "days") > 0;

  const showAvatar =
    dayjs(message.createdAt).date() === dayjs(next.time).date() &&
    (dayjs(message.createdAt).date() !== dayjs(previous.time).date() ||
      previous.id === sender._id);

  function showAction() {
    setShowActions(!showActions);
  }

  const options = !message.visible
    ? [{ title: "حذف", action: hideRemovedMessage, Icon: MdDelete }]
    : isMe
    ? [
        {
          title: "حذف عن الجميع",
          action: removeMessageFromAll,
          Icon: MdDeleteForever,
        },
        { title: "حذف عنك", action: removeMessageFromMe, Icon: MdDelete },
      ]
    : [
        { title: "ابلاغ", action: reportMessage, Icon: MdOutlineReport },
        { title: "حذف", action: removeTargetMessage, Icon: MdDelete },
      ];

  return (
    <>
      <div
        key={message._id}
        className={classNames({
          "chat flex items-center px-3": true,
          "chat-end justify-end": !isMe,
          "chat-start": isMe,
        })}
      >
        <div
          className={classNames({
            dropdown: true,
            "dropdown-right": !isMe && isLargeMessage,
            "dropdown-left": isMe && isLargeMessage,
            "order-2": isMe,
          })}
        >
          {showBubbleActions && (
            <div
              className={`cursor-pointer ${isMe ? "ml-10" : "mr-10"}`}
              tabIndex={0}
              onClick={() => setShowActions(true)}
            >
              <FiMoreHorizontal className="h-5 w-5" />
            </div>
          )}

          {showActions && (
            <div className="dropdown-content rounded-box z-[1] flex w-40 justify-around gap-y-1 border-[1px] border-base-300 bg-base-100 p-2 shadow">
              {options.map(({ title, Icon, action }) => {
                return (
                  <button
                    key={title}
                    onClick={() => {
                      showAction();
                      action(message._id);
                    }}
                  >
                    <span
                      className={`flex flex-col items-center text-[10px] font-bold ${
                        title.includes(constants.REMOVE)
                          ? "text-red-500"
                          : "text-warning"
                      }`}
                    >
                      <Icon className="text-lg" />
                      {title}
                    </span>
                  </button>
                );
              })}
            </div>
          )}
        </div>

        <>
          <div
            className={classNames({
              "chat-bubble max-w-lg break-words px-4 py-1 text-[15px] font-medium leading-6 [word-spacing:0.16rem]":
                true,
              "text-neutral": !isMe,
              "bg-gray-500": !message.visible,
              "bg-white": !isMe && message.visible,
              [`${bubbleColor} text-[#F5F5F5]`]: isMe && message.visible,
            })}
          >
            {message.text}

            <div className="chat-footer mt-2 flex items-end justify-between text-[10px] font-bold">
              <span className="mt-2 pl-2 font-extrabold">
                {!isMe && showBubbleActions && "~ " + receiver.name}
              </span>
              <div className="flex">
                {showMessageStatus && (
                  <>
                    {isMe && (
                      <div>
                        {!isRead ? (
                          <span className="font-extrabold">
                            <RxCheck className="h-4 w-8" />
                          </span>
                        ) : (
                          <span className="font-extrabold text-success">
                            <BsCheckAll className="h-[18px] w-7" />
                          </span>
                        )}
                      </div>
                    )}
                  </>
                )}
                <span>{amPm_12hTime(message.createdAt)}</span>
              </div>
            </div>
          </div>

          {!isMe && (
            <div className="avatar chat-image w-10 rounded-full">
              {(firstBubble || showAvatar) && (
                <Link to={pathToProfile}>
                  <Avatar
                    size="sm"
                    src={message.user.profile_picture || defaultPicture}
                  />
                </Link>
              )}
            </div>
          )}
        </>
      </div>

      {(isNewDay || lastBubble) && (
        <span className="text my-4 text-center text-xs font-bold">
          {dayjs(message.createdAt).format("DD MMM YYYY")}
        </span>
      )}
    </>
  );
};

export default Bubble;
