import NavIcons from "components/NavIcons";

const BottomBar = () => {
  return (
    <div className="btm-nav z-30 border-t-[0.1rem] border-gray-300">
      <NavIcons className="justify-evenly" tooltipPosition="tooltip-top" />
    </div>
  );
};

export default BottomBar;
