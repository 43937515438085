interface Props {
  visible: boolean;
  bg?: string;
}

const Loading = ({ visible, bg }: Props) => {
  if (!visible) return null;

  return (
    <div
      className={`absolute z-[99999] flex h-[100vh] w-full items-center justify-center opacity-60 ${
        bg ? bg : "bg-gray-100"
      }`}
    >
      <span className="loading loading-spinner mx-auto w-[8rem] bg-primary"></span>
    </div>
  );
};

export default Loading;
