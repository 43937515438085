import { create } from "zustand";

const LIGHT = "winter";

interface States {
  showSidebar: boolean;
  setShowSidebar: (v: boolean) => void;
  theme: string;
  setTheme: (v: string) => void;
}

const useUtils = create<States>()((set) => ({
  showSidebar: false,
  setShowSidebar: (showSidebar: boolean) => set({ showSidebar }),

  theme: localStorage.getItem("theme") || LIGHT,
  setTheme: (theme: string) => set({ theme }),
}));

export default useUtils;
