import TGuardian from "types/TGuardian";
import { MARRIED_MALE_NOTE, MARRIED_NOTE } from "../strings";

interface Props {
  guardian: TGuardian;
  isMale: boolean;
}

const HEADING = [
  "الاسم: ",
  "الاسم ولي الامر: ",
  "جوال ولي الامر: ",
  "اقتراح الفتاة حول كيفية التعرف بها: ",
];

const MarriedNotes = ({ guardian, isMale }: Props) => {
  return (
    <div className="font-bold leading-7">
      {!isMale ? (
        <span>{MARRIED_NOTE}</span>
      ) : (
        <>
          {MARRIED_MALE_NOTE}
          <br />
          <br />
          <div className="text-primary underline">
            البيانات كما ارسلتها الفتاة
          </div>
          {Object.keys(guardian).map((key, index) => {
            return (
              <div key={index.toString()} className="">
                {HEADING[index]}
                <span className="text-primary">
                  {guardian[key as keyof TGuardian]}
                </span>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default MarriedNotes;
