import Badge from "components/core/Badge";
import sidebarSections from "data/sidebar";
import useStorage from "hooks/useStorage";
import useBadges from "queries/badges";
import useBlocks from "queries/blocks";
import useFavorites from "queries/favorites";
import {
  BsInstagram,
  BsSnapchat,
  BsTiktok,
  BsTwitterX,
  BsWhatsapp,
} from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useUtils from "stores/utils";

const Sidebar = () => {
  const { options } = useStorage();
  const location = useLocation();
  const navigate = useNavigate();

  const { badges } = useBadges();
  const { fvBadge } = useFavorites();
  const { blBadge } = useBlocks();

  const setShowSidebar = useUtils((store) => store.setShowSidebar);
  const showSide = useUtils((store) => store.showSidebar);
  const side = showSide ? "drawer-open" : "";
  const pathname = location.pathname;
  const { facebook, instagram, snapchat, tiktok, x, whatsapp } = options.social;

  function navigateTo(path: string) {
    setShowSidebar(false);
    navigate(path);
  }
  return (
    <div
      className={`no-scrollbar z-20 overflow-auto border-gray-200 px-1 max-md:drawer md:h-[85vh] md:rounded-2xl md:border-[0.1rem] md:shadow-xl ${
        !side && "max-md:hidden"
      }`}
    >
      <div className="flex flex-col-reverse md:flex-col">
        <div className={`drawer-start drawer ${side} md:drawer-open`}>
          <input id="app-drawer" type="checkbox" className="drawer-toggle" />
          <div className="drawer-side md:h-full">
            <ul className="text menu w-64 px-2 pb-48 pt-2 sm:w-52 md:pb-0 md:pt-2 lg:w-[17rem]">
              {sidebarSections.map(({ title, values }, index) => {
                return (
                  <div key={index.toString()}>
                    <div className="mx-1 font-semibold">{title}</div>
                    {values.map((item, index) => {
                      const selectedStyle =
                        pathname.includes(item.path) &&
                        "bg-gray-100 text-primary link-primary";

                      return (
                        <li
                          key={index.toString()}
                          className={`my-[2px] w-full flex-row rounded-xl ${selectedStyle}`}
                        >
                          <span
                            className="w-full hover:text-primary"
                            onClick={() => navigateTo(item.path)}
                          >
                            <item.Icon
                              className={`ml-2 h-5 w-5 items-center justify-center bg-transparent  ${selectedStyle}`}
                            />
                            {item.name}
                            {item.badge && (
                              <Badge
                                value={
                                  item.path === "favorites"
                                    ? fvBadge
                                    : item.path === "blocks"
                                    ? blBadge
                                    : (badges || {})[
                                        item.path as keyof typeof badges
                                      ]
                                }
                              />
                            )}
                          </span>
                        </li>
                      );
                    })}
                  </div>
                );
              })}
            </ul>
          </div>
        </div>

        <div className="px-1 pb-1 pt-5">
          <div className="z-10 flex flex-wrap text-[12px] md:justify-center">
            <Link
              className="link-hover link px-1"
              target="_blank"
              to="/privacy"
            >
              السياسة
            </Link>
            <span className="mx-[2px]">·</span>
            <Link target="_blank" className="link-hover link px-1" to="/terms">
              الشروط
            </Link>
            <span className="mx-[2px]">·</span>
            <Link target="_blank" className="link-hover link px-1" to="/about">
              حول
            </Link>
            <span className="mx-1">·</span>
            خطوبة &copy; 2023
          </div>

          {/* social links */}
          <div className="z-10 mt-1 flex justify-center text-[12px]">
            <Link
              target="_blank"
              className="link-hover link px-2 text-base text-blue-600"
              to={"https://" + facebook}
            >
              <FaFacebook />
            </Link>
            <Link
              target="_blank"
              className="link-hover link px-2 text-base text-pink-600"
              to={"https://" + instagram}
            >
              <BsInstagram />
            </Link>
            <Link
              target="_blank"
              className="link-hover link px-2 text-base text-[black]"
              to={"https://" + x}
            >
              <BsTwitterX />
            </Link>
            <Link
              target="_blank"
              className="link-hover link px-2 text-base text-yellow-500"
              to={"https://" + snapchat}
            >
              <BsSnapchat />
            </Link>
            <Link
              target="_blank"
              className="link-hover link px-2 text-base text-black"
              to={"https://" + tiktok}
            >
              <BsTiktok />
            </Link>
            <Link
              target="_blank"
              className="link-hover link px-2 text-base text-green-500"
              to={"https://wa.me/" + whatsapp}
            >
              <BsWhatsapp />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
