import { yupResolver } from "@hookform/resolvers/yup";
import auth from "api/auth";
import Button from "components/core/Button";
import FormFiled from "components/forms/FormFiled";
import schema from "components/forms/schema";
import constants from "configs/constants";
import useForm from "hooks/useForm";
import useToast from "hooks/useToast";
import Auth from "layouts/Auth";
import { useState } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

interface Reset {
  email: string;
  password: string;
  confirmPassword: string;
}

const ResetPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const resetForm = useForm<Reset>({
    resolver: yupResolver(schema.reset),
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  const onReset = async ({ email, password }: Reset) => {
    try {
      setLoading(true);
      const res = await auth.resetPassword({ email, newPassword: password });
      if (res.ok) {
        toast.success(constants.SUCCESS.REQUEST);
        setTimeout(() => navigate("/login"), 500);
      } else {
        if (res.data?.message) setError(res.data.message);
        else setError(constants.ERRORS.UNEXPECTED_ERROR);
        return setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Auth>
      <div className="flex h-screen flex-col items-center justify-end">
        <div className="no-scrollbar flex h-[65%] w-[98%] flex-col gap-y-5 overflow-y-auto rounded-tl-2xl rounded-tr-2xl border-[1px] border-b-0 border-primary bg-base-100 px-5 pb-5 pt-10 md:h-[75%] md:w-[90%] md:pb-20">
          <p className="mb-5 text-center font-semibold">
            {constants.RESET_PASS}
          </p>
          <div>
            <FormFiled
              type="email"
              name="email"
              Icon={HiOutlineMail}
              control={resetForm.control}
              placeholder={constants.EMAIL}
              error={resetForm.errors.email}
              containerClassName=" border-primary"
            />
          </div>
          <div>
            <FormFiled
              type="password"
              name="password"
              control={resetForm.control}
              Icon={RiLockPasswordLine}
              error={resetForm.errors.password}
              placeholder={constants.PASSWORD}
              containerClassName="border-primary"
            />
          </div>

          <div>
            <FormFiled
              type="password"
              control={resetForm.control}
              name="confirmPassword"
              Icon={RiLockPasswordLine}
              error={resetForm.errors.confirmPassword}
              containerClassName="border-primary"
              placeholder={constants.CONFIRM_PASSWORD}
            />
          </div>
          {!!error && <p className="mr-3 text-xs text-error">{error}</p>}
          <Button
            outline
            loading={loading}
            className="mt-5 w-[60%]"
            color="btn-primary"
            onClick={resetForm.handleSubmit(onReset)}
          >
            {constants.SEND_REQUEST}
          </Button>
        </div>
      </div>
    </Auth>
  );
};

export default ResetPassword;
