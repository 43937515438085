import ThemeSwitcher from "components/theme/Switcher";
import useStorage from "hooks/useStorage";

const Contact = () => {
  const { contact } = useStorage();

  return (
    <div>
      <div className="flex justify-end p-2">
        <ThemeSwitcher />
      </div>
      {!!contact && (
        <div
          className="custom-list p-10 pt-0"
          dangerouslySetInnerHTML={{ __html: contact }}
        />
      )}
    </div>
  );
};

export default Contact;
