import { yupResolver } from "@hookform/resolvers/yup";
import { useRef, useState } from "react";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { RiLockPasswordLine, RiUser3Line } from "react-icons/ri";
import * as Yup from "yup";

import Button from "components/core/Button";
import CheckBox from "components/core/CheckBox";
import FormFiled from "components/forms/FormFiled";
import schema from "components/forms/schema";
import constants from "configs/constants";
import useForm from "hooks/useForm";
import { useNavigate } from "react-router-dom";

import { GuardianParams } from "api/types/register";
import Captcha, { CaptchaRef } from "components/Captcha";
import { FaRegAddressCard } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

interface Props {
  phone?: string;
  loading: boolean;
  error: string;
  approved: boolean;
  setApproved: (v: boolean) => void;
  isAdult: boolean;
  setIsAdult: (v: boolean) => void;
  onSubmit: (values: GuardianParams) => void;
}

type TValue = Yup.InferType<typeof schema.guardianRegister>;

const RegisterGuardianForm = ({
  phone,
  approved,
  isAdult,
  loading,
  error,
  setApproved,
  setIsAdult,
  onSubmit,
}: Props) => {
  const navigate = useNavigate();

  const captchaRef = useRef<CaptchaRef>(null);
  const [errorAdult, setErrorAdult] = useState(false);
  const { control, errors, handleSubmit } = useForm<TValue>({
    resolver: yupResolver(schema.guardianRegister),
    defaultValues: {
      name: "",
      email: "",
      phone: phone || "",
      national_number: "",
      birthday: undefined,
      password: "",
      confirmPassword: "",
    },
  });

  function onFirstCheckBoxClick() {
    if (!approved && isAdult && errorAdult) setErrorAdult(false);
    setApproved(!approved);
  }

  function onSecondCheckBoxClick() {
    if (approved && !isAdult && errorAdult) setErrorAdult(false);
    setIsAdult(!isAdult);
  }

  return (
    <>
      <div>
        <FormFiled
          type="text"
          name="name"
          alphabetic
          maxLength={20}
          Icon={RiUser3Line}
          control={control}
          error={errors.name}
          placeholder={constants.NAME}
          containerClassName="border-primary"
        />
        {!errors.name && (
          <span className="mr-2 text-xs font-semibold text-yellow-500">
            {constants.NAME_IN_ARABIC_ONLY}
          </span>
        )}
      </div>

      <div>
        <FormFiled
          type="text"
          name="email"
          Icon={HiOutlineMail}
          control={control}
          error={errors.email}
          placeholder={constants.EMAIL}
          containerClassName="border-primary"
        />
      </div>

      <div>
        <FormFiled
          type="date"
          name="birthday"
          control={control}
          error={errors.birthday}
          placeholder={constants.BIRTHDAY}
          containerClassName="border-primary"
        />
      </div>

      <div>
        <FormFiled
          type="number"
          name="national_number"
          Icon={FaRegAddressCard}
          control={control}
          error={errors.national_number}
          placeholder={constants.NATIONAL_NUM}
          containerClassName="border-primary"
        />
      </div>

      <div>
        <FormFiled
          type="tel"
          name="phone"
          disabled={!!phone}
          control={control}
          Icon={HiOutlineDevicePhoneMobile}
          containerClassName="border-primary"
          error={errors.phone}
          placeholder={constants.PHONE}
        />
      </div>

      <div>
        <FormFiled
          type="password"
          name="password"
          control={control}
          Icon={RiLockPasswordLine}
          error={errors.password}
          placeholder={constants.PASSWORD}
          containerClassName="border-primary"
        />
      </div>

      <div>
        <FormFiled
          type="password"
          control={control}
          name="confirmPassword"
          Icon={RiLockPasswordLine}
          error={errors.confirmPassword}
          containerClassName="border-primary"
          placeholder={constants.CONFIRM_PASSWORD}
        />
      </div>

      {!!error && <p className="text-error">{error}</p>}

      <div className="flex flex-col gap-x-2">
        <CheckBox
          color="checkbox-primary"
          size="checkbox-sm"
          title={constants.SWEAR}
          checked={approved}
          error={errorAdult}
          toggle={onFirstCheckBoxClick}
        />
        <CheckBox
          color="checkbox-primary"
          size="checkbox-sm"
          title={constants.PLEDGE}
          checked={isAdult}
          error={errorAdult}
          toggle={onSecondCheckBoxClick}
        />
      </div>

      <Captcha ref={captchaRef} />

      <span
        onClick={() => navigate("/login")}
        className="link-hover link mx-auto text-xs hover:text-primary"
      >
        {constants.HAVE_ACCOUNT}
      </span>

      <Button
        outline
        loading={loading}
        className="w-[80%]"
        color="btn-primary"
        onClick={handleSubmit(onSubmit)}
      >
        {constants.CREATE_ACCOUNT}
      </Button>
      {!phone && (
        <Button
          outline
          loading={loading}
          className="w-[80%]"
          color="btn-primary"
          onClick={() => navigate("/register/token")}
        >
          {constants.REGISTER_BY_TOKEN}
        </Button>
      )}
    </>
  );
};

export default RegisterGuardianForm;
