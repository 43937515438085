import { useMutation, useQuery } from "@tanstack/react-query";
import blocks from "api/blocks";
import ms from "ms";
import User from "types/User";
import UserItem from "types/UserItem";
import refactor from "utils/refactor";
import queryClient from "./queryClient";
import useAuth from "hooks/useAuth";

export const refreshBlocksQuery = () =>
  queryClient.invalidateQueries({ queryKey: ["blocks"] });

const useBlocks = () => {
  const { isAuth, isGuardian } = useAuth();

  const query = useQuery({
    queryKey: ["blocks"],
    staleTime: ms("2h"),
    queryFn: () =>
      isAuth && !isGuardian
        ? blocks.get().then((res) => res.data?.reverse())
        : Promise.resolve<UserItem[]>([]),
  });

  const setBlocks = (update: UserItem[]) => {
    queryClient.setQueryData<UserItem[]>(["blocks"], () => update);
  };

  const { mutate: removeFromBlocks } = useMutation({
    mutationFn: blocks.remove,

    onMutate: (id: string) => {
      const previous = queryClient.getQueryData(["blocks"]) || [];
      queryClient.setQueryData<UserItem[]>(
        ["blocks"],
        (users) => users?.filter((user) => user._id !== id),
      );

      return { previous };
    },

    onError: (_, __, context) => {
      if (!context) return;
      queryClient.setQueryData<UserItem[]>(
        ["blocks"],
        context.previous as UserItem[],
      );
    },
  });

  const { mutate: addToBlocks } = useMutation({
    mutationFn: (user: User) => blocks.add(user.account._id),

    onMutate: (user: User) => {
      const previous = queryClient.getQueryData(["blocks"]) || [];

      queryClient.setQueryData<UserItem[]>(["blocks"], (users) => [
        refactor.userItem(user),
        ...(users || []),
      ]);

      return { previous };
    },

    onError: (_, __, context) => {
      if (!context) return;
      queryClient.setQueryData<UserItem[]>(
        ["blocks"],
        context.previous as UserItem[],
      );
    },
  });

  return {
    setBlocks,
    addToBlocks,
    removeFromBlocks,
    blocks: query.data! || [],
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
    blBadge: query.data?.length || 0,
  };
};

export default useBlocks;
