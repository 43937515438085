import apiConfig from "configs/api-v2";
import { PropsType } from "pages/search/props";
import UserItem from "types/UserItem";
import apiClient from "./client-v2";

const endpoints = apiConfig.endpoints;

interface Response {
  results: UserItem[];
  next?: number;
  previous?: number;
}

const getForGuest = (data: PropsType, page: number) =>
  apiClient.post<Response>(`${endpoints.search_landing}?page=${page}`, data);

const get = (data: PropsType, page: number) =>
  apiClient.post<Response>(`${endpoints.search}?page=${page}`, data);

const matched = () => apiClient.get<UserItem[]>(endpoints.matched_users);
const forMe = () => apiClient.get<UserItem[]>(endpoints.forMe_users);

const proposals = ({ all }: { all: boolean }) =>
  apiClient.get<UserItem[]>(
    `${endpoints.proposals_users}${all ? "?all=1" : ""}`,
  );

const newUsers = ({ all }: { all: boolean }) =>
  apiClient.get<UserItem[]>(`${endpoints.new_users}${all ? "?all=1" : ""}`);

const lastSeenUsers = ({ all }: { all: boolean }) =>
  apiClient.get<UserItem[]>(
    `${endpoints.last_seen_users}${all ? "?all=1" : ""}`,
  );

export default {
  get,
  getForGuest,
  matched,
  forMe,
  proposals,
  newUsers,
  lastSeenUsers,
};
