import SelectPicker from "components/picker/Select";
import constants from "configs/constants";
import useStorage from "hooks/useStorage";
import { Type as PrefType } from "../../preferencesProps";
import Props from "../../profileProps";

interface Props {
  prefs: PrefType;
  setPrefs: (v: PrefType) => void;
  errors: string[];
  setErrors: (v: string[]) => void;
}

const StepThree = ({ prefs, setPrefs, errors, setErrors }: Props) => {
  const { options } = useStorage();

  const shapeOptions = (array: Array<string>) => {
    return [constants.NOT_REQUIRE, ...array];
  };

  const ageList = () => Array.from({ length: 53 }, (_, i) => i + 18);

  const generateList = (start: number, end: number, label: string) => {
    const arr: { label: string; value: number }[] = [];
    for (let i = start; i <= end; i++)
      arr.push({ value: i, label: i + " " + label });
    return arr;
  };

  const validate = (propName: string) => {
    if (errors.includes(propName))
      setErrors(errors.filter((e) => e !== propName));
  };

  const OpGender = prefs.gender === "male" ? "female" : "male";
  const { ageFrom, ageTo, weightFrom, weightTo } = prefs;
  const { heightFrom, heightTo, beauty, skin_color } = prefs;
  const skin_color_options = options.skin_color_options[OpGender];
  const beauty_options = options.beauty_options[OpGender];
  const weightList = generateList(40, 150, "kg");
  const heightList = generateList(150, 250, "cm");

  return (
    <div className="px-4">
      <div className="mt-5 flex w-full max-md:mb-10 max-md:flex-col max-md:items-center">
        <div className="flex w-1/2 flex-col gap-5 max-md:w-[85%]">
          <SelectPicker
            multiple
            options={shapeOptions(beauty_options)}
            label={constants.BEAUTY}
            value={beauty!}
            error={errors.includes("beauty")}
            setValue={(v) => {
              validate("beauty");
              setPrefs({ ...prefs, beauty: v as string[] });
            }}
          />

          <SelectPicker
            multiple
            options={shapeOptions(skin_color_options)}
            label={constants.SKIN_COLOR}
            value={skin_color!}
            error={errors.includes("skin_color")}
            setValue={(v) => {
              validate("skin_color");
              setPrefs({ ...prefs, skin_color: v as string[] });
            }}
          />

          <div>
            <p className="text-lg font-bold">{constants.AGE}</p>
            <div className="flex w-full justify-between gap-x-10">
              <SelectPicker
                options={shapeOptions(ageList().map((e) => e.toString()))}
                label={constants.FROM}
                value={ageFrom!}
                error={errors.includes("ageFrom")}
                setValue={(v) => {
                  validate("ageFrom");
                  if (v === constants.NOT_REQUIRE) {
                    validate("ageTo");
                    setPrefs({ ...prefs, ageFrom: v, ageTo: v });
                  } else {
                    const isNotReq = prefs.ageTo === constants.NOT_REQUIRE;
                    setPrefs({
                      ...prefs,
                      ageTo: isNotReq ? (v as string) : prefs.ageTo,
                      ageFrom: v as string,
                    });
                  }
                }}
              />
              <SelectPicker
                options={shapeOptions(ageList().map((e) => e.toString()))}
                label={constants.TO}
                value={ageTo!}
                error={errors.includes("ageTo")}
                setValue={(v) => {
                  validate("ageTo");
                  if (v === constants.NOT_REQUIRE) {
                    validate("ageFrom");
                    setPrefs({ ...prefs, ageFrom: v, ageTo: v });
                  } else {
                    const isNotReq = prefs.ageFrom === constants.NOT_REQUIRE;
                    setPrefs({
                      ...prefs,
                      ageFrom: isNotReq ? (v as string) : prefs.ageFrom,
                      ageTo: v as string,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="divider divider-horizontal mx-10 mt-5 max-md:hidden"></div>

        <div className="flex w-1/2 flex-col gap-5 max-md:mt-5 max-md:w-[85%]">
          <div>
            <p className="text-lg font-bold">{constants.WEIGHT}</p>
            <div className="flex w-full justify-between gap-x-10">
              <SelectPicker
                options={shapeOptions(weightList.map((e) => e.label))}
                label={constants.FROM}
                value={weightFrom!}
                error={errors.includes("weightFrom")}
                setValue={(v) => {
                  validate("weightFrom");
                  if (v === constants.NOT_REQUIRE) {
                    validate("weightTo");
                    setPrefs({ ...prefs, weightFrom: v, weightTo: v });
                  } else {
                    const isNotReq = prefs.weightTo === constants.NOT_REQUIRE;
                    setPrefs({
                      ...prefs,
                      weightFrom: v as string,
                      weightTo: isNotReq ? (v as string) : prefs.weightTo,
                    });
                  }
                }}
              />
              <SelectPicker
                options={shapeOptions(weightList.map((e) => e.label))}
                label={constants.TO}
                value={weightTo!}
                error={errors.includes("weightTo")}
                setValue={(v) => {
                  validate("weightTo");
                  if (v === constants.NOT_REQUIRE) {
                    validate("weightFrom");
                    setPrefs({ ...prefs, weightFrom: v, weightTo: v });
                  } else {
                    const isNotReq = prefs.weightFrom === constants.NOT_REQUIRE;
                    setPrefs({
                      ...prefs,
                      weightTo: v as string,
                      weightFrom: isNotReq ? (v as string) : prefs.weightFrom,
                    });
                  }
                }}
              />
            </div>
          </div>

          <div>
            <p className="text-lg font-bold">{constants.HEIGHT}</p>
            <div className="flex w-full justify-between gap-x-10">
              <SelectPicker
                options={shapeOptions(heightList.map((e) => e.label))}
                label={constants.FROM}
                value={heightFrom!}
                error={errors.includes("heightFrom")}
                setValue={(v) => {
                  validate("heightFrom");
                  if (v === constants.NOT_REQUIRE) {
                    validate("heightTo");
                    setPrefs({ ...prefs, heightFrom: v, heightTo: v });
                  } else {
                    const isNotReq = prefs.heightTo === constants.NOT_REQUIRE;
                    setPrefs({
                      ...prefs,
                      heightFrom: v as string,
                      heightTo: isNotReq ? (v as string) : prefs.heightTo,
                    });
                  }
                }}
              />
              <SelectPicker
                options={shapeOptions(heightList.map((e) => e.label))}
                label={constants.TO}
                value={heightTo!}
                error={errors.includes("heightTo")}
                setValue={(v) => {
                  validate("heightTo");
                  if (v === constants.NOT_REQUIRE) {
                    validate("heightFrom");
                    setPrefs({ ...prefs, heightFrom: v, heightTo: v });
                  } else {
                    const isNotReq = prefs.heightFrom === constants.NOT_REQUIRE;
                    setPrefs({
                      ...prefs,
                      heightTo: v as string,
                      heightFrom: isNotReq ? (v as string) : prefs.heightFrom,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepThree;
