import useBadges from "./badges";
import useBlocks from "./blocks";
import useFavorites from "./favorites";
import useWallet from "./wallets";
import useUser from "./user";

const InitQueries = () => {
  useFavorites();
  useBlocks();
  useBadges();
  useWallet();
  return useUser();
};

export default InitQueries;
