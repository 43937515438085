import authApi from "api/auth";
import constants from "configs/constants";
import useToast from "hooks/useToast";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { IoIosCloseCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import hasKeys from "utils/hasKeys";
import Modal from "../../core/Modal";
import Counter from "./Counter";
import Otp from "./Otp";
import info from "configs/info";

interface Props {
  title?: string;
}

interface AuthState {
  phone: string;
  onSuccess: (t: string) => void;
}

export interface PhoneAuthRef {
  setState: (props: AuthState) => void;
}

const isSaudiPhone = (phone: string) => phone.includes(info.SA_CODE);

const PhoneAuth = forwardRef(({ title }: Props, ref) => {
  const [visible, setVisibility] = useState(false);
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const state = useRef<AuthState>();
  const counterRef = useRef<{ setShowTimer: (v: boolean) => void }>();
  const token = useRef("");
  const toast = useToast();

  async function sendOtp() {
    if (!hasKeys(state.current) || !state.current?.phone)
      return console.log("add phone number first!");

    setLoading(true);

    try {
      const service = isSaudiPhone(state.current.phone)
        ? authApi.sendOtp
        : authApi.sendTwilioOtp;

      const result = await service(state.current.phone);
      if (result.ok) token.current = result.data!;
      else {
        token.current = result.data!;
        toast.error(constants.TOAST.SEND_OTP);
        setVisibility(false);
      }
    } catch (error) {
      setError(false);
      console.error("Error during send otp", error);
    }

    counterRef.current?.setShowTimer(true);
    setLoading(false);
  }

  async function verifyOtp(code: string) {
    if (!hasKeys(state.current) || !state.current?.phone) {
      console.log("send otp first!");
      return setError(true);
    }

    setLoading(true);

    try {
      const service = isSaudiPhone(state.current.phone)
        ? async () => authApi.verifyOtp(code, token.current)
        : async () => authApi.verifyTwilioOtp(state.current!.phone, code);

      const result = await service();
      if (result.ok) {
        setError(false);
        setDone(true);

        setTimeout(() => {
          setDone(false);
          setVisibility(false);
          state.current?.onSuccess(result.data!.token);
        }, 2000);
      } else {
        if (result.data?.name === "EXPIRED")
          toast.error(constants.ERRORS.SESSION_EXPIRED);
        setError(true);
      }
    } catch (error) {
      setError(true);
    }

    setLoading(false);
  }

  useImperativeHandle(ref, () => ({
    setState: (props: AuthState) => {
      state.current = props;
      setVisibility(true);
      setTimeout(() => sendOtp(), 100);
    },
  }));

  return (
    <Modal
      className="max-w-[28rem]"
      visible={visible}
      setVisibility={setVisibility}
    >
      <div className="text-center font-bold md:text-xl">
        <span>{title || constants.VERIFICATION_CODE}</span>
        <span className="inline-block text-sm">
          {`ان كنت تواجه مشكلة في التحقق يمكنك تسجيل عن طريق `}
          <Link to="/register/token" className="link-hover link-primary link">
            معرف.
          </Link>
        </span>
      </div>

      <div className="flex h-32 items-start justify-center pt-10">
        <Otp
          visible={visible}
          error={error}
          setError={setError}
          onComplete={verifyOtp}
        />
      </div>

      {error && (
        <div className="mb-8 flex animate-jump-in items-center justify-center animate-once">
          <IoIosCloseCircle className="text-center text-8xl text-error" />
        </div>
      )}

      {done && (
        <div className="mb-8 flex animate-jump-in items-center justify-center animate-delay-500 animate-once">
          <BsFillCheckCircleFill className="text-center text-8xl text-success" />
        </div>
      )}

      {loading && (
        <div className="mb-8 flex animate-jump-in items-center justify-center animate-once">
          <span className="loading loading-spinner mx-auto w-[5rem] bg-primary"></span>
        </div>
      )}

      <Counter ref={counterRef} reSendOtpAgin={sendOtp} />
    </Modal>
  );
});

export default PhoneAuth;
