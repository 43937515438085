import constants from "configs/constants";
import useAlert from "hooks/useAlert";
import { ReactNode } from "react";
import { AiFillHeart, AiOutlineMessage } from "react-icons/ai";
import { BiBlock } from "react-icons/bi";
import { GiDiamondRing } from "react-icons/gi";
import { TbLockOpen } from "react-icons/tb";

interface TIcon {
  icon: ReactNode;
  title: string;
  className?: string;
  disable?: boolean;
  onClick?: () => void;
}

const Icon = ({ className, icon, title, disable, onClick }: TIcon) => {
  return (
    <div tabIndex={0} className="flex flex-col items-center text-xs">
      <div
        className={`btn btn-circle btn-sm z-10 p-1 text-lg ${className} ${
          disable && "btn-disabled"
        }`}
        onClick={onClick}
      >
        {icon}
      </div>
      <span>{title}</span>
    </div>
  );
};

interface Props {
  iconClassName?: string;
  checkUser: () => Promise<void>;
  contactLoading: boolean;
  isBlocked: boolean;
  isFavorite: boolean;
  isFemale: boolean;
  isMyProfile: boolean;
  makeAsFavorite: ({
    notify,
  }: {
    notify?: boolean | undefined;
  }) => Promise<void>;
  onContact: () => Promise<void>;
  sendEngage: () => Promise<void>;
  setShowReport: (v: boolean) => void;
  toggleBlock: () => Promise<void>;
}

const Actions = ({
  checkUser,
  contactLoading,
  isBlocked,
  isFavorite,
  isFemale,
  isMyProfile,
  makeAsFavorite,
  onContact,
  sendEngage,
  setShowReport,
  toggleBlock,
  iconClassName,
}: Props) => {
  const alert = useAlert();

  const report = [
    { label: constants.REPORT_VIOLATION, action: () => setShowReport(true) },
    { label: constants.BLOCK, action: checkUser },
  ];

  if (isBlocked)
    return (
      <div>
        <Icon
          className={iconClassName}
          disable={isMyProfile}
          title={constants.UNBLOCK}
          icon={<TbLockOpen />}
          onClick={toggleBlock}
        />
      </div>
    );

  return (
    <>
      <Icon
        className={iconClassName}
        disable={isMyProfile || contactLoading}
        onClick={onContact}
        icon={<AiOutlineMessage className="text-[dodgerblue]" />}
        title={constants.SEND_MESSAGE}
      />

      {(!isFemale || isMyProfile) && (
        <Icon
          className={iconClassName}
          disable={isMyProfile}
          icon={<GiDiamondRing className="text-accent" />}
          title={constants.ENGAGE_REQUEST}
          onClick={() =>
            alert({
              title: constants.BAR_ENGAGE,
              content: constants.ALERT.SEND_ENGAGE,
              onConfirm: sendEngage,
            })
          }
        />
      )}

      <Icon
        className={iconClassName}
        disable={isMyProfile}
        onClick={() => makeAsFavorite({ notify: true })}
        icon={
          <AiFillHeart
            className={isFavorite ? "text-red-400" : "text-red-200"}
          />
        }
        title={
          isFavorite
            ? constants.REMOVE_FROM_FAVORITE
            : constants.ADD_TO_FAVORITE
        }
      />

      <div className="dropdown dropdown-left z-20">
        <Icon
          className={iconClassName}
          disable={isMyProfile}
          icon={<BiBlock />}
          title={constants.BLOCK}
        />
        {!isMyProfile && (
          <ul
            tabIndex={0}
            className="menu dropdown-content rounded-box z-[1] w-52 border-2 border-base-200 bg-base-100 p-2 shadow"
          >
            {report.map((e) => {
              return (
                <li
                  key={e.label}
                  className="btn border-0 bg-transparent hover:bg-base-200"
                  onClick={e.action}
                >
                  {e.label}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
};

export default Actions;
