import { FC } from "react";
import {
  AiOutlineClose,
  AiOutlineLogout,
  AiOutlineMenu,
  AiOutlineMessage,
  AiOutlineSend,
} from "react-icons/ai";
import { GiCheckMark, GiDiamondRing } from "react-icons/gi";

interface Props {
  acceptAction?: () => void;
  cancelAction?: () => void;
  marriageAction?: () => void;
  getInfoAction?: () => void;
  deleteAction?: () => void;
  messageAction?: () => void;
  resendAction?: () => void;
  logoutAction?: () => void;
  cancelTitle?: string;
  marriedTitle?: string;
  isMale?: boolean;
}

const EngageAction = ({
  acceptAction,
  cancelAction,
  marriageAction,
  getInfoAction,
  deleteAction,
  messageAction,
  resendAction,
  logoutAction,
  cancelTitle = "رفض",
  marriedTitle = "تم الزواج",
  isMale,
}: Props) => {
  const messageBadge = 1;
  // const messageBadge = useStoreState((state) => state.messageBadge);

  const values: {
    title: string;
    color: string;
    badge: boolean;
    Icon: FC<{ className?: string }>;
    action?: () => void;
  }[] = [];

  if (messageAction)
    values.push({
      title: "ارسال رسالة",
      color: "text-primary",
      badge: !!messageBadge,
      action: messageAction,
      Icon: AiOutlineMessage,
    });

  if (marriageAction)
    values.push({
      title: marriedTitle,
      color: "text-accent",
      badge: false,
      action: marriageAction,
      Icon: GiDiamondRing,
    });

  if (getInfoAction)
    values.push({
      title: isMale ? "طلب البيانات" : "ارسال البيانات",
      color: "text-green-400",
      badge: false,
      action: getInfoAction,
      Icon: AiOutlineMenu,
    });

  if (acceptAction)
    values.push({
      title: "قبول",
      color: "text-success",
      badge: false,
      action: acceptAction,
      Icon: GiCheckMark,
    });

  if (cancelAction)
    values.push({
      title: cancelTitle,
      color: "text-red-500",
      badge: false,
      action: cancelAction,
      Icon: AiOutlineClose,
    });

  if (resendAction)
    values.push({
      title: "اعادة ارسال",
      color: "text-[dodgerblue]",
      badge: false,
      action: resendAction,
      Icon: AiOutlineSend,
    });

  if (deleteAction)
    values.push({
      title: "حذف",
      color: "text-red-400",
      badge: false,
      action: deleteAction,
      Icon: AiOutlineClose,
    });

  if (logoutAction)
    values.push({
      title: "خروج",
      color: "text-red-500",
      badge: false,
      action: logoutAction,
      Icon: AiOutlineLogout,
    });

  return (
    <div className="mt-8 flex justify-center gap-10">
      {values.map(({ title, badge, Icon, color, action }) => {
        return (
          <div key={title} className="flex flex-col items-center ">
            <button
              className={`${color} btn btn-circle indicator border-2 bg-base-100`}
              onClick={action}
            >
              <div>
                {badge && !!messageBadge && (
                  <div className="badge indicator-item badge-primary badge-xs mr-1 mt-2" />
                )}
                <Icon className="mx-[0.65rem] text-2xl" />
              </div>
            </button>
            <span className="text-center text-sm">{title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default EngageAction;
