import {
  BsInstagram,
  BsSnapchat,
  BsTiktok,
  BsTwitterX,
  BsWhatsapp,
} from "react-icons/bs";
import { Link } from "react-router-dom";

import appleStore from "assets/app_store.png";
import googlePlay from "assets/google_play.png";
import logo from "assets/logo.svg";
import info from "configs/info";
import useStorage from "hooks/useStorage";
import { FaFacebook } from "react-icons/fa";

interface Props {
  scrollToStatistics: () => void;
  scrollToStories: () => void;
}

const Footer = ({ scrollToStatistics, scrollToStories }: Props) => {
  const { options } = useStorage();
  const { facebook, instagram, snapchat, tiktok, x, whatsapp } = options.social;

  return (
    <footer className="mt-5 overflow-hidden md:mt-10">
      <div className="grid grid-cols-1 grid-rows-[2fr,repeat(1fr)] md:grid-cols-[40%,20%,20%,20%] md:grid-rows-1">
        <div className="flex w-full flex-col items-center justify-center border-0 border-l-2 border-gray-500 max-md:order-2 max-md:mt-5 md:w-[90%]">
          <img src={logo} alt="logo" className="h-16 w-16" />
          <p className="text-lg font-bold text-primary">
            خطوبة للزواج الاسلامي
          </p>

          {/* store icons */}
          <div className="mt-5 flex w-[70%] flex-col items-center gap-2 py-2 sm:w-[65%] sm:flex-row sm:justify-evenly sm:py-5 sm:pr-5">
            <a target="_blank" href={info.GOOGLE_PLAY}>
              <img className="h-10 cursor-pointer sm:h-12" src={googlePlay} />
            </a>

            <a target="_blank" href={info.APP_STORE}>
              <img className="h-10 cursor-pointer sm:h-12" src={appleStore} />
            </a>
          </div>

          {/* social icons */}
          <div className="mb-3 mt-8 flex justify-center text-base">
            <Link
              target="_blank"
              className="link-hover link px-2 text-xl text-blue-600"
              to={"https://" + facebook}
            >
              <FaFacebook />
            </Link>
            <Link
              target="_blank"
              className="link-hover link px-2 text-xl text-pink-600"
              to={"https://" + instagram}
            >
              <BsInstagram />
            </Link>
            <Link
              target="_blank"
              className="link-hover link px-2 text-xl text-[black]"
              to={"https://" + x}
            >
              <BsTwitterX />
            </Link>
            <Link
              target="_blank"
              className="link-hover link px-2 text-xl text-yellow-500"
              to={"https://" + snapchat}
            >
              <BsSnapchat />
            </Link>
            <Link
              target="_blank"
              className="link-hover link px-2 text-xl text-black"
              to={"https://" + tiktok}
            >
              <BsTiktok />
            </Link>
            <Link
              target="_blank"
              className="link-hover link px-2 text-xl text-green-500"
              to={"https://wa.me/" + whatsapp}
            >
              <BsWhatsapp />
            </Link>
          </div>
        </div>

        <div className="flex h-fit w-full flex-col p-2 pr-5">
          <p className="mb-2 text-xl font-bold text-primary md:mt-10">الشركة</p>
          <ul className="pr-8">
            <a href="#">
              <li className="link-hover link w-fit text-lg hover:link-primary md:text-base">
                الرئيسية
              </li>
            </a>

            <Link to="/login">
              <li className="link-hover link w-fit text-lg hover:link-primary md:text-base">
                الاشتراك
              </li>
            </Link>

            <Link to="/about" target="_blank">
              <li className="link-hover link w-fit text-lg hover:link-primary md:text-base">
                طريقة العمل
              </li>
            </Link>
          </ul>
        </div>

        <div className="flex h-fit w-full flex-col p-2 pr-5 md:pr-0">
          <p className="mb-2 text-xl font-bold text-primary md:mt-10">
            الاعضاء
          </p>
          <ul className="pr-8">
            <Link to="/guest/search">
              <li className="link-hover link w-fit text-lg hover:link-primary md:text-base">
                البحث
              </li>
            </Link>
            <li
              onClick={scrollToStories}
              className="link-hover link w-fit text-lg hover:link-primary md:text-base"
            >
              القصص الناجحة
            </li>
            <li
              onClick={scrollToStatistics}
              className="link-hover link w-fit text-lg hover:link-primary md:text-base"
            >
              احصائيات
            </li>
          </ul>
        </div>

        <div className="flex h-fit w-full flex-col p-2 pr-5 md:pr-0">
          <p className="mb-2 text-xl font-bold text-primary md:mt-10">
            القانون
          </p>
          <ul className="pr-8">
            <Link to="/privacy" target="_blank">
              <li className="link-hover link w-fit text-lg hover:link-primary md:text-base">
                السياسة الخصوصية
              </li>
            </Link>
            <Link to="/terms" target="_blank">
              <li className="link-hover link w-fit text-lg hover:link-primary md:text-base">
                الشروط والاحكام
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
