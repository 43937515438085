import Avatar from "components/Avatar";
import Badge from "components/core/Badge";
import { CgClose } from "react-icons/cg";
import { IoChevronBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

interface Props {
  user: { uid?: string; name: string; profile_picture: string };
  seen: boolean;
  color?: "primary" | "secondary";
  close?: () => void;
  isCollapsed: boolean;
  setIsCollapse?: (v: boolean) => void;
  badge?: boolean;
  back?: boolean;
  className?: string;
  online?: boolean;
  path?: string;
}

const Header = ({
  user,
  seen,
  close,
  isCollapsed,
  color = "primary",
  back,
  setIsCollapse = () => {},
  badge,
  className,
  online,
  path,
}: Props) => {
  const isPrimary = color === "primary";
  const badgeColor = "badge-secondary";
  const bgColor = isPrimary
    ? "bg-gradient-to-r from-primary  to-primary-focus"
    : "bg-accent";
  const btnColor = isPrimary ? "btn-secondary" : "btn-accent";
  const navigate = useNavigate();

  if (!isCollapsed)
    return (
      <div className="indicator sticky">
        {!seen && (
          <span
            className={`${badgeColor} badge indicator-item mr-2 mt-1 px-[6px] text-xs  font-bold`}
          >
            1
          </span>
        )}
        <div className="indicator">
          {badge && (
            <Badge
              value="1"
              className="indicator-item badge-md indicator-middle bg-secondary"
            />
          )}
          <Avatar
            onClick={() => setIsCollapse(true)}
            src={user.profile_picture}
            size="md"
            bordered
          />
        </div>
      </div>
    );

  return (
    <div
      className={`${bgColor} collapse-title flex h-0 items-center border-b-2 border-base-100 p-0 px-2 pt-0 text-xl font-medium ${className}`}
    >
      <div className="flex w-full items-center gap-x-2 md:gap-x-4">
        {!!back && (
          <button className="text-2xl text-white" onClick={() => navigate(-1)}>
            <IoChevronBack className="rotate-180" />
          </button>
        )}

        <div className="flex flex-1 items-center gap-3 text-white">
          <button
            className="btn btn-circle z-50"
            onClick={() => navigate(path || `/users/profile/${user.uid}`)}
          >
            <Avatar
              src={user.profile_picture}
              size="sm"
              online={online}
              offline={online === false}
            />
          </button>
          <span>{user.name}</span>
        </div>
        {!!close && (
          <span
            onClick={close}
            className={`${btnColor} btn btn-circle z-10 border-0 bg-transparent text-base text-white`}
          >
            <CgClose />
          </span>
        )}
      </div>
    </div>
  );
};

export default Header;
