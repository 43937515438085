import ThemeSwitcher from "components/theme/Switcher";
import useStorage from "hooks/useStorage";

const Privacy = () => {
  const { privacy } = useStorage();

  return (
    <div>
      <div className="flex justify-end p-2">
        <ThemeSwitcher />
      </div>
      {!!privacy && (
        <div
          className="custom-list p-10 pt-0"
          dangerouslySetInnerHTML={{ __html: privacy }}
        />
      )}
    </div>
  );
};

export default Privacy;
