import TWallet from "types/TWallet";
import apiClient from "./client-v2";
import apiConfig from "configs/api-v2";

const endpoints = apiConfig.endpoints;

const get = () => apiClient.get<TWallet>(endpoints.wallet);

export default {
  get,
};
