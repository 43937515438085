import classNames from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import OTPInput from "react-otp-input";

interface Props {
  visible: boolean;
  error: boolean;
  setError: (v: boolean) => void;
  onComplete: (v: string) => void;
}

const Otp: FC<Props> = ({ visible, error, setError, onComplete }) => {
  const [value, setValue] = useState("");
  const otpInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setValue("");
    setError(false);
  }, [visible]);

  return (
    <OTPInput
      value={value}
      numInputs={6}
      shouldAutoFocus
      containerStyle={{ direction: "ltr" }}
      renderSeparator={<span className="mx-1">-</span>}
      onChange={(v) => {
        setValue(v);
        if (v.length === 6) {
          otpInputRef.current?.blur();
          onComplete(v);
        }
      }}
      renderInput={(props) => (
        <input
          {...props}
          type="number"
          className={classNames({
            [props.className || ""]: !!props.className,
            "!h-8 !w-8 rounded-lg border-[2px] border-base-300 bg-base-200 p-2 text-base-content hover:bg-base-300 sm:!h-10 sm:!w-10 md:!h-11 md:!w-11":
              true,
            "animate-jump border-error bg-error text-white animate-once hover:bg-error":
              error,
          })}
          ref={(ref) => {
            props.ref(ref);
            otpInputRef.current = ref;
          }}
          onFocus={(e) => {
            if (error) setError(false);
            props.onFocus(e);
          }}
        />
      )}
    />
  );
};

export default Otp;
