const TapPayIcon = () => {
  return (
    <svg
      width={50}
      height={50}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1679.5 696.5"
    >
      <defs></defs>
      <path
        className="cls-1"
        d="M790.2,41h-52.6v105.3h-.1v44.9h.1v206.4c0,20.1.7,36,2.1,47.7,1.4,11.7,4.8,23.3,10.2,34.7,11.9,23.6,32.2,38.9,60.7,45.8,28.6,6.9,60.5,6.7,95.8-.5v-46c-29.2,4.7-52.6,5.3-70,1.8s-30.2-12.7-38.4-27.7c-3.7-6.6-6-13.8-6.8-21.9s-1.1-19.7-.9-34.9v-205.3h116.1v-44.9s-76.8,1.2-116.1,1.2h-13.7c-3.7,0-10,.5-10-1,0-7,23.7-2,23.7-11V41h-.1Z"
      />
      <path
        className="cls-1"
        d="M1264.6,246.4c-1.6-13-4.7-24.7-9.1-35.3-10.1-24.3-27.1-43-51.2-56-24.1-13-53.6-19.5-88.4-19.5s-75.6,9.3-101.9,27.9c-26.3,18.6-43.9,45-52.8,79.1l51.3,15.1c6.8-24.8,19-43.1,36.7-54.9,17.7-11.8,39.6-17.7,65.8-17.7s44.6,3.9,59.5,11.8c14.9,7.8,25.5,19.8,31.9,35.8,5.7,14.2,8.6,31.8,9,53.1-4.8.7-9.8,1.3-14.7,1.9-39.5,5.2-70.8,9.4-93.7,12.8-22.9,3.4-44.2,7.5-63.9,12.5-30.2,7.9-53.8,20.9-70.7,39-17,18-25.4,41.9-25.4,71.6s4.9,39.4,14.7,56.5,24.5,30.6,44,40.7c19.6,10,43.1,15.1,70.7,15.1s47.8-3.7,68.2-11.2c20.5-7.5,38.2-18.6,53.2-33.5,8.5-8.4,15.9-17.9,22.4-28.5l8.4-15.2c11.6,2.7-8.4,17.2-8.4,49s0,28.9,0,28.9h47v-235.1c0-16.1-.8-30.7-2.5-43.7h0l-.1-.2ZM1213.8,362.4c-.7,10.5-2.1,20.4-4.2,29.5-3.5,19.2-10.8,36.2-21.8,51-11,14.9-25.5,26.4-43.5,34.7-18,8.3-38.6,12.5-61.8,12.5s-35-3.2-47.4-9.5c-12.4-6.3-21.5-14.5-27.2-24.6-5.7-10-8.6-20.9-8.6-32.6,0-18.7,6.1-33.4,18.2-44,12.2-10.7,27.7-18.6,46.7-24,18-4.9,38.4-9,61.2-12.3,22.8-3.3,51.2-6.9,85.1-10.9l4.2-.5c0,11.5-.4,21.8-1,30.7h.1Z"
      />
      <path
        className="cls-1"
        d="M1659.5,233.1c-13.4-30.3-32.8-54.1-57.9-71.4-25.2-17.3-54.7-26-88.6-26s-64.3,8.6-89,25.6c-36.3,25.7-36.5,46.2-43,46.2s6.7-16,6.7-28.5v-32.7h-47.4v550.2h53v-189.4c0-25.5-12.5-33-6.3-35,6.2-2,13.9,21.4,36.6,37.6,24.4,17.4,53.6,26.1,87.5,26.1s64.6-8.6,90-26c25.4-17.3,44.8-41.2,58.2-71.6,13.4-30.4,20.2-64.7,20.2-102.8s-6.7-72-20.2-102.3h.2ZM1610.2,413.3c-8.9,22.9-22.2,40.9-39.8,54-17.7,13.1-39.2,19.7-64.7,19.7s-47.4-6.4-65.1-19.3c-17.7-12.9-30.9-30.7-39.7-53.5-8.8-22.8-13.2-49.1-13.2-78.8s4.3-56.5,13-78.9c8.6-22.4,21.8-40.1,39.3-52.8s39.1-19.1,64.6-19.1,48.2,6.5,66,19.5c17.8,13,31.1,30.9,39.8,53.7,8.8,22.8,13.2,48.7,13.2,77.7s-4.5,55-13.3,77.9h0l-.1-.1Z"
      />
      <path
        className="cls-1"
        d="M286.8,41c135.5,0,245.8,110.3,245.8,245.8s-110.3,245.8-245.8,245.8S41,422.3,41,286.8,151.2,41,286.8,41ZM286.8,0C128.4,0,0,128.4,0,286.8s128.4,286.8,286.8,286.8,286.8-128.4,286.8-286.8S445.1,0,286.8,0Z"
      />
      <path
        className="cls-1"
        d="M286.8,122.9c90.4,0,163.9,73.5,163.9,163.9s-73.5,163.9-163.9,163.9-163.9-73.5-163.9-163.9,73.5-163.9,163.9-163.9ZM286.8,81.9c-113.1,0-204.8,91.7-204.8,204.8s91.7,204.8,204.8,204.8,204.8-91.7,204.8-204.8-91.7-204.8-204.8-204.8Z"
      />
    </svg>
  );
};

export default TapPayIcon;
