import { useMutation, useQuery } from "@tanstack/react-query";
import engagements from "api/engage";
import { UpdateUser } from "api/types/users";
import { UserInfo } from "api/users";
import ms from "ms";
import Badges from "types/Badges";
import TEngagement from "types/TEngagement";
import TGuardian from "types/TGuardian";
import RealInfo from "types/TRealInfo";
import queryClient from "./queryClient";

interface Update {
  status?: "accepted" | "pending" | "canceled" | "removed";
  guardian?: {
    girl_name: string;
    guardian_name: string;
    guardian_phone_number: string;
    suggestion_how_to_find: string;
  };
  young_info?: {
    name: string;
    national_number: string;
    birthday: string;
    phone: string;
  };
  pass_girl_info?: boolean;
  removed?: {
    reason: string;
    from: string;
    time: string;
  };
  swear?: boolean;
}

interface UpdateProps {
  engageId: string;
  update: Update;
  updateUser?: UpdateUser;
}

export const refreshEngageQuery = () =>
  queryClient.invalidateQueries({ queryKey: ["engage"] });

const useEngage = () => {
  const query = useQuery({
    queryKey: ["engage"],
    queryFn: () => engagements.get().then((res) => res.data?.reverse()),
    staleTime: ms("2h"),
  });

  const refreshBadge = () => {
    const user = queryClient.getQueryData<UserInfo>(["user"])!;
    const engagements = queryClient.getQueryData<TEngagement[]>(["engage"])!;

    const engage =
      user && user.gender === "female"
        ? engagements.filter((e) => e.status === "pending").length
        : 0;

    const badges = queryClient.getQueryData<Badges>(["badges"])!;
    queryClient.setQueryData<Badges>(["badges"], () => ({ ...badges, engage }));
  };

  const add = (engage: TEngagement) => {
    queryClient.setQueryData<TEngagement[]>(["engage"], (engagements) => [
      engage,
      ...(engagements || []),
    ]);
    refreshBadge();
  };

  const send = () => {
    queryClient.setQueryData<UserInfo>(["user"], (user) => ({
      ...user!,
      status: "pending-engage",
    }));

    queryClient.invalidateQueries({ queryKey: ["engage"] });
  };

  const update = (props: UpdateProps) => {
    queryClient.setQueryData<TEngagement[]>(
      ["engage"],
      (engage) =>
        engage?.map((e) => {
          if (e.user._id === props.engageId) return { ...e, ...props.update };
          else if (props.update.status === "accepted")
            return { ...e, status: "canceled" };

          return e;
        }),
    );

    if (props.updateUser) {
      queryClient.setQueryData<UserInfo>(["user"], (user) => ({
        ...user!,
        ...(props.updateUser as UserInfo),
      }));
    }

    refreshBadge();
  };

  const remove = (id: string) => {
    queryClient.setQueryData<TEngagement[]>(
      ["engage"],
      (engage) => engage?.filter((e) => e.user._id !== id),
    );

    refreshBadge();
  };

  const { mutate: swear } = useMutation({
    mutationFn: engagements.swear,

    onMutate: (id: string) => {
      const previous =
        queryClient.getQueryData<TEngagement[]>(["engage"]) || [];

      queryClient.setQueryData<TEngagement[]>(
        ["engage"],
        (engage) =>
          engage?.map((e) => (e.user._id === id ? { ...e, swear: true } : e)),
      );
      return { previous };
    },

    onError: (_, __, context) => {
      if (!context) return;
      queryClient.setQueryData<TEngagement[]>(
        ["engage"],
        context.previous as TEngagement[],
      );
    },
  });

  const { mutate: setGuardian } = useMutation({
    mutationFn: engagements.setGuardianInfo,

    onMutate: ({ id, ...guardian }: TGuardian & { id: string }) => {
      const previous =
        queryClient.getQueryData<TEngagement[]>(["engage"]) || [];

      queryClient.setQueryData<TEngagement[]>(
        ["engage"],
        (engage) =>
          engage?.map((e) => (e.user._id === id ? { ...e, guardian } : e)),
      );
      return { previous };
    },

    onError: (_, __, context) => {
      if (!context) return;
      queryClient.setQueryData<TEngagement[]>(
        ["engage"],
        context.previous as TEngagement[],
      );
    },
  });

  const { mutate: setInfo } = useMutation({
    mutationFn: engagements.setGuardianInfo,

    onMutate: ({ id, ...young_info }: RealInfo & { id: string }) => {
      const previous =
        queryClient.getQueryData<TEngagement[]>(["engage"]) || [];

      queryClient.setQueryData<TEngagement[]>(
        ["engage"],
        (engage) =>
          engage?.map((e) => (e.user._id === id ? { ...e, young_info } : e)),
      );
      return { previous };
    },

    onError: (_, __, context) => {
      if (!context) return;
      queryClient.setQueryData<TEngagement[]>(
        ["engage"],
        context.previous as TEngagement[],
      );
    },
  });

  return {
    add,
    send,
    update,
    remove,
    swear,
    setGuardian,
    setInfo,
    data: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export default useEngage;
