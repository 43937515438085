import StepProgressBar from "components/StepProgressBar";
import constants from "configs/constants";
import { useState } from "react";

import props from "pages/users/utils/profileProps";

import { injectToken } from "api/client-v2";
import users from "api/users";
import Loading from "components/theme/Loading";
import TProps from "pages/users/utils/TProps";
import StepFour from "pages/users/utils/steps/profile/Four";
import StepOne from "pages/users/utils/steps/profile/One";
import StepThree from "pages/users/utils/steps/profile/Three";
import StepTow from "pages/users/utils/steps/profile/Tow";
import { Navigate, useLoaderData, useNavigate } from "react-router-dom";
import TProfile from "types/TProfile";
import expiredToken from "utils/expiredToken";
import refreshPage from "utils/refreshPage";
import storage from "utils/storage";
import { AiOutlinePoweroff } from "react-icons/ai";
import profiles from "api/profiles";
import hasKeys from "utils/hasKeys";

interface Build {
  gender?: string;
  token?: string;
  is_guardian?: boolean;
  user?: TProps;
  route?: string;
  activeStep?: number;
}

interface Value {
  label: string;
  value: number;
}

function BuildProfile() {
  const navigate = useNavigate();
  const state = useLoaderData() as Build;
  const isPropsPassed = state && Object.keys(state).length;

  const gender = state?.gender;
  const token = state?.token;
  const is_guardian = state?.is_guardian;
  const user_ = state?.user;

  const [user, setUser] = useState(user_ || { ...props.user, gender });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [currentStep, setCurrentStep] = useState(state?.activeStep || 0);

  const onSubmit = async () => {
    setLoading(true);
    setUploadError("");

    const { age, profile_picture } = user;
    const data = { ...user };
    delete data.age;
    delete data.profile_picture;

    data.weight = (data.weight as Value)!.value;
    data.height = (data.height as Value)!.value;
    data.children = (data.children as Value)!.value;

    let props = {};
    injectToken(token!);

    const uUpdate: any = {};
    if (age) uUpdate.age = +age;
    if (profile_picture) uUpdate.profile_picture = profile_picture;

    if (hasKeys(uUpdate)) {
      const userInfo = await users.update(uUpdate);
      if (userInfo.ok) props = { gender, is_guardian, token };
      else if (!userInfo.ok) {
        setUploadError(constants.ERRORS.INFO_UPLOAD);
        return setLoading(false);
      }
    }

    const profile = await profiles.create(data as TProfile);
    if (profile.ok) {
      setLoading(false);
      storage.store(
        "build",
        JSON.stringify({
          route: "/build/preferences",
          activeStep: 0,
          ...props,
        }),
      );
      navigate("/build/preferences");
    } else {
      setUploadError(constants.ERRORS.INFO_UPLOAD + ".");
      return setLoading(false);
    }
  };

  const nextStep = async (step: number) => {
    const thereIsError = props
      .step(step)
      .filter((e) => !user[e as keyof typeof user]);

    if (step === 4 && !thereIsError.length) onSubmit();
    else if (!thereIsError.length) {
      setCurrentStep(step);
      persistData(step);
    } else setErrorMessages(thereIsError);
  };

  async function persistData(step: number) {
    try {
      const isExpiry = expiredToken(token || "");
      if (isExpiry) {
        storage.remove("build");
        refreshPage("/login");
      } else {
        storage.store(
          "build",
          JSON.stringify({
            user,
            gender,
            is_guardian,
            token,
            activeStep: step,
            route: "/build/profile",
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  function logout() {
    storage.remove("build");
    refreshPage("/");
  }

  if (!isPropsPassed) return <Navigate to="/login" />;
  if (state.route !== "/build/profile")
    return <Navigate to="/build/preferences" />;

  return (
    <div className="h-[100%] overflow-hidden pl-[2px]">
      <div
        onClick={logout}
        className="absolute left-4 top-4 z-10 flex cursor-pointer flex-col items-center font-semibold text-primary"
      >
        <AiOutlinePoweroff className="text-xl" />
        <p className="text-xs">{constants.OUT}</p>
      </div>
      <Loading visible={loading} />
      <StepProgressBar
        title={constants.YOUR_PREFERENCES}
        next={() => nextStep(currentStep + 1)}
        previous={() => setCurrentStep((s) => s - 1)}
        className="h-[100%] pb-16"
        containerClassName="!h-[100vh]"
        currentStep={currentStep}
        steps={[
          {
            label: constants.BIRTH_AFFILIATION,
            content: (
              <StepOne
                user={user}
                setUser={setUser}
                errors={errorMessages}
                setErrors={setErrorMessages}
              />
            ),
          },
          {
            label: constants.STATUS,
            content: (
              <StepTow
                user={user}
                setUser={setUser}
                errors={errorMessages}
                setErrors={setErrorMessages}
              />
            ),
          },
          {
            label: constants.SEMBLANCE,
            content: (
              <StepThree
                user={user}
                setUser={setUser}
                errors={errorMessages}
                setErrors={setErrorMessages}
              />
            ),
          },
          {
            label: constants.BIO,
            content: (
              <StepFour
                user={user}
                setUser={setUser}
                errors={errorMessages}
                uploadError={uploadError}
                setErrors={setErrorMessages}
              />
            ),
          },
        ]}
      />
    </div>
  );
}

export default BuildProfile;
