import constants from "configs/constants";
import { FC } from "react";
import Note from "types/Note";

type Props = Partial<Note> & { isCash: boolean };

const Notes: FC<Props> = ({ isCash, before_payment, payment_accounts }) => {
  return (
    <>
      {!!before_payment?.html && (
        <div
          className="custom-list text-[14px] leading-6"
          dangerouslySetInnerHTML={{ __html: before_payment.html }}
        />
      )}

      {isCash && (
        <>
          <span className="label-text mt-2 text-lg font-bold leading-6">
            {constants.CASH_PAYMENT_INFO}
          </span>
          {!!payment_accounts?.html && (
            <div
              className="text-[14px] leading-6"
              dangerouslySetInnerHTML={{ __html: payment_accounts.html }}
            />
          )}
        </>
      )}
    </>
  );
};

export default Notes;
