import { useQuery } from "@tanstack/react-query";
import notes from "api/notes";
import ms from "ms";

const useNotes = () => {
  const query = useQuery({
    queryKey: ["notes"],
    queryFn: () => notes.get().then((res) => res.data),
    staleTime: ms("2h"),
  });

  return {
    before_payment: (query.data || {}).before_payment,
    payment_accounts: (query.data || {}).payment_accounts,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export default useNotes;
