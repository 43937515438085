interface Props {
  page: number;
  next: (page: number) => void;
  previous: (page: number) => void;
  hasNext: boolean;
  hasPrevious: boolean;
}

const Pagination = ({ page, next, previous, hasNext, hasPrevious }: Props) => {
  return (
    <div className="join">
      <button
        className={`btn join-item !bg-base-300 text-primary ${
          (page === 1 || !hasPrevious) && "btn-disabled"
        }`}
        onClick={() => previous(page - 1)}
      >
        «
      </button>
      <button className="btn join-item cursor-default !bg-base-300 !text-base-content">
        الصفحة {page}
      </button>
      <button
        className={`btn join-item !bg-base-300 text-primary ${
          !hasNext && "btn-disabled mr-[0.2px]"
        }`}
        onClick={() => next(page + 1)}
      >
        »
      </button>
    </div>
  );
};

export default Pagination;
