import AlertProvider from "components/core/Alert";
import SupportThread from "pages/support/Thread";
import { Outlet, useNavigate } from "react-router-dom";
import useThread from "stores/thread";
import Navbar from "../../Navbar";

const ROUTES = [
  { key: "first", title: "الاعضاء الموكل عنهم", path: "/guardian" },
  { key: "second", title: "الدعم الفني", path: "/guardian/support" },
];

const GuardianContainer = () => {
  const th = useThread((s) => s.thread);
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  return (
    <AlertProvider>
      <div className="h-screen overflow-hidden">
        {/* navbar */}
        <Navbar showIcons={false} />

        <div className="relative my-4 grid grid-cols-1 grid-rows-1 justify-center gap-2 md:grid-cols-[100%]">
          {/* content */}
          <div className="flex items-center justify-center">
            <div className="tabs tabs-boxed gap-x-5 !rounded-3xl border-[0.1rem] bg-white">
              {ROUTES.map(({ path, key, title }) => {
                return (
                  <div
                    key={key}
                    className={`tab !rounded-3xl text-xs hover:text-gray-500 md:text-sm ${
                      path === pathname &&
                      "tab-active font-semibold !text-white"
                    }`}
                    onClick={() => navigate(path)}
                  >
                    {title}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="no-scrollbar mx-auto h-screen w-screen self-center overflow-auto md:h-[80vh] md:w-[70%] lg:w-[60%]">
            {th.visible && th.fromSupport && <SupportThread />}

            <Outlet />
          </div>
        </div>
      </div>
    </AlertProvider>
  );
};

export default GuardianContainer;
