interface Props {
  children: React.ReactNode;
  text: string;
  position?:
    | "tooltip-bottom"
    | "tooltip-top"
    | "tooltip-left"
    | "tooltip-right";
}

export const Tooltip = ({
  text,
  children,
  position = "tooltip-bottom",
}: Props) => {
  return (
    <div className={`tooltip ${position}`} data-tip={text}>
      {children}
    </div>
  );
};
