import Avatar from "components/Avatar";
import TPreference from "types/TPreference";
import TProfile from "types/TProfile";
import TUser from "types/TUser";
import inPercent from "utils/inPercent";

interface Props {
  preview_me?: boolean;
  online: boolean;
  account: TUser;
  profile: TProfile;
  gender: "male" | "female";
  preferences?: TPreference;
}

const ProfileAvatar = ({
  gender,
  preview_me = false,
  online,
  account,
  profile,
  preferences,
}: Props) => {
  return (
    <>
      <div className="flex flex-col items-center justify-center">
        {/* profile picture */}
        <Avatar
          size="lg"
          bordered={account.is_guardian}
          online={!preview_me && online}
          offline={!preview_me && !online}
          src={account.profile_picture}
          badge={
            !preview_me && preferences
              ? inPercent(gender, preferences, {
                  ...profile,
                  age: account.age,
                })
              : undefined
          }
        />

        <div className="mx-4 flex flex-col items-center">
          {/* name */}
          <p className="mt-2 line-clamp-1 text-2xl font-bold text-primary max-lg:text-lg">
            {account.name}
          </p>
          <p className="line-clamp-1 text-lg font-bold text-accent max-lg:text-base">
            {`${profile.relationship_status} - ${account.age} سنة`}
          </p>
        </div>
      </div>
    </>
  );
};

export default ProfileAvatar;
