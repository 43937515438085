import apiConfig from "configs/api-v2";
import apiClient from "./client-v2";
import TNotification from "types/TNotification";

const endpoints = apiConfig.endpoints;

const get = () => apiClient.get<TNotification[]>(endpoints.notifications);

const makeAsRead = (id: string) =>
  apiClient.patch(`${endpoints.read_notification}/${id}`);

const remove = (id: string) =>
  apiClient.delete(`${endpoints.notifications}/${id}`);

const removeAll = () => apiClient.delete(endpoints.notifications);

export default {
  get,
  makeAsRead,
  remove,
  removeAll,
};
