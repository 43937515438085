import constants from "configs/constants";
import useToast from "hooks/useToast";
import { useState } from "react";
import { BiSend } from "react-icons/bi";
import { MdOutlineQuickreply } from "react-icons/md";

const WORD_LENGTH = 9;

type Target = EventTarget & {
  selectionStart?: number;
  selectionEnd?: number;
};

const regex = /^[0-9\u0660-\u0669\u0621-\u064A؟!.,،:"'() ]*$/;

interface Props {
  alphabeticNumber?: boolean;
  quicksMessages?: string[];
  color?: "primary" | "secondary";
  onSend: (text: string, fromQuick: boolean) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
  className?: string;
  inputClassName?: string;
}

const Input = ({
  onSend,
  quicksMessages = [],
  onFocus,
  onBlur,
  color,
  alphabeticNumber = false,
  className,
  inputClassName,
}: Props) => {
  const [text, onChangeText] = useState("");
  const [showQuickReplies, setShowQuickReplies] = useState(false);
  const toast = useToast();

  function handleOnSend(text: string, fromQuick: boolean) {
    if (showQuickReplies) showQuick();
    onChangeText("");
    onSend(text, fromQuick);
  }

  function showQuick() {
    setShowQuickReplies(!showQuickReplies);
  }

  return (
    <div
      className={`message-input fixed bottom-0 flex h-11 w-full items-center justify-between gap-2 bg-base-300 px-2 pb-1 md:w-96 ${className}`}
    >
      {!!quicksMessages.length && (
        <div className="dropdown dropdown-top mt-2">
          <label tabIndex={0}>
            <button className="quick-replies-button" onClick={showQuick}>
              <MdOutlineQuickreply className="text-2xl" />
            </button>
          </label>

          {showQuickReplies && (
            <div className="quick-replies-dropdown">
              <ul
                tabIndex={0}
                className="no-scrollbar menu dropdown-content rounded-box z-30 mb-2 h-80 w-80 cursor-pointer flex-nowrap overflow-auto border-[1px] border-base-300 bg-base-100 p-2 shadow"
              >
                {quicksMessages.map((message, index) => {
                  return (
                    <>
                      <li onClick={() => handleOnSend(message, true)}>
                        {message}
                      </li>
                      {index !== quicksMessages.length - 1 && (
                        <div className="divider"></div>
                      )}
                    </>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      )}

      <textarea
        className={`vertical no-scrollbar h-full w-full resize-none rounded-2xl border-[1px] border-gray-300 bg-base-100 px-3 pt-[6px] outline-0 ${inputClassName}`}
        value={text}
        placeholder={`${constants.WRITE_MESSAGE}${
          alphabeticNumber ? ` (${constants.IN_ARABIC})` : ""
        }`}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(e) => {
          const text = e.target.value;
          const words = text.split(/\s+/);
          const exceedsLength = words.some((word) => word.length > WORD_LENGTH);

          if (alphabeticNumber && exceedsLength)
            return toast.error(`الكلمة لايجب ان تتعدى ${WORD_LENGTH} أحرف.`);

          if (alphabeticNumber && regex.test(text)) onChangeText(text);
          else if (!alphabeticNumber) onChangeText(text);
        }}
        onKeyDown={(e) => {
          const target: Target = e.target;
          if (e.key === "Enter" && e.shiftKey) {
            e.preventDefault();
            const currentValue = text;
            const selectionStart = target.selectionStart;
            const selectionEnd = target.selectionEnd;

            const newValue =
              currentValue.substring(0, selectionStart) +
              "\n" +
              currentValue.substring(selectionEnd!);
            onChangeText(newValue);
          } else if (e.key === "Enter") {
            e.preventDefault();
            handleOnSend(text, false);
          }
        }}
      />

      {text && (
        <button
          className={`flex items-center justify-center ${
            color === "primary" ? "text-secondary" : "text-accent"
          }`}
          onClick={() => handleOnSend(text, false)}
        >
          <BiSend className="rotate-180 text-3xl" />
        </button>
      )}
    </div>
  );
};

export default Input;
