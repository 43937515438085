import classNames from "classnames";
import { FC, ReactNode } from "react";

interface Props {
  onClick: () => void;
  children?: ReactNode;
  className?: string;
  color?:
    | "btn-neutral"
    | "btn-primary"
    | "btn-secondary"
    | "btn-accent"
    | "btn-info"
    | "btn-success"
    | "btn-warning"
    | "btn-error";
  isLink?: boolean;
  outline?: boolean;
  active?: boolean;
  disable?: boolean;
  loading?: boolean;
  Icon?: FC;
  size?: "btn-lg" | "btn-md" | "btn-sm" | "btn-xs";
}
const Button = ({
  active,
  children,
  className,
  color = "btn-accent",
  disable,
  isLink,
  onClick,
  size = "btn-md",
  outline,
  loading,
  Icon,
}: Props) => {
  return (
    <button
      onClick={!loading ? onClick : undefined}
      className={classNames({
        "btn flex min-w-[9rem] justify-center self-center rounded-xl text-white":
          true,
        "btn-link": isLink,
        "btn-outline": outline,
        "btn-active": active,
        "btn-disabled": loading || disable,
        [size]: size,
        [color]: color,
        [`${className}`]: Boolean(className),
      })}
    >
      {Icon && <Icon />}
      {loading && <span className="loading loading-spinner ml-2"></span>}
      {children}
    </button>
  );
};

export default Button;
