import apiConfig from "configs/api-v2";
import TPreference from "types/TPreference";
import apiClient from "./client-v2";
import { UpdatePreferences } from "./types/users";

const endpoints = apiConfig.endpoints;

const create = (props: TPreference) =>
  apiClient.post<{ token: string; refresh_token: string }>(
    endpoints.preferences,
    props,
  );
const update = (props: UpdatePreferences) =>
  apiClient.put(endpoints.preferences, props);

export default {
  create,
  update,
};
