import { create } from "apisauce";

import apiV2 from "configs/api-v2";
import mem from "mem";
import queryClient from "queries/queryClient";
import expiredToken from "utils/expiredToken";
import refreshPage from "utils/refreshPage";
import storage from "utils/storage";

const maxAge = 10_000; // 10s
const apiClientV2 = create({
  baseURL: apiV2.baseURL,
  headers: { "x-api-key": import.meta.env.VITE_ACCESS_API_KEY },
});

interface ExtendResponse {
  token: string;
  refresh_token: string;
}

// const isNetworkError = (error: AxiosError) =>
//   error && !error.response && error.code !== "ECONNABORTED";

apiClientV2.addAsyncRequestTransform(async (request) => {
  const authToken = storage.getToken();
  if (authToken) request.headers!["Authorization"] = "Bearer " + authToken;
});

export const injectToken = (token: string) => {
  apiClientV2.addAsyncRequestTransform(async (request) => {
    request.headers!["Authorization"] = "Bearer " + token;
  });
};

const extendToken = mem(
  function (token: string) {
    return create({ baseURL: apiV2.baseURL }).get<ExtendResponse>(
      apiV2.endpoints.refresh_token,
      {},
      {
        headers: {
          Authorization: "Bearer " + token,
          "x-api-key": import.meta.env.VITE_ACCESS_API_KEY,
        },
      },
    );
  },
  { maxAge },
);

// throw error for bad response
apiClientV2.axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const response = error.response;

    const thereIsToken = storage.getToken();
    const refresh = "" + storage.getRefreshToken();

    if (response.status === 401 && thereIsToken) {
      const redirect = () => {
        storage.removeToken();
        refreshPage("/login");
      };

      if (expiredToken(refresh)) return redirect();
      try {
        const newTokens = await extendToken(refresh);

        if (newTokens.ok && newTokens.data) {
          storage.storeToken(
            newTokens.data.token,
            newTokens.data.refresh_token,
          );
          queryClient.resetQueries();
        } else redirect();
      } catch (error) {
        console.log(error);
      }
    }

    return error;
  },
);

export default apiClientV2;
