import { useEffect } from "react";
import useSocket from "./useSocket";

interface Props<T> {
  event: string;
  listener: (props: T) => void;
}

const useEvent = <T>({ event, listener }: Props<T>) => {
  const { socket } = useSocket();

  useEffect(() => {
    socket?.on(event, listener);

    return () => {
      socket?.off(event, listener);
    };
  }, [socket]);
};

export default useEvent;
