import { useQuery } from "@tanstack/react-query";
import blocks from "api/blocks";
import ms from "ms";
import queryClient from "./queryClient";

interface Response {
  who?: boolean;
  me?: boolean;
}

export const refreshBlockedQuery = (id: string) =>
  queryClient.invalidateQueries({ queryKey: ["blocked-me", id] });

// check if given user i blocked or he blocked me!
const useBlocked = (id: string) => {
  const query = useQuery({
    queryKey: id ? ["blocked-me", id] : ["blocked-me"],
    queryFn: () =>
      id
        ? blocks.isBlockedMe(id).then((res) => res.data)
        : Promise.resolve({} as Response),
    staleTime: ms("15m"),
  });

  return {
    isBlockedMe: query.data!,
    isLoading: query.isLoading,
    isError: query.isError,
  };
};

export default useBlocked;
