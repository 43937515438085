import configs from "configs/api-v2";
import Badges from "types/Badges";
import TAds from "types/TAds";
import apiClient from "./client-v2";

const endpoints = configs.endpoints;

const getBadges = () => apiClient.get<Badges>(endpoints.badges);

const ads = () => apiClient.get<TAds[]>(endpoints.ads);

export default {
  ads,
  getBadges,
};
