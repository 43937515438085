import { CgEye, CgUserList } from "react-icons/cg";
import { GiDiamondRing } from "react-icons/gi";
import { LuUserCog, LuUsers } from "react-icons/lu";
import { MdOutlineHistoryEdu } from "react-icons/md";
import { RiUserForbidLine, RiUserHeartLine } from "react-icons/ri";
import { SlLike } from "react-icons/sl";

const sidebarSections = [
  {
    label: "profile",
    title: "الملف",
    values: [
      {
        name: "شاهد ملفي",
        path: "visits",
        Icon: CgEye,
        badge: true,
      },
      {
        name: "اعجب بي",
        path: "likes",
        Icon: SlLike,
        badge: true,
      },
      {
        name: "خطبتي",
        path: "engagements",
        Icon: GiDiamondRing,
        badge: true,
      },
      {
        name: "مفضلتي",
        path: "favorites",
        Icon: RiUserHeartLine,
        badge: true,
      },
      {
        name: "قائمة الحظر",
        path: "blocks",
        Icon: RiUserForbidLine,
        badge: true,
      },
    ],
  },

  {
    label: "members",
    title: "الأعضاء",
    values: [
      {
        name: "مطابقين لي",
        path: "matched",
        Icon: LuUsers,
        badge: false,
      },
      {
        name: "مناسبين لي",
        path: "forme",
        Icon: LuUsers,
        badge: false,
      },
      {
        name: "القصص الناجحة",
        path: "stories",
        Icon: MdOutlineHistoryEdu,
        badge: false,
      },
    ],
  },

  {
    label: "settings",
    title: "الاعدادات",
    values: [
      {
        name: "تعديل الحساب",
        path: "edit/general",
        Icon: LuUserCog,
        badge: false,
      },
      {
        name: "تعديل مواصفاتك",
        path: "edit/profile",
        Icon: CgUserList,
        badge: false,
      },
      {
        name: "تعديل مواصفات الشريك",
        path: "edit/preferences",
        Icon: CgUserList,
        badge: false,
      },
    ],
  },
];

export default sidebarSections;
