import jwtDecode from "jwt-decode";
import dayjs from "dayjs";

interface JWT {
  _id: string;
  exp: number;
  iat: number;
}

export default (token: string) => {
  try {
    if (!token || token === "null") return true;
    const exp = jwtDecode<JWT>(token).exp;
    return dayjs(exp * 1000).diff(Date.now(), "minute") <= 0;
  } catch (error) {
    console.error("Error decoding JWT:", error);
  }
};
