import List from "components/lists/List";

const UserSkeletons = ({ length = 10 }: { length?: number }) => {
  const data = Array.from({ length }, (_, index) => index);

  return (
    <List
      data={data}
      keyExtractor={(_, index) => index.toString()}
      renderItem={() => {
        return (
          <div className=" mx-auto my-4 flex w-[90%]  animate-pulse">
            <div className="flex-shrink-0">
              <span className="ml-2 mt-1 block h-16 w-16 rounded-full bg-base-300"></span>
            </div>

            <div className="ml-4 mt-1 w-full">
              <h3 className="mb-2 h-4 w-[40%] rounded-md bg-base-300"></h3>
              <h3 className="h-10 rounded-md bg-base-300"></h3>

              {/* <ul className="mt-5 space-y-3">
          <li className="h-4 w-full rounded-md bg-base-300"></li>
          <li className="h-4 w-full rounded-md bg-base-300"></li>
          <li className="h-4 w-full rounded-md bg-base-300"></li>
          <li className="h-4 w-full rounded-md bg-base-300"></li>
        </ul> */}
            </div>
          </div>
        );
      }}
    />
  );
};

export default UserSkeletons;
