import Button from "components/core/Button";
import constants from "configs/constants";
import TGuardian from "types/TGuardian";
import { GUARDIAN_FORM_NOTE } from "../strings";

import { yupResolver } from "@hookform/resolvers/yup";
import FormFiled from "components/forms/FormFiled";
import schema from "components/forms/schema";
import useForm from "hooks/useForm";

interface Props {
  visible: boolean;
  close: (v: boolean) => void;
  onClick: (guardian: TGuardian) => void;
}
const GuardianInfo = ({ visible, close, onClick }: Props) => {
  const { handleSubmit, errors, control } = useForm<TGuardian>({
    resolver: yupResolver(schema.guardianInfo),
  });

  const onSubmit = (values: TGuardian) => {
    close(false);
    onClick(values);
  };

  return (
    <dialog
      id="show-modal"
      className={`modal modal-middle ${visible && "modal-open"}`}
    >
      <div className="no-scrollbar modal-box flex w-[80%] flex-col gap-y-2 pt-6 md:w-[28rem]">
        <form method="dialog">
          <button
            onClick={() => close(false)}
            className="btn btn-circle btn-ghost btn-sm absolute right-1 top-1"
          >
            ✕
          </button>
        </form>

        <span className="label-text mb-4 leading-6">{GUARDIAN_FORM_NOTE}</span>
        <FormFiled
          control={control}
          error={errors.girl_name}
          name="girl_name"
          placeholder={constants.NAME}
        />
        <FormFiled
          control={control}
          error={errors.guardian_name}
          name="guardian_name"
          placeholder={constants.GUARDIAN_NAME}
        />
        <FormFiled
          type="tel"
          control={control}
          error={errors.guardian_phone_number}
          name="guardian_phone_number"
          placeholder={constants.GUARDIAN_PHONE}
        />

        <FormFiled
          control={control}
          error={errors.suggestion_how_to_find}
          name="suggestion_how_to_find"
          placeholder={constants.FIND_SUGGESTION}
          type="textArea"
        />

        <Button onClick={handleSubmit(onSubmit)}>{constants.SEND}</Button>
      </div>
    </dialog>
  );
};

export default GuardianInfo;
