import constants from "configs/constants";
import { ReactNode, createContext, useRef, useState } from "react";
import { CgCheck, CgClose } from "react-icons/cg";

interface Props {
  title: string;
  content: string;
  onConfirm: () => void;
}

export const AlertContext = createContext<{ alert: (props: Props) => void }>({
  alert: () => {},
});

const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const onDoneRef = useRef(() => {});

  function close() {
    setVisible(!visible);
  }

  function done() {
    setVisible(false);
    if (typeof onDoneRef.current === "function") onDoneRef?.current();
  }

  function alert({ title, content, onConfirm }: Props) {
    setTitle(title);
    setContent(content);
    onDoneRef.current = onConfirm;
    setVisible(true);
  }

  return (
    <AlertContext.Provider value={{ alert }}>
      <dialog
        id="show-modal"
        className={`modal modal-middle ${visible && "modal-open"}`}
      >
        <div className="modal-box w-96">
          {title && <h3 className="text-center text-2xl font-bold">{title}</h3>}
          <p className="w-full py-4 pb-8 text-center">{content}</p>
          <div className="flex flex-row justify-center gap-8">
            <button className="btn btn-error px-4" onClick={close}>
              <CgClose />
              {constants.CLOSE}
            </button>
            <button className="btn btn-success px-4" onClick={done}>
              <CgCheck />
              {constants.CONFIRM}
            </button>
          </div>
        </div>
      </dialog>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
