import * as Sentry from "@sentry/react";
import constants from "configs/constants";
import Auth from "layouts/Auth";
import { useEffect } from "react";
import { PiSmileySadThin } from "react-icons/pi";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

const AuthError = () => {
  const error = useRouteError();
  const isRouteError = isRouteErrorResponse(error);

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <Auth>
      <div className="flex h-screen flex-col items-center justify-end">
        <div className="flex h-[65%] w-[98%] flex-col gap-y-5 rounded-tl-2xl rounded-tr-2xl border-[1px] border-b-0 border-primary bg-base-100 px-5 pt-10">
          <div className="no-scrollbar flex h-screen items-center justify-center">
            <article className="prose  text-center">
              <PiSmileySadThin className="m-auto self-center text-center text-8xl" />
              <p>
                {isRouteError
                  ? constants.ROUTE_NOT_EXIST
                  : constants.ERRORS.UNEXPECTED_ERROR}
              </p>
            </article>
          </div>
        </div>
      </div>
    </Auth>
  );
};

export default AuthError;
