import constants from "configs/constants";
import { AiOutlineLogout, AiOutlineMessage } from "react-icons/ai";
import { BiHomeAlt, BiSearch } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa";
import { GiDiamondRing } from "react-icons/gi";
import { MdPassword } from "react-icons/md";
import { TbUserX } from "react-icons/tb";

export const navbarIcons = [
  { path: "/", Icon: BiHomeAlt, name: constants.BAR_HOME },
  { path: "/engagements", Icon: GiDiamondRing, name: constants.BAR_ENGAGE },
  { path: "/search", Icon: BiSearch, name: constants.BAR_SEARCH },
  { path: "/chats", Icon: AiOutlineMessage, name: constants.BAR_MESSAGES },
];

export const navbarSetting = [
  { path: "/profile", Icon: FaRegUser, name: "الملف الشخصي" },
  { path: "/edit/password", Icon: MdPassword, name: "تغير كلمة المرور" },
  {
    path: "/account/permanent-deletion",
    Icon: TbUserX,
    name: "حذف نهائي",
  },
  { path: "/logout", Icon: AiOutlineLogout, name: "تسجيل الخروج" },
];
