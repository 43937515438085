import EmptyPage from "components/Empty";
import Switch from "components/core/Switch";
import List from "components/lists/List";
import UserSkeletons from "components/theme/skeleton/users";
import constants from "configs/constants";
import useAlert from "hooks/useAlert";
import useToast from "hooks/useToast";
import useNotifications from "queries/notifications";
import useUser from "queries/user";
import { useState } from "react";
import { CgClose } from "react-icons/cg";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import TNotification from "types/TNotification";
import textWithNewLines from "utils/textWithNewLines";
import { timeFormat } from "utils/time";
import logo from "../../assets/logo.svg";
import NotifyListItem from "./NotifyListItem";

const RemoveReason = () => {
  return (
    <div>
      <p className="mb-2">
        نعتذر لحجب رسالتك من خلال الفلتر الإلكتروني لمخالفتها شروط عمل الموقع،
        ونشكر لك حرصك وتفهمك.
      </p>
      <ul>
        <li className="list-item">
          يرجي الانتباه إلي أن تبادل البيانات والصور يكون من خلال قسم الخطبه.
        </li>
        <li className="list-item">
          يستطيع الشاب الدخول الى ملف الفتاه والتقدم بطلب خطبه لتتمكنا من
          التواصل بطريقه صحيحه وسريعه.
        </li>
        <li className="list-item">
          يمكنك مراجعة الدعم الفني إذا لديك شكوى أو استفسار
        </li>
      </ul>
    </div>
  );
};

const NotificationPage = () => {
  const { user, updateUser } = useUser();
  const toast = useToast();
  const alert = useAlert();
  const navigate = useNavigate();
  const [selectedNot, setSelectedNot] = useState<TNotification>();
  const [showDropdown, setShowDropdown] = useState(true);

  const {
    notifications,
    removeAll,
    readNotification,
    isLoading,
    isError,
    error,
  } = useNotifications();

  const onNotificationClick = async (item: TNotification) => {
    const { uid, type, isRead, _id } = item;

    if (!isRead) readNotification(_id);

    if (type.includes("engage")) navigate("/engagements");
    else if (type === "like") navigate(uid ? `/profile/${uid}` : "/likes");
    else if (type === "visit") navigate(uid ? `/profile/${uid}` : "/visits");
    else if (type === "mail") setSelectedNot(item);

    setShowDropdown(false);
    setTimeout(() => setShowDropdown(true), 200);
  };

  const toggleNotification = async (value: boolean) => {
    updateUser({ notification_enable: value });
    toast.success(`تم ${value ? "فتح" : "غلق"} تلقي الاشعارات`);
  };

  const removeAllNotification = async () => {
    removeAll();
    toast.success(constants.TOAST.NOTIFICATIONS_REMOVED);
  };

  if (isError) throw new Error(error?.name);

  return (
    <>
      <div
        tabIndex={0}
        className={`${
          !showDropdown && "hidden"
        } no-scrollbar max-lg:-right-auto card dropdown-content card-compact z-50 h-[85vh] w-[88vw] overflow-auto border-[2px] border-base-300 bg-base-100 shadow max-md:-right-[60vw] max-sm:-right-[60vw]  sm:w-[30rem]`}
      >
        {isLoading ? (
          <UserSkeletons />
        ) : !notifications.length ? (
          <EmptyPage
            content={constants.EMPTY_NOTIFICATION_LIST}
            Icon={IoMdNotificationsOutline}
          />
        ) : (
          <List<TNotification>
            data={notifications}
            keyExtractor={(item, index) => item._id || index.toString()}
            Header={
              <div className="flex flex-row items-center justify-between p-2 px-5">
                {!!notifications.length && (
                  <div className="flex flex-col items-center gap-1">
                    <div
                      className="btn btn-circle btn-error btn-sm"
                      onClick={() =>
                        alert({
                          title: constants.BAR_NOTIFICATIONS,
                          content: constants.ALERT.REMOVE_ALL_NOTIFICATIONS,
                          onConfirm: removeAllNotification,
                        })
                      }
                    >
                      <CgClose className="text-base" />
                    </div>
                    <span className="text-sm">{constants.REMOVE_ALL}</span>
                  </div>
                )}

                <div className="">
                  <Switch
                    title={constants.RECEIVE_NOTIFICATION}
                    checked={user.notification_enable}
                    toggle={toggleNotification}
                  />
                </div>
              </div>
            }
            renderItem={(item) => {
              const { isRead, title, icon, content, time, type } = item;

              return (
                <NotifyListItem
                  type={type}
                  title={title}
                  isRead={isRead}
                  content={content}
                  image={["mail", "payment"].includes(type) ? logo : icon}
                  time={timeFormat(time)}
                  onClick={() => onNotificationClick(item)}
                />
              );
            }}
          />
        )}
      </div>

      {/* notification preview */}
      <div style={{ direction: "rtl" }} className="">
        <dialog className={`modal ${!!selectedNot && "modal-open"}`}>
          <div className="no-scrollbar modal-box">
            <form method="dialog">
              <button
                onClick={() => setSelectedNot(undefined)}
                className="btn btn-circle btn-ghost btn-sm absolute right-2 top-2"
              >
                ✕
              </button>
            </form>

            <div className="p-5">
              <p className="">{selectedNot?.title}</p>
              <p className="leading-7">
                {textWithNewLines(selectedNot?.content || "")}
              </p>
              <br />
              {selectedNot?.content.includes(constants.REMOVED_CONTENT) && (
                <RemoveReason />
              )}
            </div>
          </div>
        </dialog>
      </div>
    </>
  );
};

export default NotificationPage;
