import constants from "configs/constants";
import { Link, Outlet, useLocation } from "react-router-dom";

const routes = [
  { title: constants.BASIC_SEARCH, path: "/search" },
  { title: constants.ADVANCE_SEARCH, path: "/search/advanced" },
];

const SearchPage = () => {
  const { pathname } = useLocation();

  return (
    <div>
      <div className="my-4 flex items-center justify-center">
        <div className="tabs-xs sm:tabs-sm md:tabs-md tabs-boxed w-fit gap-x-5">
          {routes.map((e, i) => {
            return (
              <Link
                key={i.toString()}
                to={e.path}
                className={`tab ${
                  pathname === e.path && "tab-active font-semibold !text-white"
                }`}
              >
                {e.title}
              </Link>
            );
          })}
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default SearchPage;
