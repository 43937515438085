import apiConfig from "configs/api-v2";
import TProfile from "types/TProfile";
import apiClient from "./client-v2";
import { UpdateProfile } from "./types/users";

const endpoints = apiConfig.endpoints;

const create = (profile: TProfile) =>
  apiClient.post(endpoints.profiles, profile);

const update = (props: UpdateProfile) =>
  apiClient.put(endpoints.profiles, props);

export default {
  create,
  update,
};
