import dayjs from "dayjs";
import useUser from "queries/user";
import { FiFilter } from "react-icons/fi";
import TPreference from "types/TPreference";
import TProfile from "types/TProfile";
import UserItem from "types/UserItem";

interface Props {
  list: UserItem[];
  measure?: (
    gender: string,
    pref: TPreference,
    profile: TProfile & { age: number },
  ) => void;
  onSort: (v: string | UserItem[]) => void;
}

export default function FilterList({ list, measure, onSort }: Props) {
  const { user } = useUser();
  const isMale = user.gender === "male";

  const cat_ = isMale ? "الفتيات" : "الشباب";
  const keys = [
    "الكل",
    cat_,
    "وكلاء عن " + cat_,
    "متواجدين الان",
    "الأحدث دخولا",
    "نسبة التطابق",
  ];
  if (!measure) keys.pop();

  const handelOnSorting = (index: number) => {
    if (index === 0) onSort("reset");
    else if (index === 1) {
      onSort(list.filter((user) => !user.is_guardian));
    } else if (index === 2) {
      onSort(list.filter((user) => user.is_guardian));
    } else if (index === 3) {
      onSort(list.filter((user) => user.is_active && user.is_online));
    } else if (index === 4) {
      onSort(
        list.sort((u1, u2) => {
          const conditionOne = u2.last_seen || u2.createdAt;
          const conditionTow = u1.last_seen || u1.createdAt;
          return (
            Date.parse(dayjs(conditionOne).toString()) -
            Date.parse(dayjs(conditionTow).toString())
          );
        }),
      );
    } else if (index === 5 && measure) {
      onSort(list.sort((u1, u2) => u2.match_percent! - u1.match_percent!));
    }
  };

  return (
    <div className="dropdown dropdown-left">
      <button tabIndex={0} className="px-aut btn btn-circle bg-base-200">
        <FiFilter className="text-xl" />
      </button>
      <ul
        tabIndex={0}
        className="menu dropdown-content rounded-box z-[1] w-52 border-2 border-base-200 bg-base-100 p-2 shadow"
      >
        {keys.map((key, index) => {
          return (
            <li key={index.toString()} onClick={() => handelOnSorting(index)}>
              <span>{key}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
