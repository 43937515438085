import { useCallback } from "react";
import { Router } from "@remix-run/router";
import { NavigateOptions } from "react-router-dom";

let globalRouter: Router | null = null;

export const setGlobalRouter = (routerInstance: Router): void => {
  globalRouter = routerInstance;
};

export const navigate = (
  path: string,
  options: { replace?: boolean } = {},
): void => {
  if (globalRouter) globalRouter.navigate(path, options);
};

const useGlobalNavigate = () => {
  const handleNavigate = useCallback(
    (path: string, options: NavigateOptions = {}) => {
      navigate(path, options);
    },
    [],
  );

  return handleNavigate;
};

export default useGlobalNavigate;
