import classNames from "classnames";

interface Props {
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  value: string | number | readonly string[] | undefined;
  setValue: (v: string) => void;
  className?: string;
  containerClassName?: string;
  error?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  size?: "input-xs" | "input-sm" | "input-md" | "input-lg";
}

const TextArea = ({
  placeholder = "",
  disabled,
  value,
  setValue,
  className,
  containerClassName,
  error,
  errorMessage,
}: Props) => {
  return (
    <div className={`flex flex-col ${containerClassName}`}>
      <textarea
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        className={classNames({
          "textarea textarea-accent rounded-lg !border-[0.1rem] placeholder:text-gray-400 focus:outline-none":
            true,
          "textarea-disabled": disabled,
          [`${className}`]: Boolean(className),
          "border-error": Boolean(error),
        })}
      ></textarea>

      <span className={`mr-2 mt-2 text-sm text-error`}>{errorMessage}</span>
    </div>
  );
};

export default TextArea;
