import { useQuery } from "@tanstack/react-query";
import search from "api/search";
import ms from "ms";
import queryClient from "./queryClient";

export const refreshProposalUsersQuery = () =>
  queryClient.invalidateQueries({ queryKey: ["proposal-users"] });

const useProposalUsers = ({ all }: { all: boolean }) => {
  const query = useQuery({
    queryKey: all ? ["all-proposal-users"] : ["proposal-users"],
    queryFn: () => search.proposals({ all }).then((res) => res.data),
    staleTime: ms("2h"),
  });

  return {
    proposalUsers: query.data!,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export default useProposalUsers;
