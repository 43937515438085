import classNames from "classnames";
import constants from "configs/constants";
import SelectSearch from "react-select";

interface Props {
  label: string;
  options: string[];
  value: string | string[] | null;
  error: boolean;
  setValue: (v: string | string[]) => void;
  multiple?: boolean;
  withSearch?: boolean;
}

type TValue = { label: string; value: string };

const SelectPicker = ({
  label,
  options,
  value,
  error,
  setValue,
  multiple = false,
  withSearch = false,
}: Props) => {
  const isValueExist = !!(value && value.length);
  let selectedValue: any = value === null ? "" : { label: value, value };
  if (multiple && value)
    selectedValue = (value as string[]).map((e) => ({ label: e, value: e }));
  else if (multiple) selectedValue = [];

  return (
    <div className={`relative mt-2 flex w-full items-center justify-between`}>
      {isValueExist && (
        <p className="absolute -top-[8px] right-3 z-[2] bg-base-100 px-1 text-[10px] font-bold">
          {label}
        </p>
      )}
      <div className="flex-1">
        <SelectSearch
          isRtl
          placeholder={label}
          isMulti={multiple}
          isSearchable={withSearch}
          value={selectedValue}
          closeMenuOnSelect={!multiple}
          hideSelectedOptions={!multiple}
          maxMenuHeight={140}
          options={options.map((e) => ({ label: e, value: e }))}
          classNamePrefix="react-select"
          className={classNames({
            "rounded-lg border-2 border-error": error,
          })}
          styles={{
            menu: (base) => ({ ...base, zIndex: 100 }),
            singleValue: (b) => ({ ...b, fontSize: 14 }),
          }}
          onChange={(e) => {
            if (multiple) {
              let values = (e as TValue[]).map((item) => item.value);

              // If NOT_REQUIRE is selected and it's the first selection or no previous value, only keep NOT_REQUIRE
              if (
                values.includes(constants.NOT_REQUIRE) &&
                (values[0] !== constants.NOT_REQUIRE ||
                  !(selectedValue as []).length)
              ) {
                values = [constants.NOT_REQUIRE];
              } else if (values.includes(constants.NOT_REQUIRE)) {
                // Remove NOT_REQUIRE if there are other selections
                values = values.filter(
                  (value) => value !== constants.NOT_REQUIRE,
                );
              }

              setValue(values);
            } else {
              setValue((e as TValue)?.value);
            }
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 6,
            colors: {
              ...theme.colors,
              primary25: "#ffaacc",
              primary: "#ffaacc",
            },
          })}
        />
      </div>
    </div>
  );
};

export default SelectPicker;
