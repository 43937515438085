import SelectPicker from "components/picker/Select";
import constants from "configs/constants";
import useStorage from "hooks/useStorage";

import { Type as PrefType } from "../../preferencesProps";
import Props from "../../profileProps";

interface Props {
  prefs: PrefType;
  setPrefs: (v: PrefType) => void;
  errors: string[];
  setErrors: (v: string[]) => void;
}

const StepTow = ({ prefs, setPrefs, errors, setErrors }: Props) => {
  const shapeOptions = (array: Array<string>) => {
    return [constants.NOT_REQUIRE, ...array];
  };

  const validate = (propName: string) => {
    if (errors.includes(propName))
      setErrors(errors.filter((e) => e !== propName));
  };

  const { options } = useStorage();

  const { education, prayers, health } = prefs;
  const { occupation, relationship_status, financial_status } = prefs;

  const OpGender = prefs.gender === "male" ? "female" : "male";
  const relationship_options = options.relationship_status_options[OpGender];
  const financial_status_options = options.financial_status_options[OpGender];
  const occupations_options = options.occupations_options[OpGender];
  const education_options = options.education_options[OpGender];
  const prayers_options = options.prayers_options[OpGender];
  const health_options = options.health_options[OpGender];

  return (
    <div className="px-4 py-2">
      <div className="mt-5 flex w-full max-md:mb-10 max-md:mt-5 max-md:flex-col max-md:items-center">
        <div className="flex w-1/2 flex-col gap-5 max-md:w-[85%]">
          <SelectPicker
            multiple
            options={shapeOptions(relationship_options)}
            label={constants.RELATIONSHIP_STATUS}
            value={relationship_status!}
            error={errors.includes("relationship_status")}
            setValue={(v) => {
              validate("relationship_status");
              setPrefs({ ...prefs, relationship_status: v as string[] });
            }}
          />

          <SelectPicker
            multiple
            options={shapeOptions(financial_status_options)}
            label={constants.FINANCIAL_STATUS}
            value={financial_status!}
            error={errors.includes("financial_status")}
            setValue={(v) => {
              validate("financial_status");
              setPrefs({ ...prefs, financial_status: v as string[] });
            }}
          />

          <SelectPicker
            multiple
            options={shapeOptions(health_options)}
            label={constants.HEALTH}
            value={health!}
            error={errors.includes("health")}
            setValue={(v) => {
              validate("health");
              setPrefs({ ...prefs, health: v as string[] });
            }}
          />
        </div>

        <div className="divider divider-horizontal mx-10 mt-5 max-md:hidden"></div>

        <div className="flex w-1/2 flex-col gap-5 max-md:mt-5 max-md:w-[85%]">
          <SelectPicker
            multiple
            options={shapeOptions(prayers_options)}
            label={constants.PRAYERS}
            value={prayers!}
            error={errors.includes("prayers")}
            setValue={(v) => {
              validate("prayers");
              setPrefs({ ...prefs, prayers: v as string[] });
            }}
          />

          <SelectPicker
            multiple
            options={shapeOptions(education_options)}
            label={constants.EDUCATION}
            value={education!}
            error={errors.includes("education")}
            setValue={(v) => {
              validate("education");
              setPrefs({ ...prefs, education: v as string[] });
            }}
          />

          <SelectPicker
            multiple
            options={shapeOptions(occupations_options)}
            label={constants.OCCUPATION}
            value={occupation!}
            error={errors.includes("occupation")}
            setValue={(v) => {
              validate("occupation");
              setPrefs({ ...prefs, occupation: v as string[] });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StepTow;
