import { useMutation, useQuery } from "@tanstack/react-query";
import { UpdateUser } from "api/types/users";
import users, { UserInfo } from "api/users";
import useAuth from "hooks/useAuth";
import ms from "ms";
import queryClient from "./queryClient";
import guardians from "api/guardians";

export const refreshUserQuery = () =>
  queryClient.invalidateQueries({ queryKey: ["user"] });

const useUser = () => {
  const { isAuth, isGuardian, logout } = useAuth();
  const fn = isAuth
    ? isGuardian
      ? () => guardians.get().then((res) => res.data)
      : async () => {
          const user = await users.get();
          if (user.ok) return user.data;
          else if (user.data?.name === "USER_DELETED") logout();
        }
    : () => Promise.resolve<UserInfo>({} as UserInfo);

  const query = useQuery({
    queryKey: ["user"],
    staleTime: ms("2h"),
    queryFn: fn,
  });

  const setUser = (update: UpdateUser) => {
    queryClient.setQueryData<UpdateUser>(["user"], (user) => ({
      ...user,
      ...update,
    }));
  };

  const { mutate: updateUser } = useMutation({
    mutationFn: (update: UpdateUser) =>
      isGuardian ? guardians.update(update) : users.update(update),
    onMutate: (update: UpdateUser) => {
      const previous = queryClient.getQueryData(["user"]) || {};
      queryClient.setQueryData<UpdateUser>(["user"], (user) => ({
        ...user,
        ...update,
      }));

      return { previous };
    },
    onError: (_, __, context) => {
      if (!context) return;
      queryClient.setQueryData<UserInfo>(
        ["user"],
        context.previous as UserInfo,
      );
    },
  });

  const removed = query.data?.completed_profile === 1 && query.data?.is_removed;
  const canceled = query.data?.status === "canceled";
  const pending = query.data?.status === "pending" && isGuardian;

  return {
    user: query.data! || {},
    isLoading: query.isLoading,
    isError: query.isError,
    updateUser,
    setUser,
    male: query.data?.gender === "male",
    engaged: ["engaged", "married"].includes(query.data?.status || ""),
    removed,
    canceled,
    pending,
    unAccepted: removed || canceled || pending,
  };
};

export default useUser;
