import classNames from "classnames";
import RowListItem from "components/lists/RowListItem";
import HorizontalSkeletons from "components/theme/skeleton/horizontal";
import constants from "configs/constants";
import useSearch from "queries/search";
import { forwardRef, useEffect, useRef, useState } from "react";

const LIMIT = 20;

type UserCarouselProps = {
  users: any[];
  isFetching: boolean;
  title: string;
};

const UserItem = forwardRef<HTMLDivElement, UserCarouselProps>(
  ({ users, isFetching, title }, ref) => {
    return (
      <div
        className={classNames({
          "rounded-box mx-auto w-[90%] overflow-hidden border-[0.1rem] border-gray-200 bg-white p-1 px-4 transition-all md:w-[80%] xl:w-[70%]":
            true,
          hidden: !isFetching && !users.length,
        })}
      >
        {!!users.length && (
          <p className="my-2 text-center text-lg font-bold text-primary">
            {title}
          </p>
        )}
        <div
          dir="ltr"
          ref={ref}
          className="carousel rounded-box gap-x-3 overflow-x-auto"
          style={{ scrollSnapType: "x mandatory" }}
        >
          {isFetching ? (
            <HorizontalSkeletons />
          ) : (
            users.map((user) => (
              <div
                key={user._id}
                className="carousel-item !h-[14rem]"
                style={{ scrollSnapAlign: "start" }}
              >
                <RowListItem
                  online
                  age={user.age}
                  city={user.city}
                  username={user.name}
                  country={user.country}
                  guardian={user.is_guardian}
                  image={user.profile_picture}
                  status={user.relationship_status}
                  to={`/guest/users/${user.uid}`}
                  target="_blank"
                />
              </div>
            ))
          )}
        </div>
      </div>
    );
  },
);

const Online = () => {
  const setMaleId = useState(0)[1];
  const setFemaleId = useState(0)[1];
  const maleContainerRef = useRef<HTMLDivElement | null>(null);
  const femaleContainerRef = useRef<HTMLDivElement | null>(null);
  const [perPage, setPerPage] = useState(5); // Default to xl size

  const mResults = useSearch({
    guest: true,
    page: 1,
    params: { gender: "male", status: true },
  });

  const fResults = useSearch({
    guest: true,
    page: 1,
    params: { gender: "female", status: true },
  });

  const males = mResults.data?.results || [];
  const females = fResults.data?.results || [];

  useEffect(() => {
    // Function to update PER_PAGE based on screen size
    const updatePerPage = () => {
      if (window.innerWidth >= 1280) {
        setPerPage(5); // xl
      } else if (window.innerWidth >= 1024) {
        setPerPage(4); // lg
      } else if (window.innerWidth >= 768) {
        setPerPage(3); // md
      } else {
        setPerPage(2);
      }
    };

    // Call the function initially
    updatePerPage();

    // Add resize event listener to update perPage when window is resized
    window.addEventListener("resize", updatePerPage);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", updatePerPage);
    };
  }, []);

  useEffect(() => {
    const mInterval = setInterval(() => {
      setMaleId((prevId) => {
        const newId = prevId + 1;
        if (maleContainerRef.current) {
          const itemWidth = maleContainerRef.current.clientWidth / perPage;
          maleContainerRef.current.scrollTo({
            left: newId * itemWidth, // Updated to scroll horizontally
            behavior: "smooth",
          });
        }
        if (newId + (perPage - 1) >= LIMIT) return 0;
        return newId;
      });
    }, 5000);

    const fInterval = setInterval(() => {
      setFemaleId((prevId) => {
        const newId = prevId + 1;
        if (femaleContainerRef.current) {
          const itemWidth = femaleContainerRef.current.clientWidth / perPage;
          femaleContainerRef.current.scrollTo({
            left: newId * itemWidth, // Updated to scroll horizontally
            behavior: "smooth",
          });
        }
        if (newId + (perPage - 1) >= LIMIT) return 0;
        return newId;
      });
    }, 5000);

    return () => {
      clearInterval(mInterval);
      clearInterval(fInterval);
    };
  }, [males.length, females.length]);

  return (
    <div className={"mx-auto mb-16 mt-5 flex max-w-6xl overflow-hidden"}>
      <div className="flex w-full flex-col gap-y-5">
        <UserItem
          users={males}
          ref={maleContainerRef}
          title={constants.ONLINE_MALE_USERS}
          isFetching={mResults.isFetching}
        />

        <UserItem
          users={females}
          ref={femaleContainerRef}
          title={constants.ONLINE_FEMALE_USERS}
          isFetching={fResults.isFetching}
        />
      </div>
    </div>
  );
};

export default Online;
