import { useMutation, useQuery } from "@tanstack/react-query";
import notifications from "api/notifications";
import useAuth from "hooks/useAuth";
import ms from "ms";
import Badges from "types/Badges";
import TNotification from "types/TNotification";
import notify from "utils/notify";
import queryClient from "./queryClient";

export const refreshNotificationsQuery = () =>
  queryClient.invalidateQueries({ queryKey: ["notifications"] });

const useNotifications = () => {
  const { isAuth, isGuardian } = useAuth();

  const query = useQuery({
    queryKey: ["notifications"],
    staleTime: ms("2h"),
    queryFn: () =>
      isAuth && !isGuardian
        ? notifications.get().then((res) => res.data)
        : Promise.resolve<TNotification[]>([]),
  });

  const setBadge = (set: (badges: Badges) => Badges) => {
    const badges = queryClient.getQueryData<Badges>(["badges"])!;
    queryClient.setQueryData<Badges>(["badges"], () => set(badges));
  };

  const setNotifications = (notifications: TNotification[]) => {
    queryClient.setQueryData<TNotification[]>(
      ["notifications"],
      () => notifications,
    );
  };

  const addNotification = (notification: TNotification) => {
    setBadge((b) => ({ ...b, notifications: b.notifications + 1 }));
    queryClient.setQueryData<TNotification[]>(["notifications"], (not) => [
      notification,
      ...(not || []),
    ]);

    notify({
      title: notification.title,
      message: notification.content,
      sound: "NOTIFY",
    });
  };

  const { mutate: removeAll } = useMutation({
    mutationFn: notifications.removeAll,

    onMutate: () => {
      const previous = queryClient.getQueryData(["notifications"]) || [];
      queryClient.setQueryData<TNotification[]>(["notifications"], () => []);
      setBadge((b) => ({ ...b, notifications: 0 }));

      return { previous };
    },

    onError: (_, __, context) => {
      if (!context) return;
      queryClient.setQueryData<TNotification[]>(
        ["notifications"],
        context.previous as TNotification[],
      );
    },
  });

  const { mutate: removeNotification } = useMutation({
    mutationFn: notifications.remove,

    onMutate: (id: string) => {
      const previous =
        queryClient.getQueryData<TNotification[]>(["notifications"]) || [];

      queryClient.setQueryData<TNotification[]>(
        ["notifications"],
        (notifications) => notifications?.filter(({ _id }) => _id !== id),
      );

      setBadge((b) => ({ ...b, notifications: b.notifications - 1 }));
      return { previous };
    },

    onError: (_, __, context) => {
      if (!context) return;
      queryClient.setQueryData<TNotification[]>(
        ["notifications"],
        context.previous as TNotification[],
      );
    },
  });

  const { mutate: readNotification } = useMutation({
    mutationFn: notifications.makeAsRead,

    onMutate: (id: string) => {
      const previous =
        queryClient.getQueryData<TNotification[]>(["notifications"]) || [];

      queryClient.setQueryData<TNotification[]>(
        ["notifications"],
        (notifications) =>
          notifications?.map((n) =>
            n._id === id ? { ...n, isRead: true } : n,
          ),
      );

      setBadge((b) => ({ ...b, notifications: b.notifications - 1 }));

      return { previous };
    },

    onError: (_, __, context) => {
      if (!context) return;
      queryClient.setQueryData<TNotification[]>(
        ["notifications"],
        context.previous as TNotification[],
      );
    },
  });

  return {
    setNotifications,
    addNotification,
    removeNotification,
    readNotification,
    removeAll,
    badge: query.data?.length,
    notifications: query.data! || [],
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export default useNotifications;
