import Avatar from "components/Avatar";
import { ReactNode } from "react";
import { GiDiamondRing } from "react-icons/gi";
import { SlLocationPin } from "react-icons/sl";
import { Link } from "react-router-dom";

interface Props {
  online: boolean;
  age: number;
  image: string;
  country: string;
  city: string;
  username: string;
  status: string;
  matchedPercent?: number;
  guardian: boolean;
  to?: string;
  onClick?: () => void;
  state?: any;
  target?: React.HTMLAttributeAnchorTarget | undefined;
}

const Div = ({ children, ...props }: { children: ReactNode }) => (
  <div {...props}>{children}</div>
);

const RowListItem = ({
  online,
  age,
  image,
  country,
  city,
  username,
  status,
  matchedPercent,
  guardian,
  to,
  state,
  onClick,
  target,
}: Props) => {
  const Wrapper = to ? Link : Div;

  return (
    <Wrapper
      to={to!}
      state={state}
      onClick={onClick}
      target={target}
      className={`mx-auto my-3 flex h-40 w-[9rem] cursor-pointer flex-col items-center overflow-hidden rounded-3xl border-[0.1rem] border-gray-100 bg-white p-1 shadow-lg transition-all hover:border-gray-200 hover:opacity-95 hover:shadow-xl`}
    >
      <div className="mt-1 px-2 pl-3">
        <Avatar
          online={online}
          offline={online === false ? true : undefined}
          src={image}
          bordered={guardian}
          badge={matchedPercent}
        />
      </div>
      <div className="flex w-full flex-col">
        <h3 className="mb-2 w-5/6 self-center truncate text-center text-lg">
          {username}
        </h3>
        <div className="flex w-5/6 flex-col self-center truncate">
          <div className="flex  gap-1 text-xs">
            <SlLocationPin className="text-error" />
            <span className="line-clamp-1">
              {country} / {city}
            </span>
          </div>
          <div className="mt-1 line-clamp-1 flex w-full gap-1 text-xs">
            <GiDiamondRing className="text-accent" />
            {status} / العمر {age}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default RowListItem;
