import GOT from "assets/sound/whatsapp.mp3";
import SENT from "assets/sound/message_sent.mp3";
import NOTIFY from "assets/sound/notification.mp3";

const audio = {
  GOT: new Audio(GOT),
  SENT: new Audio(SENT),
  NOTIFY: new Audio(NOTIFY),
};

const playAudio = (audioType: keyof typeof audio) => {
  if (audio[audioType].paused) {
    audio[audioType].play().catch((error) => console.error(error));
  }
};

export default playAudio;
