import engageApi from "api/engage";
import EmptyPage from "components/Empty";
import List from "components/lists/List";
import UserSkeletons from "components/theme/skeleton/users";
import constants from "configs/constants";
import dayjs from "dayjs";
import useAlert from "hooks/useAlert";
import useToast from "hooks/useToast";
import useEngage from "queries/engage";
import queryClient from "queries/queryClient";
import useUser from "queries/user";
import { useState } from "react";
import { GiDiamondRing } from "react-icons/gi";
import sort from "utils/sort";
import EngageListItem from "./components/EngageListItem";
import { EMPTY_FEMALE_CONTENT, EMPTY_MALE_CONTENT } from "./components/strings";

const EngagementsPage = () => {
  const { user } = useUser();
  const alert = useAlert();
  const toast = useToast();

  const engage = useEngage();

  const [pendingItem, setPendingItem] = useState("");
  const isMale = user.gender === "male";
  const isEngaged =
    engage.data && engage.data.find((e) => e.status == "accepted");

  const onResendEngage = async (id: string) => {
    setPendingItem(id);

    const { data: response, ok } = await engageApi.send(id);
    if (ok) {
      engage.send();
      toast.success(constants.TOAST.REQUEST_SENT);
    } else if (response && response.name) {
      if (response.name === "ENGAGED_USER")
        toast.error(constants.TOAST.GIRL_ENGAGED);
      else if (response.name === "ALREADY_SENT")
        toast.warn(constants.TOAST.PENDING_GIRL_ACCEPTING);
      else if (response.name === "BLOCKED_USER") toast.error(response.message!);
      else toast.error(constants.ERRORS.UNEXPECTED_ERROR);
    } else toast.error(constants.ERRORS.UNEXPECTED_ERROR);

    setPendingItem("");
  };

  const onAccept = async (id: string) => {
    setPendingItem(id);

    const response = await engageApi.accept(id);
    if (response.ok)
      engage.update({
        engageId: id,
        update: { status: "accepted" },
        updateUser: { status: "engaged" },
      });
    else toast.error(constants.ERRORS.UNEXPECTED_ERROR);

    setPendingItem("");
  };

  const onCancel = async (id: string) => {
    setPendingItem(id);

    const response = await engageApi.cancel(id);
    if (response.ok) {
      queryClient.invalidateQueries({ queryKey: ["chats"] });
      engage.update({
        engageId: id,
        update: { status: "canceled" },
        updateUser: { status: "accepted" },
      });
    } else toast.error(constants.ERRORS.UNEXPECTED_ERROR);

    setPendingItem("");
  };

  const onDelete = async (id: string) => {
    setPendingItem(id);

    await engageApi.remove(id);
    engage.remove(id);
    queryClient.invalidateQueries({ queryKey: ["chats"] });

    setPendingItem("");
  };

  function showAlert(message: string, action: () => void) {
    alert({
      title: constants.BAR_ENGAGE,
      content: message,
      onConfirm: action,
    });
  }

  const cancelAction = (id: string, status: string) => {
    const isCancel = "canceled" === status;
    return !isCancel && !isMale
      ? () => showAlert(constants.ALERT.CANCEL_ENGAGE, () => onCancel(id))
      : undefined;
  };

  const deleteAction = (id: string, status: string) => {
    const isCancel = "canceled" === status;
    return isMale || (!isMale && isCancel)
      ? () => showAlert(constants.ALERT.DELETE_ENGAGE, () => onDelete(id))
      : undefined;
  };

  const acceptAction = (id: string, status: string) => {
    const isCancel = "canceled" === status;
    const isAccept = "accepted" === status;

    return !isAccept && !isCancel && !isMale && !isEngaged
      ? () => showAlert(constants.ALERT.ACCEPT_ENGAGE, () => onAccept(id))
      : undefined;
  };

  const resendAgain = (id: string, status: string) => {
    const isCancel = "canceled" === status;

    return isCancel && isMale && user.status === "accepted"
      ? () => onResendEngage(id)
      : undefined;
  };

  if (engage.isError) throw new Error(engage.error?.message);
  return (
    <div>
      {engage.isLoading || pendingItem ? (
        <UserSkeletons />
      ) : !engage.data?.length ? (
        <div className="flex h-[85vh] items-center justify-center">
          <EmptyPage
            content={isMale ? EMPTY_MALE_CONTENT : EMPTY_FEMALE_CONTENT}
            Icon={GiDiamondRing}
          />
        </div>
      ) : (
        <List
          title={constants.ENGAGED_USERS}
          data={sort.withCreatedAtProp(engage.data)}
          keyExtractor={(item) => item.user.uid}
          renderItem={(item) => {
            const { name, profile_picture, _id, is_guardian } = item.user;
            const { status, createdAt, removed } = item;
            const { reason, from } = removed || {};

            return (
              <EngageListItem
                title={name}
                isMale={isMale}
                guardian={is_guardian}
                status={!reason && status === "canceled" ? "rejected" : status}
                reason={from === user.name && reason ? reason : undefined}
                image={profile_picture}
                subTitle={dayjs(createdAt).format("YYYY-MM-DD")}
                acceptAction={acceptAction(_id, status)}
                cancelAction={cancelAction(_id, status)}
                deleteAction={deleteAction(_id, status)}
                resendAction={resendAgain(_id, status)}
                to={`profile/${item.user.uid}`}
              />
            );
          }}
        />
      )}
    </div>
  );
};

export default EngagementsPage;
