export const ACCEPT_MESSAGE = (name: string) => (
  <span>
    لقد قام العضو
    <span className="text-primary">{` (${name}) `}</span>
    بارسال رغبته في خطبتك
    <br /> في حال موافقتك على الخطبة سوف تكون خدمة الرسائل متاحة بينكم فقط ولا
    يمكنكما التواصل مع الاخرين حتى يقوم احدكما بالغاء الخطبة
  </span>
);

export const CANCEL_MESSAGE = (reason: string) => (
  <span>
    {reason ? (
      <>
        تم الغاء الخطبة بناء على طلبك بسبب
        <span className="text-primary">{` (${reason}) `}</span>
        <br />
        نسال الله لكما التوفيق والسداد في خياراتكما الاخرى وأن يرزقكما الخير حيث
        كان
      </>
    ) : (
      <>
        تم الغاء التواصل بناء على طلب احد الطرفين <br /> نسال الله لكما التوفيق
        والسداد في خياراتكما الاخرى وأن يرزقكما الخير حيث كان
      </>
    )}
  </span>
);

export const REJECT_MESSAGE = (name: string) => (
  <span>
    لقد قام العضو
    <span className="text-primary">{` (${name}) `}</span>
    برفض طلب خطبة
    <br /> يمكنك البحث عن اخرى ويمكنك استخدام خاصية الاعضاء المطابقين والمناسبين
    داخل الموقع
    <br /> نسال الله ان يرزقك زوجة صالحة
  </span>
);

export const STATUS_VALUES = [
  { label: "pending", value: "في الإننظار", color: "bg-sky-400" },
  { label: "accepted", value: "خطبة مفعلة", color: "bg-green-300" },
  { label: "canceled", value: "خطبة ملغية", color: "bg-error" },
  { label: "rejected", value: "مرفوضة", color: "bg-error" },
  { label: "success", value: "خطبة ناجحة", color: "bg-success" },
];

export const EMPTY_FEMALE_CONTENT = (
  <span>
    هذه الخدمة مخصصة لاستقبال طلبات الخطبه من الاعضاء وتتيح للفتاه عند قبول طلب
    الخطبة الآتي
    <br />
    <br />
    ١- التواصل بشكل فوري ومباشر
    <br />
    ٢- إرسال بيانات ولي أمرك وفق الضوابط التي سوف تتلقينها من الموقع
    <br />
    ٣- إغلاق التواصل العام ويكون فقط بين المخطوبين لمنع اي ازعاج من الآخرين حتى
    يحدث التفاهم بينكما
    <br />
    ٥- يجب على الشاب الجاد أن يقوم بإرسال طلب خطبه اذا هناك توافق مبدئي بين
    الطرفين ٦- اظهار الصور الخاصة بين المخطوبين فقط
  </span>
);

export const EMPTY_MALE_CONTENT = (
  <span>
    هذه الخدمة مخصصة للتعامل مع طلبات الخطبة المرسلة للأعضاء وتتيح لك عند قبول
    الفتاة طلب الخطبة الآتي :
    <br />
    <br />
    ١- التواصل بشكل فوري ومباشر
    <br />
    ٢- استقبال بيانات ولي أمر الفتاة
    <br />
    ٣- إغلاق التواصل العام ليكون فقط بين المخطوبين لمنع اي ازعاج من الاخرين حتى
    يحدث التفاهم بينكما
    <br />
    ٤- إرسال طلب خطبه للفتاة يعني أنك جاد
    <br />
    ٥- اظهار الصور الخاصة بين المخطوبين فقط`
  </span>
);

export const ERROR_TEXT_FEMALE = `أدخلي كامل البيانات !`;

export const ERROR_TEXT_MALE = `أدخل كامل البيانات !`;

export const ENGAGE_NOTE_FEMALE = (
  <span>
    الان يمكنكما التواصل وتبادل الرسائل بطريقة مباشرة وغير خاضعة للمراقبة من قبل
    الكنترول ولكننا نذكرك انها مراقبة من فوق سبع سماوات.
    <br />
    <br />
    الأخت الكريمة ننصحك وأنت أختنا لا ترسلين رقمك الخاص أو وسيلة تواصل خارج
    الموقع ولا تسمحين الا بارسال بيانات ولي امرك بعد طلب الشاب البيانات وتحكمي
    في عواطفك فالحب الحقيقي يبدا بعد الزواج فحافظي على نفسك يأتيك رزقك وأنت
    جوهرة مصونة
    <br />
    <br />
    النقاش في هذه المرحلة من أجل تقريب وجهات النظر فقط، وفي حال اتفقتما على كل
    شيئ يمكنك ارسال بيانات ولي امرك في الخيارات المتاحة امام الاسم. نسال الله ان
    يرزقكما الخير ويوفق بينكما
  </span>
);

export const ENGAGE_NOTE_MALE = (
  <span>
    الان يمكنكما التواصل وتبادل الرسائل بطريقة مباشرة وغير خاضعة للمراقبة من قبل
    الكنترول ولكننا نذكرك انها مراقبة من فوق سبع سماوات.
    <br />
    <br />
    في حال اتفقتما على اتمام الخطبة بشكل رسمي يمكن للشاب الضغط على زر طلب
    البيانات لتقوم الفتاة بتعبئة الاستمارة وارسالها. نسال الله ان يرزقكما الخير
    وأن يوفق بينكما
  </span>
);

export const GUARDIAN_FORM_NOTE = (
  <span>
    اختي الكريمة
    <br />
    قبل ان ترسلي بيانتك نؤكد عليك الا تقعي في خطأ بعض البنات وترسلي رقمك، لانها
    مغامرة ليست في صالحك. يجب ان ترسلي رقم ولي امرك وتصرين على ذلك فان كان الرجل
    جاد فسوف يدخل البيت من بابه وان كان غير ذلك فما خسرتي شيئ. الان يرجى وضع
    البيانات التالية:
  </span>
);

export const MARRIED_NOTE = (
  <span>
    تم ارسال بيانات ولي امرك
    <br />
    <br />
    في حال تم الزواج بينكم لا تنسي مشاركتنا هذة التجربة من خلال الضغط على زر تم
    الزواج ادناه حتى نسجلها في سجل القصص الناجحة، كما نسال الله لك السعادة في
    الدنيا و الآخرة
  </span>
);

export const MARRIED_MALE_NOTE = (
  <span>
    اخي الكريم .. السلام عليكم ورحمة الله وبركاته
    <br />
    يرجى قراءة هذه الارشادات قبل التواصل مع الفتاة
    <br />
    <br />
    1- تذكر أنك اقسمت بالله أن تحافظ على أعراض المسلمين وأن لديك أخوات وبنات في
    مستقبل باذن الله.
    <br />
    2- هذه المعلومات خاصة وسرية ويجب حذفها
    <br />
    3- لايجب ان تذكر وسيلة التعارف لاهل الفتاة الا اذا اخبرتك هي بذلك حتى لا
    تسبب لها الحرج
    <br />
    4- يجب الحذر من تحويل اي مبالغ مالية للفتاة او لاي احد من طرفها قبل اتمام
    العقد بشكل رسمي ونحن نخلي مسؤليتنا القانونية.
    <br />
    5- نحن جهة توفيق فقط ولا نزكي طرف لطرف ولا نعرف جدية أيا منكما وصدقه. واخيرا
    لا تنسانا من دعائك ولاتنسى صلاة الاستخارة
  </span>
);

export const ASK_FOR_INFO = (name: string) => (
  <span>
    لقد قام العضو<span className="text-primary">{` ${name} `}</span>
    بطلب بياناتك حيث اتفقتما على طلب الخطبة <br /> يمكنك ارسال بيانات ولي امرك
    من خلال ملىء استمارة بعد الضغط على زر{" "}
    <span className="text-primary">(ارسال البيانات)</span> الموجود بالاسفل,
  </span>
);

export const YOUNG_INFO_NOTE = `قبل طلب بيانات الفتاة يرجى تسجيل البيانات التالية لتأكيد الجدية`;

export const SUCCESS_MARRIED = (
  <span>
    أخي الكريم / أختي الكريمة
    <br />
    بارك الله لكما وبارك عليكما وجمع بينكما في خير، نسال الله ان يبارك في بيتكم
    وأن يرزقكم طاعته
    <br />
    ولا تنسونا من صالح دعائكم ونشر الموقع حتى يستفيد منه اصدقائكم واخوانكم.
  </span>
);

export const IN_ENGAGE_FOOTER = (
  <div className=" text-center font-bold">
    في حالة الخطبة لايمكنك ارسال او استقبال رسائل من باقي الاعضاء، المراسلات
    قاصرة فقط بين المخطوبين.
  </div>
);
