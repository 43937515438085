import classNames from "classnames";
import constants from "configs/constants";
import { useState } from "react";
import { PhoneInput as PInput } from "react-international-phone";
import "./index.css";

interface Props {
  placeholder?: string;
  value?: string;
  setValue?: (v: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  containerClassName?: string;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}

const PhoneInput = ({
  placeholder = "",
  value,
  setValue,
  onBlur,
  onFocus,
  containerClassName,
  error,
  errorMessage,
  disabled,
}: Props) => {
  const [isRTL, setIsRTL] = useState(true);

  return (
    <>
      <div
        dir={isRTL ? "rtl" : "ltr"}
        className={classNames({
          "w-full  rounded-lg border-[0.1rem] border-gray-300 p-2 py-1 outline-none placeholder:text-gray-400":
            true,
          "border-error": Boolean(error),
          "!bg-gray-100": Boolean(disabled),
          [`${containerClassName}`]: Boolean(containerClassName),
        })}
      >
        <PInput
          hideDropdown={disabled}
          disabled={disabled}
          countrySelectorStyleProps={{
            flagClassName: disabled ? "!hidden" : "",
          }}
          inputProps={{
            onBlur: () => {
              if (onBlur) onBlur();
              if (!isRTL) setIsRTL(true);
            },
            onFocus: (e) => {
              if (onFocus) onFocus();
              if (isRTL) setIsRTL(false);
              setTimeout(() => {
                e.target.setSelectionRange(
                  e.target.value.length,
                  e.target.value.length,
                );
              }, 50);
            },
            className: "!text-sm !outline-none !w-[80%]",
            style: {
              direction: "ltr",
              textAlign: isRTL ? "right" : "left",
            },
          }}
          placeholder={placeholder}
          value={value?.trim()}
          onChange={setValue}
          defaultCountry="sa"
          disableDialCodeAndPrefix={disabled ? undefined : true}
          showDisabledDialCodeAndPrefix={disabled ? undefined : !isRTL}
          charAfterDialCode={disabled ? "" : undefined}
          dialCodePreviewStyleProps={{
            className: "!font-arabic !m-2 !border-0 !border-r-2 !text-sm",
            style: {
              direction: "ltr",
              textAlign: isRTL ? "right" : "left",
            },
          }}
          preferredCountries={constants.ARABIC_COUNTRY_CODE}
        />
      </div>
      {errorMessage && (
        <span className={`mr-2 text-sm text-error`}>{errorMessage}</span>
      )}
    </>
  );
};

export default PhoneInput;
