import engageApi from "api/engage";
import Button from "components/core/Button";
import CheckBox from "components/core/CheckBox";
import constants from "configs/constants";
import useUser from "queries/user";
import { useState } from "react";
import TEngagement from "types/TEngagement";

interface Props {
  visible: boolean;
  engage?: TEngagement;
  onSwear: (id: string) => void;
}

const SWEAR = {
  title: "أنتما الآن في حالة تواصل مفتوح من أجل التفاهم حول الخطبة",
  subtitle: "يجب عليكم الموافقة على القسم أولا",
  swears: (gender: string) => [
    "أقسم بالله أن هدفي الزواج وأن تواصلنا هو للتفاهم حول هذا الامر فقط",
    gender === "male"
      ? "أقسم بالله أن أحافظ على سرية البيانات وأن أتواصل مع ولي المرأة حال اتفقنا على إتمام الخطبة"
      : "أقسم بالله أن أرسل بيانات ولي أمري حال اتفقنا على إتمام الخطبة ووجدته مناسبا لي",
  ],
};

const Swear = ({ visible, engage, onSwear }: Props) => {
  const { user } = useUser();

  const [loading, setLoading] = useState(false);
  const [swearApproved, setSwearApproved] = useState({
    one: { error: false, value: false },
    tow: { error: false, value: false },
  });

  const handelOnSwear = async () => {
    if (!swearApproved.one.value && !swearApproved.tow.value) {
      return setSwearApproved({
        one: { error: true, value: false },
        tow: { error: true, value: false },
      });
    } else if (!swearApproved.one.value) {
      return setSwearApproved((prevState) => ({
        ...prevState,
        one: { error: true, value: false },
      }));
    } else if (!swearApproved.tow.value) {
      return setSwearApproved((prevState) => ({
        ...prevState,
        tow: { error: true, value: false },
      }));
    }

    setLoading(true);

    const target = engage?.user?._id || "";
    const result = await engageApi.swear(target);
    if (result.ok) onSwear(target);
    else console.log(constants.ERRORS.UNEXPECTED_ERROR);

    setLoading(false);
  };

  if (!visible) return null;
  return (
    <dialog
      id="show-modal"
      className={`modal modal-middle ${visible && "modal-open"}`}
    >
      <div className="modal-box flex w-[80%] flex-col  md:w-96">
        <span className="label-text mb-4 text-center text-lg">
          {SWEAR.title}
        </span>
        <span className="label-text mb-4 text-center text-base text-primary">
          {SWEAR.subtitle}
        </span>
        <CheckBox
          labelClassName="text-right"
          title={SWEAR.swears(user.gender)[0]}
          checked={swearApproved.one.value}
          error={swearApproved.one.error}
          toggle={() =>
            setSwearApproved((e) => ({
              ...e,
              one: { error: false, value: !e.one.value },
            }))
          }
        />

        <CheckBox
          labelClassName="text-right"
          title={SWEAR.swears(user.gender)[1]}
          checked={swearApproved.tow.value}
          error={swearApproved.tow.error}
          toggle={() =>
            setSwearApproved((e) => ({
              ...e,
              tow: { error: false, value: !e.tow.value },
            }))
          }
        />

        <Button
          loading={loading}
          onClick={handelOnSwear}
          color="btn-accent"
          className="mt-5"
        >
          {constants.I_SWEAR}
        </Button>
      </div>
    </dialog>
  );
};

export default Swear;
