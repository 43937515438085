import SelectPicker from "components/picker/Select";
import constants from "configs/constants";
import useStorage from "hooks/useStorage";
import { useState } from "react";
import { Type as PrefType } from "../../preferencesProps";

interface Props {
  prefs: PrefType;
  setPrefs: (v: PrefType) => void;
  errors: string[];
  setErrors: (v: string[]) => void;
}

const StepOne = ({ prefs, setPrefs, errors, setErrors }: Props) => {
  const { options, countries } = useStorage();

  const shapeOptions = (array: Array<string>) => {
    return [constants.NOT_REQUIRE, ...array];
  };

  const validate = (propName: string) => {
    if (errors.includes(propName))
      setErrors(errors.filter((e) => e !== propName));
  };

  const { city, clan, origin, nationality, country } = prefs;
  const [citiesList, SetCitiesList] = useState(
    countries.find((e) => country?.includes(e.label))?.cities || [],
  );

  const OpGender = prefs.gender === "male" ? "female" : "male";
  const nationality_options = options.nationality_options[OpGender];
  const clan_options = options.clan_options[OpGender];
  const origin_options = options.origin_options[OpGender];

  return (
    <div className="px-4 py-2">
      <div className="mt-5 flex w-full max-md:mb-10 max-md:mt-5 max-md:flex-col max-md:items-center">
        <div className="flex w-1/2 flex-col gap-5 max-md:max-w-[85%]">
          <SelectPicker
            withSearch
            multiple
            options={shapeOptions(nationality_options)}
            label={constants.NATIONALITY}
            value={nationality!}
            error={errors.includes("nationality")}
            setValue={(v) => {
              validate("nationality");
              setPrefs({ ...prefs, nationality: v as string[] });
            }}
          />

          <SelectPicker
            withSearch
            multiple
            options={shapeOptions(countries.map((e) => e.label))}
            label={constants.COUNTRY}
            value={country!}
            error={errors.includes("country")}
            setValue={(v) => {
              const value = v as string[];
              validate("country");
              if (v.length === 1 && v[0] === constants.NOT_REQUIRE) {
                setPrefs({ ...prefs, country: value, city: value });
                SetCitiesList([]);
              } else {
                const cities = value
                  .map((c) => countries.find((e) => c === e.label)!.cities)
                  .flat();
                SetCitiesList(cities);
                setPrefs({ ...prefs, country: value });
              }
            }}
          />

          <SelectPicker
            withSearch
            multiple
            options={shapeOptions(citiesList)}
            label={constants.CITY}
            value={city!}
            error={errors.includes("city")}
            setValue={(v) => {
              validate("city");
              setPrefs({ ...prefs, city: v as string[] });
            }}
          />
        </div>

        <div className="divider divider-horizontal mx-10 mt-5 max-md:hidden"></div>

        <div className="flex w-1/2 flex-col gap-5 max-md:mt-5 max-md:w-[85%]">
          <SelectPicker
            multiple
            options={shapeOptions([clan_options[0], clan_options[1]])}
            label={constants.CLAN}
            value={clan!}
            error={errors.includes("clan")}
            setValue={(v) => {
              validate("clan");
              setPrefs({ ...prefs, clan: v as string[] });
            }}
          />

          <SelectPicker
            multiple
            options={shapeOptions(origin_options)}
            label={constants.ORIGIN}
            value={origin!}
            error={errors.includes("origin")}
            setValue={(v) => {
              validate("origin");
              setPrefs({ ...prefs, origin: v as string[] });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StepOne;
