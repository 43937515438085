import logo from "assets/logo.svg";
import addNotification from "react-push-notification";

import playAudio from "./audio";

interface Props {
  title: string;
  message: string;
  sound?: "GOT" | "SENT" | "NOTIFY";
}

const notify = ({ title, message, sound = "GOT" }: Props) => {
  playAudio(sound);
  addNotification({ title, message, icon: logo, silent: true, native: true });
};

export default notify;
