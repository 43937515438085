import { FC } from "react";
import {
  Control,
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
} from "react-hook-form";

import CoreInput from "../core/Input";
import TextArea from "../core/TextArea";
import PhoneInput from "../core/phone/Input";
import DatePicker from "components/picker/DatePicker";

interface Props {
  visible?: boolean;
  alphabetic?: boolean;
  containerClassName?: string;
  control: Control<any> | undefined;
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  Icon?: FC<{ className?: string }>;
  inputClassName?: string;
  name: string;
  placeholder?: string;
  textAreaClassName?: string;
  type?: "tel" | "number" | "password" | "email" | "text" | "textArea" | "date";
  maxLength?: number;
  [x: string]: any;
}

const FormFiled = ({
  visible = true,
  alphabetic,
  containerClassName,
  control,
  error,
  Icon,
  inputClassName,
  name,
  placeholder,
  textAreaClassName,
  type = "text",
  ...rest
}: Props) => {
  const Input =
    type === "tel"
      ? PhoneInput
      : type === "textArea"
      ? TextArea
      : type === "date"
      ? DatePicker
      : CoreInput;

  if (!visible) return null;
  return (
    <Controller
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <Input
          alphabetic={alphabetic}
          containerClassName={containerClassName}
          error={!!error}
          errorMessage={error?.message as string}
          Icon={Icon}
          inputClassName={inputClassName}
          onBlur={onBlur}
          placeholder={placeholder}
          setValue={onChange}
          className={textAreaClassName}
          type={type}
          value={value}
          {...rest}
        />
      )}
      name={name}
    />
  );
};

export default FormFiled;
