import classNames from "classnames";
import dayjs from "dayjs";
import useIsMobile from "hooks/useIsMobile";
import { FC, useState } from "react";
import { MdDateRange } from "react-icons/md";
import DPicker, { DateValueType } from "react-tailwindcss-datepicker";
import { mm_dd_yyyy } from "utils/time";

import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

interface Props {
  placeholder?: string;
  value?: string | null;
  setValue?: (v: string | null) => void;
  inputClassName?: string;
  containerClassName?: string;
  error?: boolean;
  errorMessage?: string;
  size?: "input-xs" | "input-sm" | "input-md" | "input-lg";
  Icon?: FC<{ className?: string }>;
}

const DatePicker = ({
  placeholder = "",
  value,
  setValue,
  inputClassName,
  containerClassName,
  error,
  size = "input-md",
  errorMessage,
}: Props) => {
  const [mobileDate, setMobileDate] = useState(
    dayjs(value || Date.now(), "MM-DD-YYYY").format("YYYY-MM-DD"),
  );
  const isMobile = useIsMobile();
  const date: DateValueType = {
    startDate: value ? dayjs(value).toDate() : null,
    endDate: value ? dayjs(value).toDate() : null,
  };

  return (
    <>
      {isMobile ? (
        <div
          className={`mb-0 flex w-full flex-row items-center overflow-hidden rounded-lg border-[0.1rem] px-0 py-5 focus:border-gray-500 focus:outline-none ${size} 
        ${error ? "border-error" : "border-gray-300"} ${containerClassName}`}
        >
          <MdDateRange className="mx-2 text-xl text-gray-500" />
          <input
            type="date"
            placeholder={placeholder}
            className={`mx-2 flex-1 bg-white !text-left focus:outline-none ${size} ${inputClassName}`}
            value={mobileDate}
            onChange={(e) => {
              const v = e.target.value;
              setValue!(dayjs(v, "YYYY-MM-DD").format("MM-DD-YYYY"));
              setMobileDate(v);
            }}
          />
        </div>
      ) : (
        <div dir="ltr">
          <DPicker
            asSingle
            i18n="ar"
            placeholder={placeholder}
            primaryColor="fuchsia"
            popoverDirection="down"
            useRange={false}
            value={date}
            onChange={(v) => {
              setValue!(
                v?.startDate ? mm_dd_yyyy(v?.startDate.toString()) : null,
              );
            }}
            inputClassName={classNames({
              "mb-0 px-0 focus:outline-none text-right relative border-[0.1rem] transition-all duration-300 py-2.5 pl-4 pr-14 w-full rounded-lg tracking-wide text-sm placeholder-gray-400 bg-white disabled:opacity-40 disabled:cursor-not-allowed":
                true,
              "border-error": !!error,
              "border-gray-300": !error,
              [`${size}`]: !!size,
              [`${containerClassName}`]: !!containerClassName,
              [`${inputClassName}`]: !!inputClassName,
            })}
          />
        </div>
      )}
      {errorMessage && (
        <span className={`mr-2 text-sm text-error`}>{errorMessage}</span>
      )}
    </>
  );
};

export default DatePicker;
