import constants from "configs/constants";
import { forwardRef, useImperativeHandle, useState } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";

interface Props {
  reSendOtpAgin: () => void;
}

const Counter = forwardRef(({ reSendOtpAgin }: Props, ref) => {
  const [showTimer, setShowTimer] = useState(false);

  useImperativeHandle(ref, () => ({
    setShowTimer,
  }));

  return (
    <div className="mb-5 flex w-full justify-center">
      <p>
        {constants.OTP_NOT_SEND}
        <span
          onClick={reSendOtpAgin}
          className={`link-primary link px-1 pr-2  ${
            showTimer
              ? "link-neutral btn-disabled text-neutral"
              : "link-primary text-primary"
          }`}
        >
          {constants.RESEND}
        </span>
        {showTimer && (
          <span className="text-neutral">
            <Countdown
              autoStart
              date={Date.now() + 120 * 1000}
              renderer={({
                minutes,
                seconds,
                completed,
              }: CountdownRenderProps) => {
                if (completed) {
                  setShowTimer(false);
                  return null;
                } else {
                  return (
                    <span>{`(${minutes > 9 ? minutes : `0${minutes}`}:${
                      seconds > 9 ? seconds : `0${seconds}`
                    })`}</span>
                  );
                }
              }}
            />
          </span>
        )}
      </p>
    </div>
  );
});

export default Counter;
