import { useQuery } from "@tanstack/react-query";
import guardian from "api/guardians";
import ms from "ms";
import queryClient from "./queryClient";

export const refreshGuardiansQuery = () =>
  queryClient.invalidateQueries({ queryKey: ["guardians"] });

const useGuardians = () => {
  const query = useQuery({
    queryKey: ["guardians"],
    staleTime: ms("2h"),
    queryFn: () => guardian.getUsers().then((res) => res.data),
  });

  return {
    users: query.data!,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export default useGuardians;
