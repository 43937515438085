import apiConfig from "configs/api-v2";
import UserItem from "types/UserItem";
import apiClient from "./client-v2";

const endpoints = apiConfig.endpoints;

const get = () => apiClient.get<UserItem[]>(endpoints.blocks);
const add = (id: string) => apiClient.post(`${endpoints.blocks}/${id}`);
const remove = (id: string) => apiClient.delete(`${endpoints.blocks}/${id}`);
const isBlockedMe = (id: string) =>
  apiClient.get<{ who: boolean; me: boolean }>(
    `${endpoints.is_blocked_me}/${id}`,
  );

export default {
  get,
  isBlockedMe,
  add,
  remove,
};
