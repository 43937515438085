import { FC, ReactNode } from "react";

interface Props {
  content: string | ReactNode;
  Icon: FC<{ className?: string }>;
}

const EmptyPage = ({ content, Icon }: Props) => {
  return (
    <div className="no-scrollbar flex h-full items-center justify-center overflow-auto">
      <article className="prose  text-center">
        <Icon className="m-auto self-center text-center text-8xl" />
        <p>{content}</p>
      </article>
    </div>
  );
};

export default EmptyPage;
