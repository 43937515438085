import constants from "configs/constants";
import { useState } from "react";
import { BiSend } from "react-icons/bi";

interface Props {
  visible: boolean;
  close: () => void;
  onClick: (r: string) => void;
}

const EngageCancel = ({ visible, close, onClick }: Props) => {
  const [reason, setReason] = useState("");
  const [error, setError] = useState(false);

  const onSubmit = () => {
    setError(false);

    if (!reason) return setError(true);
    close();
    onClick(reason);
  };

  return (
    <dialog
      id="show-modal"
      className={`modal modal-middle ${visible && "modal-open"}`}
    >
      <div className="modal-box flex w-[80%] flex-col  pt-10 md:w-96">
        <form method="dialog">
          <button
            onClick={() => close()}
            className="btn btn-circle btn-ghost btn-sm absolute right-1 top-1"
          >
            ✕
          </button>
        </form>
        <span className="label-text mb-4">{constants.REMOVE_REASON}</span>
        <textarea
          onFocus={() => {
            if (!reason && error) setError(false);
          }}
          onChange={(e) => setReason(e.target.value)}
          className={`textarea h-40 focus:outline-none ${
            !error ? "textarea-primary" : "textarea-error"
          }`}
        ></textarea>

        <button
          onClick={onSubmit}
          className="btn btn-circle btn-primary mt-8 self-center"
        >
          <BiSend className="text-xl text-white" />
        </button>
      </div>
    </dialog>
  );
};

export default EngageCancel;
