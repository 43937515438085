import constants from "configs/constants";
import props, { Type as PrefType } from "./preferencesProps";

interface Props {
  preferences: PrefType;
  action: "create" | "update";
  initData?: PrefType;
}

export default ({ action, initData, preferences }: Props) => {
  type Key = keyof PrefType;

  const setRange = (from?: string, to?: string) => {
    const fromNum = parseInt(from!);
    const toNum = parseInt(to!);
    if (isNaN(fromNum) && isNaN(toNum)) return;
    if (isNaN(fromNum)) return `${toNum}_${toNum}`;
    if (isNaN(toNum)) return `${fromNum}_${fromNum}`;
    return fromNum <= toNum ? `${fromNum}_${toNum}` : `${toNum}_${fromNum}`;
  };

  const updatePreference = (prefKey: Key, notRequireCheck: boolean) => {
    if (notRequireCheck) {
      (preferences as any)[prefKey] = constants.NOT_REQUIRE;
    } else {
      (preferences as any)[prefKey] = setRange(
        preferences[`${prefKey}From` as Key] as string,
        preferences[`${prefKey}To` as Key] as string,
      );
    }
  };

  updatePreference(
    "age",
    [preferences.ageTo, preferences.ageFrom].includes(constants.NOT_REQUIRE),
  );

  updatePreference(
    "weight",
    [preferences.weightTo, preferences.weightFrom].includes(
      constants.NOT_REQUIRE,
    ),
  );

  updatePreference(
    "height",
    [preferences.heightTo, preferences.heightFrom].includes(
      constants.NOT_REQUIRE,
    ),
  );

  // Remove unnecessary properties
  Object.keys(preferences).forEach((key) => {
    if (props.unnecessary.includes(key as Key)) {
      delete (preferences as any)[key];
    }
  });

  // Process remaining preferences
  for (const key in preferences) {
    const value = preferences[key as Key];
    const previous = initData ? initData[key as Key] : [];

    const isSameAsPrevious =
      (!previous && value === constants.NOT_REQUIRE) ||
      (typeof value === "string" && value === previous) ||
      (Array.isArray(value) && value.every((e) => previous!.includes(e)));

    const shouldSetToNull =
      value === constants.NOT_REQUIRE ||
      (Array.isArray(value) && value.includes(constants.NOT_REQUIRE)) ||
      (Array.isArray(value) && value.length === 0);

    if (isSameAsPrevious) delete (preferences as any)[key];
    else if (shouldSetToNull) {
      if (action === "create") delete (preferences as any)[key];
      else (preferences as any)[key] = null;
    }
  }

  for (const key in preferences) {
    if (preferences[key as Key] === undefined) delete (preferences as any)[key];
  }

  return preferences;
};
