import List from "components/lists/List";

const ProfileSkeleton = () => {
  return (
    <div>
      <div className="mx-auto mb-20 mt-5 flex w-[90%] animate-pulse flex-col items-center  justify-center">
        <div className="flex-shrink-0">
          <span className="ml-2 mt-1 block h-40 w-40 rounded-full bg-base-300"></span>
        </div>
      </div>
      <List
        wrapped
        horizontal
        data={[0, 0, 0, 0, 0, 0, 0, 0]}
        keyExtractor={(_, index) => index.toString()}
        renderItem={() => {
          return (
            <div className="mx-auto my-3 flex h-40 w-[8rem] animate-pulse flex-col items-center overflow-hidden rounded-3xl bg-base-300 p-1 md:w-[10rem]">
              <div className="flex-shrink-0">
                <span className="ml-2 mt-1 block h-16 w-16 rounded-full bg-base-300"></span>
              </div>

              <div className="ml-4 mt-1 w-full">
                <h3 className="mb-2 h-4 w-[40%] rounded-md bg-base-300"></h3>
                <h3 className="h-10 rounded-md bg-base-300"></h3>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default ProfileSkeleton;
