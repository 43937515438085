import TextArea from "components/core/TextArea";
import constants from "configs/constants";
import { Type as PrefType } from "../../preferencesProps";

interface Props {
  prefs: PrefType;
  setPrefs: (v: PrefType) => void;
  errors: string[];
  uploadError: boolean | string;
  setErrors: (v: string[]) => void;
}

const StepFour = ({
  prefs,
  setPrefs,
  errors,
  uploadError,
  setErrors,
}: Props) => {
  const isPending = !!prefs.pending_description;

  const validate = (propName: string) => {
    if (errors.includes(propName))
      setErrors(errors.filter((e) => e !== propName));
  };

  return (
    <div className="pt-14 md:pr-5">
      <p className="mb-2 text-lg font-bold">
        {constants.PARTNER_DES}
        {isPending && (
          <span className="font-bold text-warning">
            {` (${constants.PENDING_DESCRIPTION})`}
          </span>
        )}
      </p>
      <TextArea
        value={prefs.description}
        className="h-52 w-full md:w-[70%]"
        placeholder={constants.PARTNER_DES}
        error={errors.includes("description")}
        setValue={(text) => {
          validate("description");
          setPrefs({ ...prefs, description: text });
        }}
        errorMessage={
          uploadError ? constants.ERRORS.UNEXPECTED_ERROR : undefined
        }
      />
    </div>
  );
};

export default StepFour;
