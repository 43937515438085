import Button from "components/core/Button";
import Input from "components/core/Input";
import SelectPicker from "components/picker/Select";
import constants from "configs/constants";
import useStorage from "hooks/useStorage";
import props, { PropsType } from "pages/search/props";
import { useRef, useState } from "react";
import { RxReload } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

import girl from "assets/girl.png";
import young from "assets/young.png";
import { AiOutlineEnter } from "react-icons/ai";
import useEnterKey from "hooks/useEnterKey";

const genders = [
  {
    id: "1",
    src: young,
    label: constants.YOUNG,
    gender: "female",
  },
  {
    id: "2",
    src: girl,
    label: constants.GIRL,
    gender: "male",
  },
];

const BasicGuestSearch = () => {
  const { options, countries } = useStorage();
  const navigate = useNavigate();

  const stringPersist = localStorage.getItem("landing-basic-search-props");
  const persistedProps = stringPersist ? JSON.parse(stringPersist) : {};
  const initData = { ...props, ...persistedProps };

  const [errors, setErrors] = useState<string[]>([]);
  const [data, setData] = useState<PropsType>(initData);
  const [gender, setGender] = useState(persistedProps.gender || "male");
  const username = useRef(initData.name || "");

  const opGender = gender === "male" ? "female" : "male";

  const relationship_options = options.relationship_status_options[opGender];
  const occupations_options = options.occupations_options[opGender];
  const beauty_options = options.beauty_options[opGender];
  const clan_options = options.clan_options[opGender];

  const shapeOptions = (array: Array<string>) => {
    return array;
  };

  const countrySelected = (v: string[]) => {
    setData({ ...data, country: v });
    validate("country");
  };

  const validate = (propName: string) => {
    if (errors.includes(propName))
      setErrors(errors.filter((e) => e !== propName));
  };

  const onSubmit = async () => {
    const values = { ...data };

    if (username.current) values.name = username.current.trim();
    else if (values.name) delete values.name;

    localStorage.setItem(
      "landing-basic-search-props",
      JSON.stringify({ ...values, gender }),
    );

    delete values.ageFrom;
    delete values.ageTo;
    delete values.weightFrom;
    delete values.weightTo;
    delete values.heightFrom;
    delete values.heightTo;

    type Key = keyof typeof values;
    for (const key in values) {
      if (!values[key as Key] || values[key as Key] === constants.NOT_REQUIRE)
        delete values[key as Key];
    }

    navigate("/guest/search/result?page=1", {
      state: { ...values, gender: opGender },
    });
  };

  const onReload = () => {
    localStorage.setItem("landing-basic-search-props", "");
    setData(props);
  };

  const changeGender = (gender: string) => {
    setGender(gender);
    setData(props);
    localStorage.setItem("landing-basic-search-props", "");
  };

  const UsernameInput = () => {
    const [name, setName] = useState(initData.name || "");
    return (
      <Input
        size="input-sm"
        containerClassName="h-10"
        value={name}
        setValue={(v) => {
          setName(v);
          username.current = v;
        }}
        placeholder={constants.SEARCH_BY_NAME}
      />
    );
  };

  useEnterKey(onSubmit);

  return (
    <div className="px-4">
      <div className="relative my-5 flex w-full items-center justify-between">
        <p className="justify-start text-xs font-bold text-info">
          اضغط <AiOutlineEnter className="inline text-sm" /> للبحث
        </p>
        <div className="absolute -top-5 left-0 flex flex-col items-center justify-center gap-y-1">
          <RxReload
            onClick={onReload}
            className="btn btn-circle btn-sm p-1 text-accent"
          />
          <span className="text-xs">{constants.RESET}</span>
        </div>
      </div>

      <div className="join flex flex-col items-center justify-center">
        <p className="mb-3 text-xl font-bold">{"البحث عن"}</p>
        <div className="flex">
          {genders.map((item) => {
            const isSelected = item.gender === gender;
            return (
              <div
                key={item.id}
                onClick={() => changeGender(item.gender)}
                className={`mx-3 flex flex-col overflow-hidden rounded-xl bg-base-200 hover:bg-primary ${
                  isSelected && "bg-primary"
                }`}
              >
                <img
                  src={item.src}
                  className={`btn join-item border-0 bg-transparent hover:bg-primary`}
                />
                <span
                  className={`mt-1 cursor-pointer text-center text-xs ${
                    isSelected && "text-white"
                  }`}
                >
                  {item.label}
                </span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="mb-22 mt-10 flex w-full max-md:flex-col max-md:items-center md:mb-5">
        <div className="flex w-1/2 flex-col gap-5 max-md:w-[85%]">
          <UsernameInput />

          <SelectPicker
            withSearch
            options={countries.map((e) => e.label)}
            label={constants.COUNTRY}
            value={data.country!}
            error={errors.includes("country")}
            setValue={(v) => countrySelected(v as string[])}
          />

          <SelectPicker
            options={shapeOptions([clan_options[0], clan_options[1]])}
            label={constants.CLAN}
            value={data.clan!}
            error={errors.includes("clan")}
            setValue={(v) => {
              validate("clan");
              setData({ ...data, clan: v as string[] });
            }}
          />
        </div>

        <div className="divider divider-horizontal mx-10 mt-5 max-md:hidden"></div>

        <div className="flex w-1/2 flex-col gap-5 max-md:mt-5 max-md:w-[85%]">
          <SelectPicker
            options={relationship_options}
            label={constants.RELATIONSHIP_STATUS}
            value={data.relationship_status!}
            error={errors.includes("relationship_status")}
            setValue={(v) => {
              validate("relationship_status");
              setData({ ...data, relationship_status: v as string[] });
            }}
          />

          <SelectPicker
            options={shapeOptions(beauty_options)}
            label={constants.BEAUTY}
            value={data.beauty!}
            error={errors.includes("beauty")}
            setValue={(v) => {
              validate("beauty");
              setData({ ...data, beauty: v as string[] });
            }}
          />

          <SelectPicker
            options={occupations_options}
            label={constants.OCCUPATION}
            value={data.occupation!}
            error={errors.includes("occupation")}
            setValue={(v) => {
              validate("occupation");
              setData({ ...data, occupation: v as string[] });
            }}
          />
        </div>

        <div className="my-5 mt-10 flex w-full justify-center md:hidden">
          <Button
            outline
            color="btn-primary"
            className="!px-20"
            onClick={onSubmit}
          >
            {constants.SEARCH}
          </Button>
        </div>
      </div>
      <div className="my-5 mt-10 hidden w-full justify-center md:flex">
        <Button
          outline
          color="btn-primary"
          className="!px-20"
          onClick={onSubmit}
        >
          {constants.SEARCH}
        </Button>
      </div>
    </div>
  );
};

export default BasicGuestSearch;
