import constants from "configs/constants";
import { useEffect, useRef } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

const routes = [
  { title: constants.BASIC_SEARCH, path: "/guest/search" },
  { title: constants.ADVANCE_SEARCH, path: "/guest/search/advanced" },
];

const GuestSearchPage = () => {
  const { pathname } = useLocation();
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    containerRef.current?.parentElement?.scrollIntoView({
      behavior: "instant",
    });
  }, []);

  return (
    <div ref={containerRef} className="mx-auto max-w-5xl">
      <div className=" flex items-center justify-center">
        <div className="tabs-xs sm:tabs-sm md:tabs-md tabs-boxed w-fit gap-x-5">
          {routes.map((e, i) => {
            return (
              <Link
                key={i.toString()}
                to={e.path}
                className={`tab ${
                  pathname === e.path && "tab-active font-semibold !text-white"
                }`}
              >
                {e.title}
              </Link>
            );
          })}
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default GuestSearchPage;
