import StepProgressBar from "components/StepProgressBar";
import constants from "configs/constants";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import TProfile from "types/TProfile";
import TProps from "../utils/TProps";

import StepFour from "../utils/steps/profile/Four";
import StepOne from "../utils/steps/profile/One";
import StepThree from "../utils/steps/profile/Three";
import StepTow from "../utils/steps/profile/Tow";

import profiles from "api/profiles";
import { UpdateProfile } from "api/types/users";
import Loading from "components/theme/Loading";
import useToast from "hooks/useToast";
import useUser from "queries/user";
import props from "../utils/profileProps";

function EditProfile() {
  const { user, setUser } = useUser();
  const navigate = useNavigate();

  const profile: TProfile = user.profile || {};

  const currentData: TProps = {
    ...profile,
    gender: user.gender,
    bio: profile.bio || profile.pending_bio,
    children: props.children.find((e) => e.value === profile.children),
    height: { value: profile.height, label: profile.height + " سم" },
    weight: { value: profile.weight, label: profile.weight + " كغ" },
  };

  const [userData, setUserData] = useState(currentData);
  const [uploadError, setUploadError] = useState<string | boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const onSubmit = async () => {
    setLoading(true);
    setUploadError(false);

    // Clone userData into data
    const data = { ...userData };

    // Loop through keys of currentData and update data
    Object.keys(currentData).forEach((key) => {
      const currentDataValue = currentData[key as keyof typeof userData];
      const dataValue = data[key as keyof typeof userData];

      // Check if the key exists in userData
      if (dataValue !== undefined) {
        if (
          typeof currentDataValue == "object" &&
          typeof dataValue == "object" &&
          currentDataValue?.value === dataValue?.value
        ) {
          delete data[key as keyof typeof userData];
        } else if (currentDataValue === dataValue) {
          delete data[key as keyof typeof userData];
        }
      }
    });

    // Modify specific properties
    if (data.height && typeof data.height !== "number")
      data.height = data.height.value;
    if (data.weight && typeof data.weight !== "number")
      data.weight = data.weight.value;
    if (data.children && typeof data.children !== "number")
      data.children = data.children.value;

    if (Object.keys(data).length) {
      const isBioUpdated = !!data.bio;
      const update = await profiles.update(data as UpdateProfile);
      if (isBioUpdated) {
        data.pending_bio = data.bio;
        delete data.bio;
      }

      if (update.ok) {
        setUser({ profile: { ...user.profile, ...data } as UpdateProfile });
        toast.success(
          isBioUpdated
            ? constants.TOAST.PENDING_RESPONSE
            : constants.TOAST.UPDATE_SUCCESSFUL,
        );
        navigate("/");
      } else setUploadError(true);
    }

    setLoading(false);
  };

  const nextStep = async (step: number) => {
    const thereIsError = props
      .step(step)
      .filter((e) => !["age", "profile_picture"].includes(e))
      .filter((e) => !userData[e as keyof typeof userData]);

    if (step === 4 && !thereIsError.length) return onSubmit();

    if (!thereIsError.length) setCurrentStep(step);
    setErrorMessages(thereIsError);
  };

  return (
    <div className="h-[100%] overflow-hidden pl-[2px]">
      {loading && (
        <div className="relative">
          <Loading visible />
        </div>
      )}
      <StepProgressBar
        next={() => nextStep(currentStep + 1)}
        previous={() => setCurrentStep((s) => s - 1)}
        className="!h-full pb-40 md:pb-16"
        currentStep={currentStep}
        steps={[
          {
            label: constants.BIRTH_AFFILIATION,
            content: (
              <StepOne
                withoutAge
                user={userData}
                setUser={setUserData}
                errors={errorMessages}
                setErrors={setErrorMessages}
              />
            ),
          },
          {
            label: constants.STATUS,
            content: (
              <StepTow
                user={userData}
                setUser={setUserData}
                errors={errorMessages}
                setErrors={setErrorMessages}
              />
            ),
          },
          {
            label: constants.SEMBLANCE,
            content: (
              <StepThree
                withoutPicture
                user={userData}
                setUser={setUserData}
                errors={errorMessages}
                setErrors={setErrorMessages}
              />
            ),
          },
          {
            label: constants.BIO,
            content: (
              <StepFour
                user={userData}
                setUser={setUserData}
                errors={errorMessages}
                uploadError={uploadError}
                setErrors={setErrorMessages}
              />
            ),
          },
        ]}
      />
    </div>
  );
}

export default EditProfile;
