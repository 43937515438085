import Avatar from "components/Avatar";
import { GiDiamondRing } from "react-icons/gi";
import useUtils from "stores/utils";
import UserItem from "types/UserItem";

interface Props {
  sender: UserItem;
  receiver: UserItem;
  youngerMessage: string;
  girlMessage: string;
}

const StoryListItem = ({
  sender,
  receiver,
  youngerMessage,
  girlMessage,
}: Props) => {
  const theme = useUtils((s) => s.theme);
  const lightStyle = theme !== "dark" && `bg-transparent border-gray-400`;

  return (
    <div
      className={`mx-4 my-3 rounded-3xl border-2 bg-base-300  ${lightStyle}`}
    >
      <details className="collapse collapse-arrow">
        <summary className="collapse-title text-xl font-medium">
          <div className="flex items-center justify-evenly">
            <div className="item-center flex flex-col items-center justify-center gap-y-3">
              <Avatar src={sender.profile_picture} />
              <span className="text-xl">{sender.name}</span>
            </div>
            <GiDiamondRing className="text-5xl text-accent" />
            <div className="item-center flex flex-col items-center justify-center gap-y-3">
              <Avatar src={receiver.profile_picture} />
              <span className="text-xl">{receiver.name}</span>
            </div>
          </div>
        </summary>
        <div className="divider mx-6"></div>
        <div className="collapse-content  flex flex-col leading-7 sm:flex-row">
          <p className="sm:w-1/2">{youngerMessage}</p>
          <div className="divider divider-vertical sm:divider-horizontal"></div>
          <p className="sm:w-1/2">{girlMessage}</p>
        </div>
      </details>
    </div>
  );
};

export default StoryListItem;
