import { useQuery } from "@tanstack/react-query";
import users from "api/users";
import ms from "ms";
import UserItem from "types/UserItem";
import queryClient from "./queryClient";
import Badges from "types/Badges";

export const refreshLikesQuery = () =>
  queryClient.invalidateQueries({ queryKey: ["likes"] });

const useLikes = () => {
  const query = useQuery({
    queryKey: ["likes"],
    queryFn: () => users.getLikes().then((res) => res.data?.reverse()),
    staleTime: ms("2h"),
  });

  const setBadge = (set: (badges: Badges) => Badges) => {
    const badges = queryClient.getQueryData<Badges>(["badges"])!;
    queryClient.setQueryData<Badges>(["badges"], () => set(badges));
  };

  const addLike = (user: UserItem) => {
    queryClient.setQueryData<UserItem[]>(["likes"], (users) => [
      user,
      ...(users || []),
    ]);
    setBadge((b) => ({ ...b, likes: b.likes + 1 }));
  };

  const setLikes = (users: UserItem[]) => {
    queryClient.setQueryData<UserItem[]>(["likes"], () => users);
    setBadge((b) => ({ ...b, likes: users.length }));
  };

  return {
    addLike,
    setLikes,
    likes: query.data!,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export default useLikes;
