import EmptyPage from "components/Empty";
import List from "components/lists/List";
import UserSkeletons from "components/theme/skeleton/users";
import constants from "configs/constants";
import useStories from "queries/stories";
import { GiDiamondRing } from "react-icons/gi";
import StoryListItem from "./StoryListItem";

const StoriesPage = () => {
  const { stories, isLoading, isError, error, ...actions } = useStories();

  if (isLoading) return <UserSkeletons />;
  if (isError) throw new Error(error?.name);
  if (!stories.length)
    return (
      <EmptyPage content={constants.EMPTY_HISTORY_LIST} Icon={GiDiamondRing} />
    );

  return (
    <div>
      <List
        infinite
        data={stories}
        hasNextPage={actions.hasNextPage}
        next={actions.fetchNextPage}
        title={constants.HISTORY_USERS}
        keyExtractor={(item, index) => item.id || index.toString()}
        renderItem={(item) => {
          const { younger, girl, younger_message, girl_message } = item;
          return (
            <StoryListItem
              sender={younger}
              receiver={girl}
              youngerMessage={younger_message!}
              girlMessage={girl_message!}
            />
          );
        }}
      />
    </div>
  );
};

export default StoriesPage;
