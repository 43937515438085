import classNames from "classnames";
import { FC, useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import useUtils from "stores/utils";

interface Props {
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  value?: string | number | readonly string[] | undefined;
  setValue?: (v: string) => void;
  inputClassName?: string;
  containerClassName?: string;
  error?: boolean;
  errorMessage?: string;
  size?: "input-xs" | "input-sm" | "input-md" | "input-lg";
  Icon?: FC<{ className?: string }>;
  alphabetic?: boolean;
}

const Input = ({
  type = "text",
  placeholder = "",
  value,
  setValue,
  containerClassName,
  inputClassName,
  error,
  size = "input-md",
  errorMessage,
  Icon,
  alphabetic,
  ...rest
}: Props) => {
  const theme = useUtils((store) => store.theme);
  const isDark = theme === "dark";
  const [inputType, setInputType] = useState(type);
  const EyeIcon = inputType === "password" ? BsEye : BsEyeSlash;

  return (
    <>
      <div
        className={classNames({
          "mb-0 flex w-full flex-row items-center overflow-hidden rounded-lg border-[0.1rem] border-gray-300 px-0 focus:outline-none":
            true,
          [size]: Boolean(size),
          "border-error": Boolean(error),
          [`${containerClassName}`]: Boolean(containerClassName),
        })}
      >
        {Icon && <Icon className="mr-2 text-xl" />}
        <input
          type={inputType}
          value={value}
          onChange={(e) => {
            const text = e.target.value;
            if (
              alphabetic &&
              /^(?![0-9\u0660-\u0669 ])[0-9\u0660-\u0669\u0621-\u064A ]*$/.test(
                text,
              )
            )
              setValue!(text);
            else if (!alphabetic) setValue!(text);
          }}
          placeholder={placeholder}
          {...rest}
          className={`input w-full focus:outline-none ${size} ${
            isDark ? " placeholder:text-gray-500" : " placeholder:text-gray-400"
          } ${inputClassName} ${Icon ? "pr-2" : "pr-4"}`}
        />
        {type === "password" && (
          <EyeIcon
            className="ml-5 cursor-pointer text-xl"
            onClick={() =>
              setInputType(inputType === "password" ? "text" : "password")
            }
          />
        )}
      </div>
      {errorMessage && (
        <span className={`mr-2 text-sm text-error`}>{errorMessage}</span>
      )}
    </>
  );
};

export default Input;
