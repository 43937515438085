import { useMutation, useQuery } from "@tanstack/react-query";
import favorites from "api/favorites";
import ms from "ms";
import UserItem from "types/UserItem";
import queryClient from "./queryClient";
import User from "types/User";
import refactor from "utils/refactor";
import useAuth from "hooks/useAuth";

export const refreshFavoritesQuery = () =>
  queryClient.invalidateQueries({ queryKey: ["favorites"] });

const useFavorites = () => {
  const { isAuth, isGuardian } = useAuth();

  const query = useQuery({
    queryKey: ["favorites"],
    staleTime: ms("2h"),
    queryFn: () =>
      isAuth && !isGuardian
        ? favorites.get().then((res) => res.data?.reverse())
        : Promise.resolve<UserItem[]>([]),
  });

  const setFavorites = (users: UserItem[]) => {
    queryClient.setQueryData<UserItem[]>(["favorites"], () => users);
  };

  const { mutate: removeFavorite } = useMutation({
    mutationFn: favorites.remove,

    onMutate: (id: string) => {
      const previous = queryClient.getQueryData(["favorites"]) || [];
      queryClient.setQueryData<UserItem[]>(
        ["favorites"],
        (users) => users?.filter(({ _id }) => _id !== id),
      );
      return { previous };
    },

    onError: (_, __, context) => {
      if (!context) return;
      queryClient.setQueryData<UserItem[]>(
        ["favorites"],
        context.previous as UserItem[],
      );
    },
  });

  const { mutate: addFavorite } = useMutation({
    mutationFn: (user: User) => favorites.add(user.account._id),

    onMutate: (user: User) => {
      const previous = queryClient.getQueryData(["favorites"]) || [];
      queryClient.setQueryData<UserItem[]>(["favorites"], (users) => [
        refactor.userItem(user),
        ...(users || []),
      ]);

      return { previous };
    },

    onError: (_, __, context) => {
      if (!context) return;
      queryClient.setQueryData<UserItem[]>(
        ["favorites"],
        context.previous as UserItem[],
      );
    },
  });

  return {
    setFavorites,
    addFavorite,
    removeFavorite,
    favorites: query.data || [],
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
    fvBadge: query.data?.length || 0,
  };
};

export default useFavorites;
