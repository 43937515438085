import jwtDecode from "jwt-decode";
import dayjs from "dayjs";

interface TokenPayload {
  exp: number;
}

function decodeToken<T>(token: string): {
  valid: boolean;
  data?: T | null;
  error?: string;
} {
  try {
    const decoded = jwtDecode<T & TokenPayload>(token);
    const currentTime = dayjs().unix();
    if (decoded.exp && currentTime > decoded.exp) {
      return { valid: false, data: null, error: "Token has expired" };
    }

    return { valid: true, data: decoded };
  } catch (error) {
    return { valid: false, data: null, error: "Failed to decode token" };
  }
}

export default decodeToken;
