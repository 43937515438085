import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AlertProvider from "components/core/Alert";
import queryClient from "queries/queryClient";
import React from "react";
import ReactDOM from "react-dom/client";
import { Notifications } from "react-push-notification";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import App from "./App";
import "./index.css";
import Router from "./routes";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    `https://${import.meta.env.VITE_BASE_URL}`,
  ],
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Notifications />
      <AlertProvider>
        <App>
          <Router />
        </App>
      </AlertProvider>

      <div style={{ direction: "ltr" }}>
        <ReactQueryDevtools position="left" initialIsOpen={false} />
      </div>
    </QueryClientProvider>
  </React.StrictMode>,
);
