import apiConfig from "configs/api-v2";
import TEngagement from "types/TEngagement";
import TGuardian from "types/TGuardian";
import apiClient from "./client-v2";
import RealInfo from "types/TRealInfo";

const endpoints = apiConfig.endpoints;

type Props = (TGuardian | RealInfo) & { id: string };

const setGuardianInfo = ({ id, ...data }: Props) =>
  apiClient.post(`${endpoints.engage_guardian}/${id}`, data);

const get = () => apiClient.get<TEngagement[]>(endpoints.engage);

const send = (user: string) =>
  apiClient.post<unknown, Error>(`${endpoints.engage}/send/${user}`);

const accept = (user: string) =>
  apiClient.post(`${endpoints.engage}/accept/${user}`);

const cancel = (user: string, reason = "") =>
  apiClient.post(`${endpoints.engage}/cancel/${user}`, { reason });

const remove = (user: string) =>
  apiClient.delete(`${endpoints.engage}/${user}`);

const swear = (user: string) =>
  apiClient.post(`${endpoints.engage_swear}/${user}`);

export default {
  setGuardianInfo,
  get,
  send,
  accept,
  cancel,
  remove,
  swear,
};
