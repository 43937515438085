import Button from "components/core/Button";
import constants from "configs/constants";
import { useNavigate } from "react-router-dom";

import flowers from "assets/flowers.png";

const Search = () => {
  const navigate = useNavigate();

  return (
    <section className="mt-12 flex items-center overflow-hidden">
      <div className="w-full">
        <p className="mr-5 text-center text-3xl font-bold">
          {constants.SEARCH}
        </p>

        <div className="divider divider-vertical  mx-auto my-0 w-20"></div>

        <div className="pt-5 max-md:px-5 md:mx-auto md:w-[60%]">
          <div className="relative z-10 flex flex-col gap-5 rounded-xl border-2 bg-base-100 p-4 pt-8 opacity-90 md:px-12">
            <div
              className="absolute right-0 top-0 z-10 h-full w-full cursor-pointer bg-transparent"
              onClick={() => navigate("/guest/search")}
            />
            <div className="flex  flex-col md:flex-row">
              <div className="flex grow flex-col gap-y-3">
                {/* <div className="flex items-center gap-x-3">
                  <p className="w-[40%] text-xl font-semibold">النوع</p>
                  <span className="h-8 w-full rounded-3xl border-[1px] border-primary p-1" />
                </div> */}

                <div className="flex items-center gap-x-3">
                  <p className="w-[40%] text-xl font-semibold">الجنسية</p>
                  <span className="h-8 w-full rounded-3xl border-[1px] border-primary p-1" />
                </div>

                <div className="flex items-center gap-x-3">
                  <p className="w-[40%] text-xl font-semibold">الاقامة</p>
                  <span className="h-8 w-full rounded-3xl border-[1px] border-primary p-1" />
                </div>

                <div className="flex items-center gap-x-3">
                  <p className="w-[40%] text-xl font-semibold">القبيلة</p>
                  <span className="h-8 w-full rounded-3xl border-[1px] border-primary p-1" />
                </div>
              </div>

              <div className="divider divider-horizontal mx-6"></div>

              <div className="flex grow flex-col gap-y-3 max-md:mt-3">
                <div className="flex items-center gap-x-3">
                  <p className="w-[40%] text-xl font-semibold">التدين</p>
                  <span className="h-8 w-full rounded-3xl border-[1px] border-primary p-1" />
                </div>

                <div className="flex items-center gap-x-3">
                  <p className="w-[40%] text-xl font-semibold">البشرة</p>
                  <span className="h-8 w-full rounded-3xl border-[1px] border-primary p-1" />
                </div>

                {/* <div className="flex items-center gap-x-3">
                  <p className="w-[40%] text-xl font-semibold">الحالة</p>
                  <span className="h-8 w-full rounded-3xl border-[1px] border-primary p-1" />
                </div> */}

                <div className="flex w-full items-center justify-between gap-x-3">
                  <p className="w-[40%] text-lg font-semibold">الجمال</p>
                  <span className="h-8 w-full rounded-3xl border-[1px] border-primary p-1" />
                </div>
              </div>
            </div>

            <Button
              outline
              className="mt-8"
              color="btn-primary"
              onClick={() => {}}
            >
              {constants.SEARCH}
            </Button>
          </div>
        </div>
      </div>

      <img className="absolute left-0  h-96 w-96 md:flex" src={flowers} />
    </section>
  );
};

export default Search;
