import { useEffect, useRef } from "react";
import { ThreadState } from "stores/thread";

const useThreadRef = (th: ThreadState) => {
  const thread = useRef<ThreadState>(th);
  const setThreadRef = (th: ThreadState) => (thread.current = th);

  useEffect(() => {
    if (th.visible) setThreadRef(th);
  }, [th]);

  return { thread, setThreadRef };
};

export default useThreadRef;
