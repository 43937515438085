import { yupResolver } from "@hookform/resolvers/yup";
import auth from "api/auth";
import preferences from "api/preferences";
import profiles from "api/profiles";
import EmptyPage from "components/Empty";
import Badge from "components/core/Badge";
import Button from "components/core/Button";
import FormFiled from "components/forms/FormFiled";
import schema from "components/forms/schema";
import constants from "configs/constants";
import useAuth from "hooks/useAuth";
import useForm from "hooks/useForm";
import useToast from "hooks/useToast";
import { alafaf } from "pages/support";
import SupportThread from "pages/support/Thread";
import useSupport from "queries/support";
import useUser from "queries/user";
import { useEffect, useState } from "react";
import { BiSupport } from "react-icons/bi";
import { FaRegAddressCard } from "react-icons/fa";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { MdPendingActions } from "react-icons/md";
import { RiUser3Line } from "react-icons/ri";
import { TbUserCancel } from "react-icons/tb";
import { Navigate } from "react-router-dom";
import useThread from "stores/thread";
import hasKeys from "utils/hasKeys";
import { AnyObject, InferType, ObjectSchema } from "yup";

type SchemaType = ObjectSchema<
  { [x: string]: any },
  AnyObject,
  { [x: string]: any },
  ""
>;

export default function Pending() {
  const { isAuth, isGuardian } = useAuth();

  const th = useThread((s) => s.thread);
  const setThread = useThread((s) => s.setThread);
  const resetThread = useThread((s) => s.resetThread);

  const { chats } = useSupport();
  const chat = chats[0];
  const lastMessage = chat
    ? chat.messages[chat.messages.length - 1]
    : undefined;
  const isSeen = chat ? (lastMessage?.is_from_user ? true : chat.seen) : true;

  const { user, removed, pending, canceled, unAccepted, updateUser } =
    useUser();
  const isPending =
    pending ||
    user.pending_name ||
    user.profile?.pending_bio ||
    user.preferences?.pending_description;

  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const unAcceptedFiled = user.createErrors || [];
  const pendingSchema = schema.unAccepted(
    isGuardian,
    unAcceptedFiled,
  ) as SchemaType;

  const { control, errors, handleSubmit } = useForm<
    InferType<typeof pendingSchema>
  >({
    resolver: yupResolver(pendingSchema),
    defaultValues: {
      name: "",
      bio: "",
      description: "",
      national_number: "",
      birthday: "",
    },
  });

  async function onSubmit(values: InferType<typeof pendingSchema>) {
    resetThread();

    setLoading(true);
    type Key = keyof typeof values;
    const array = unAcceptedFiled.map((e) => ({ [e]: values[e as Key] }));
    const props = Object.assign({}, ...array);
    const update: any = {};

    if (props.name) {
      const valid = await auth.checkUserExistence({
        username: props.name,
        guardian: isGuardian,
      });

      if (valid.ok) {
        if (isGuardian) {
          update.name = props.name;
          update.status = "pending";
        } else update.pending_name = props.name;
      } else {
        setLoading(false);
        if (valid.data && valid.data.message)
          toast.error(valid.data.message, "bottom");
        else toast.error(constants.ERRORS.UNEXPECTED_ERROR, "bottom");
        return;
      }
    }

    if (props.birthday) update.birthday = props.birthday;
    if (props.national_number) update.national_number = props.national_number;

    if (hasKeys(update)) {
      updateUser(update);
    }

    if (props.bio) {
      profiles.update({ bio: props.bio });
      updateUser({ profile: { ...user.profile, pending_bio: props.bio } });
    }
    if (props.description) {
      updateUser({
        preferences: {
          ...user.preferences,
          pending_description: props.description,
        },
      });
      preferences.update({ description: props.description });
    }

    setTimeout(() => setLoading(false), 2000);
  }

  function openThread() {
    if (chat) {
      setThread({
        id: chat._id,
        receiver: { ...alafaf, name: chat.subject },
        visible: true,
        fromSupport: true,
        showMessageStatus: false,
      });
    }
  }

  const SupportIcon = () => {
    return (
      <label
        tabIndex={0}
        className="fixed left-0 mx-3 mt-3 cursor-pointer md:mx-6"
        onClick={openThread}
      >
        <div className="indicator flex flex-col items-center">
          <BiSupport className="h-10 w-10 text-accent max-md:h-8 max-md:w-8" />
          <p className="text-[10px]">{constants.BAR_SUPPORT}</p>
          <Badge value={!isSeen ? 1 : 0} className="m-1" />
        </div>
      </label>
    );
  };

  useEffect(() => {
    openThread();
  }, [chats]);

  if (!isAuth) return <Navigate to="/login" />;
  else if (!unAccepted) return <Navigate to="/" />;
  return (
    <>
      <SupportIcon />

      {th.visible && <SupportThread />}

      {removed ? (
        <EmptyPage
          Icon={() => (
            <TbUserCancel className="m-auto self-center text-center text-8xl text-primary" />
          )}
          content={constants.ERRORS.USER_REMOVED}
        />
      ) : isPending ? (
        <EmptyPage
          Icon={() => (
            <MdPendingActions className="m-auto self-center text-center text-8xl text-sky-500" />
          )}
          content={constants.UNDER_PROCESSING}
        />
      ) : canceled && !!unAcceptedFiled.length ? (
        <div className="mx-auto mt-20 flex w-[75%] flex-col items-center gap-y-6 overflow-y-auto pb-28 md:mt-10 md:w-[45%] md:pb-5 ">
          <p className="self-start text-xs sm:text-base">
            {constants.ERRORS.REUPLOAD_YOUR_INFO}
          </p>
          {unAcceptedFiled.includes("name") && (
            <div className="w-full">
              <FormFiled
                type="text"
                name="name"
                alphabetic
                maxLength={20}
                Icon={RiUser3Line}
                control={control}
                error={errors.name}
                placeholder={
                  isGuardian ? constants.FOURTH_NAME : constants.NAME
                }
                containerClassName="border-primary"
              />

              {unAcceptedFiled.includes("name") && !errors.name && (
                <span className="mr-2 text-xs text-success">
                  {constants.NAME_IN_ARABIC_ONLY}
                </span>
              )}
            </div>
          )}

          {unAcceptedFiled.includes("national_number") && (
            <div className="w-full">
              <FormFiled
                type="number"
                name="national_number"
                Icon={FaRegAddressCard}
                control={control}
                error={errors.national_number}
                placeholder={constants.NATIONAL_NUM}
                containerClassName="border-primary"
                visible={unAcceptedFiled.includes("national_number")}
              />
            </div>
          )}

          {unAcceptedFiled.includes("birthday") && (
            <div className="w-full">
              <FormFiled
                type="date"
                name="birthday"
                control={control}
                error={errors.birthday}
                placeholder={constants.BIRTHDAY}
                Icon={IoCalendarNumberOutline}
                containerClassName="border-primary"
                visible={unAcceptedFiled.includes("birthday")}
              />
            </div>
          )}

          {unAcceptedFiled.includes("bio") && (
            <div className="w-full">
              <FormFiled
                type="textArea"
                name="bio"
                control={control}
                error={errors.bio}
                placeholder={constants.WRITE_BIO}
                Icon={IoCalendarNumberOutline}
                textAreaClassName="border-primary h-28"
                containerClassName="w-full"
                visible={unAcceptedFiled.includes("bio")}
              />
            </div>
          )}

          {unAcceptedFiled.includes("description") && (
            <div className="w-full">
              <FormFiled
                type="textArea"
                name="description"
                control={control}
                error={errors.description}
                placeholder={constants.WRITE_DESC}
                Icon={IoCalendarNumberOutline}
                textAreaClassName="border-primary h-28"
                containerClassName="w-full"
                visible={unAcceptedFiled.includes("description")}
              />
            </div>
          )}

          <Button
            outline
            loading={loading}
            className="w-[80%]"
            color="btn-primary"
            onClick={handleSubmit(onSubmit)}
          >
            {constants.SEND}
          </Button>
        </div>
      ) : null}
    </>
  );
}
