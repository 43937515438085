import classNames from "classnames";
import Avatar from "components/Avatar";
import { BiLike } from "react-icons/bi";
import { GiDiamondRing } from "react-icons/gi";

interface Props {
  title: string;
  content: string;
  image?: string;
  time: string;
  isRead: boolean;
  type: string;
  onClick?: () => void;
  onDelete?: () => void;
}

const NotifyListItem = (props: Props) => {
  const { title, content, image, time, isRead, type, onClick } = props;
  const icon = type.includes("engage") ? (
    <GiDiamondRing className="text-xl text-accent" />
  ) : type === "like" ? (
    <BiLike className="text-xl text-accent" />
  ) : undefined;

  return (
    <div
      onClick={onClick}
      className={classNames({
        "mx-auto my-3 flex h-16 w-[95%] cursor-pointer flex-row items-center justify-evenly rounded-2xl border-[0.1rem] border-gray-200 p-1 shadow-lg transition-all hover:border-gray-200 hover:opacity-90 hover:shadow-xl":
          true,
        "bg-white": isRead,
        "indicator bg-gray-200 font-bold": !isRead,
      })}
    >
      {/* {!isRead && (
        <span className="badge indicator-item badge-primary badge-xs "></span>
      )} */}
      <div className="bg-transparent px-1 pt-1">
        <Avatar size="sm" src={image} />
      </div>
      <div className="w-[65%]">
        <h3 className="text-md mb-1">{title}</h3>
        <div className="flex  items-center">
          <p className="w-96 truncate text-xs">{content}</p>
        </div>
      </div>

      <div
        className={`flex h-full flex-col items-center justify-center ${
          time && "justify-between"
        }`}
      >
        {!!time && <span className="mt-1 text-[9px]">{time}</span>}
        {!!icon && (
          <div
            className={`flex w-16 items-center justify-center text-2xl text-primary ${
              time && "mb-4"
            }`}
          >
            {icon}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotifyListItem;
