import EmptyPage from "components/Empty";
import List from "components/lists/List";
import UserListItem from "components/lists/UserListItem";
import UserSkeletons from "components/theme/skeleton/users";
import constants from "configs/constants";
import useFavorites from "queries/favorites";
import { AiFillHeart } from "react-icons/ai";

const FavoritesPage = () => {
  const { isLoading, isError, error, favorites } = useFavorites();

  if (isLoading) return <UserSkeletons />;
  if (isError) throw new Error(error?.name);
  if (!favorites.length)
    return (
      <EmptyPage content={constants.EMPTY_FAVORITE_LIST} Icon={AiFillHeart} />
    );

  return (
    <div>
      <List
        data={favorites}
        title={constants.FAVORITES_USERS}
        keyExtractor={(item) => item.uid}
        renderItem={(item) => {
          return (
            <UserListItem
              age={item.age}
              city={item.city}
              country={item.country}
              guardian={item.is_guardian}
              iconFor="favorites"
              image={item.profile_picture}
              status={item.relationship_status}
              to={`profile/${item.uid}`}
              username={item.name}
            />
          );
        }}
      />
    </div>
  );
};

export default FavoritesPage;
