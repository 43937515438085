import api from "configs/api-v2";
import apiClient from "./client-v2";
import TCountry from "types/TCountry";
import TOptions from "types/TOptions";

const endpoints = api.endpoints;

const avatars = (gender: "male" | "female") =>
  apiClient.get<string[], Error>(`${endpoints.profile_pictures}/${gender}`);
const countries = () => apiClient.get<TCountry[], Error>(endpoints.countries);
const options = () => apiClient.get<TOptions, Error>(endpoints.options);

export default {
  avatars,
  countries,
  options,
};
