import SecureStore from "components/secure-storage";

type Value = string | object | number | boolean;

const key = "token";
const refresh_key = "refresh";

const storeToken = (token: Value, refresh?: Value) => {
  SecureStore.setItem(key, token);
  if (refresh) SecureStore.setItem(refresh_key, refresh);
};

const getToken = () => {
  return SecureStore.getItem(key);
};

const getRefreshToken = () => {
  return SecureStore.getItem(refresh_key);
};

const removeToken = () => {
  SecureStore.removeItem(key);
  SecureStore.removeItem(refresh_key);
  SecureStore.removeItem("guardian");
};

const store = (key: string, value: Value) => {
  window.dispatchEvent(new Event("storage"));
  return SecureStore.setItem(key, value);
};

const get = (key: string) => {
  const result = SecureStore.getItem(key);
  return result ? result : false;
};

const remove = (key: string) => {
  SecureStore.removeItem(key);
};

export default {
  getToken,
  getRefreshToken,
  removeToken,
  storeToken,
  get,
  store,
  remove,
};
