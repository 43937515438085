const notObj = (obj: any) => typeof obj !== "object" || obj === null;

function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) return true;

  if (notObj(obj1) || notObj(obj2)) return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key)) return false;

    if (!deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
}

export default deepEqual;
