import auth from "api/auth";
import refreshPage from "./refreshPage";
import storage from "./storage";

const userLogout = async () => {
  const response = await auth.logout();
  if (response.ok && response.data?.guardian) {
    const { refresh_token, token } = response.data!;
    storage.store("guardian", true);
    storage.storeToken(token, refresh_token);
  } else storage.removeToken();

  refreshPage("/");
};

export default userLogout;
