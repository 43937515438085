import { yupResolver } from "@hookform/resolvers/yup";
import users from "api/users";
import Button from "components/core/Button";
import FormFiled from "components/forms/FormFiled";
import schema from "components/forms/schema";
import Loading from "components/theme/Loading";
import constants from "configs/constants";
import useForm from "hooks/useForm";
import useToast from "hooks/useToast";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface Password {
  current_password: string;
  new_password: string;
  confirm_new_password: string;
}

const EditPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const { handleSubmit, errors, control } = useForm<Password>({
    resolver: yupResolver(schema.changePassword),
  });

  const onSubmit = async (values: Password) => {
    setLoading(true);

    const changed = await users.changePassword({
      current_password: values.current_password,
      new_password: values.new_password,
    });

    if (changed.ok) {
      toast.success(constants.TOAST.UPDATE_PASS_SUCCESSFUL);
      navigate("/");
    } else {
      changed.data?.message
        ? toast.error(changed.data.message)
        : toast.error(constants.ERRORS.UNEXPECTED_ERROR);
      return setLoading(false);
    }

    setLoading(false);
  };

  return (
    <>
      {loading && (
        <div className="relative">
          <Loading visible />
        </div>
      )}
      <div className="flex flex-col gap-y-5 px-8 md:px-16">
        <p className="my-6 text-center text-xl font-bold">
          {constants.CHANGE_PASSWORD}
        </p>
        <FormFiled
          type="password"
          control={control}
          name="current_password"
          error={errors.current_password}
          placeholder={constants.PRE_PASSWORD}
        />
        <FormFiled
          type="password"
          control={control}
          name="new_password"
          error={errors.new_password}
          placeholder={constants.NEW_PASSWORD}
        />
        <FormFiled
          type="password"
          control={control}
          name="confirm_new_password"
          error={errors.confirm_new_password}
          placeholder={constants.CONFIRM_PASSWORD}
        />

        <Button outline onClick={handleSubmit(onSubmit)}>
          {constants.EDIT}
        </Button>
      </div>
    </>
  );
};

export default EditPassword;
