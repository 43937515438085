import logo from "assets/logo.svg";
import Button from "components/core/Button";
import ThemeSwitcher from "components/theme/Switcher";
import constants from "configs/constants";
import useAuth from "hooks/useAuth";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { RiMenu3Line } from "react-icons/ri";
import { NavLink, Navigate, useNavigate } from "react-router-dom";

const links = [
  { path: "/contacts", name: "اتصل بنا" },
  { path: "/privacy", name: "السياسة الخصوصية" },
  { path: "/terms", name: "الشروط والاحكام" },
];

const LandingNavbar = () => {
  const [side, setShowSidebar] = useState(false);
  const { isAuth } = useAuth();
  const navigate = useNavigate();

  if (isAuth) return <Navigate to="/" />;
  return (
    <div className="navbar sticky top-0 z-50 m-0 mb-5 grid grid-cols-[20%,78%] grid-rows-1 bg-base-100 p-0 shadow-lg">
      <div className="hidden items-center gap-2 text-2xl font-bold text-primary md:flex">
        <img src={logo} className="w-10" />
        {constants.APP_NAME}
      </div>

      <label className="btn btn-circle swap swap-rotate justify-center border-0 bg-transparent md:hidden">
        <input
          type="checkbox"
          checked={side}
          onChange={(e) => setShowSidebar(e.target.checked)}
        />
        <RiMenu3Line className="icon swap-off fill-current" />
        <AiOutlineClose className="icon swap-on fill-current" />
      </label>

      <nav
        className={`flex h-full w-full flex-1 animate-fade-up flex-col items-start gap-2 animate-once max-md:order-3 max-md:col-span-3 max-md:pb-2 md:flex-row md:justify-end ${
          !side && "hidden md:flex"
        }`}
      >
        {links.map(({ path, name }, index) => {
          return (
            <NavLink
              target="_blank"
              to={path}
              key={index.toString()}
              className={() =>
                `link-hover link flex h-full items-center px-2 text-base font-bold md:link-primary hover:no-underline max-md:py-1 max-md:hover:text-primary`
              }
            >
              <span>{name}</span>
            </NavLink>
          );
        })}
        <div className="flex h-full items-center">
          <Button
            outline
            size="btn-sm"
            className="h-10 !rounded-3xl max-md:order-1"
            color="btn-primary"
            onClick={() => navigate("/login")}
          >
            {constants.LOGIN}
          </Button>
        </div>
      </nav>

      <div className="flex items-center justify-center max-md:col-start-3">
        <ThemeSwitcher />
      </div>
    </div>
  );
};

export default LandingNavbar;
