import apiConfig from "configs/api-v2";
import apiClient from "./client-v2";
import TChat from "types/TChat";
import Message from "types/Message";

const endpoints = apiConfig.endpoints;

interface IHide {
  receiver: string;
  messageId: string;
  fromAll?: boolean;
}

interface ISend {
  id: string;
  type: string;
  receiver: string;
  message: string;
}

interface IReport {
  receiver: string;
  messageId: string;
  message: string;
}

const create = (receiver: string) =>
  apiClient.post<{ id: string }>(endpoints.create_chat, { receiver });

const get = (page: number) =>
  apiClient.get<TChat[]>(endpoints.chats + "?page=" + page);

const getThread = (threadId: string, page: number) =>
  apiClient.get<Message[]>(
    endpoints.thread + "?id=" + threadId + "&page=" + page,
  );

const unsent = (data: { userId: string; messageId: string }) =>
  apiClient.post(endpoints.message_unsent, data);

const remove = (chatId: string) =>
  apiClient.delete(`${endpoints.chats}/${chatId}`);

const send = (data: ISend) => apiClient.post(endpoints.send_message, data);
const hide = (data: IHide) => apiClient.post(endpoints.hid_message, data);
const read = (chatId: string) =>
  apiClient.post(endpoints.read_message.replace(":id", chatId));
const report = (data: IReport) =>
  apiClient.post(endpoints.report_message, data);

export default {
  create,
  get,
  getThread,
  unsent,
  remove,
  send,
  hide,
  read,
  report,
};
