import constants from "configs/constants";
import { FC } from "react";

interface Props {
  isCash: boolean;
  setTab: (v: "cash" | "tap") => void;
}

const Tabs: FC<Props> = ({ isCash, setTab }) => {
  return (
    <div className="tabs-xs sm:tabs-sm md:tabs-md tabs-boxed mx-auto w-fit !rounded-3xl border-[0.1rem] bg-white">
      <div
        onClick={() => setTab("tap")}
        className={`tab hover:text-gray-500 ${
          !isCash && "tab-active !rounded-2xl  font-semibold !text-white"
        }`}
      >
        {constants.TAP_PAY}
      </div>
      <div
        onClick={() => setTab("cash")}
        className={`tab ${
          isCash && "tab-active !rounded-2xl font-semibold !text-white"
        }`}
      >
        {constants.CASH_PAY}
      </div>
    </div>
  );
};

export default Tabs;
