import apiConfig from "configs/api-v2";
import UserItem from "types/UserItem";
import apiClient from "./client-v2";

const endpoints = apiConfig.endpoints;

const get = () => apiClient.get<UserItem[]>(endpoints.favorites);
const add = (id: string) => apiClient.post(`${endpoints.favorites}/${id}`);
const remove = (id: string) => apiClient.delete(`${endpoints.favorites}/${id}`);

export default {
  get,
  add,
  remove,
};
