import Button from "components/core/Button";
import constants from "configs/constants";
import { useEffect } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { Navigate, useLoaderData } from "react-router-dom";
import refreshPage from "utils/refreshPage";
import storage from "utils/storage";

interface Build {
  gender?: string;
  token?: string;
  is_guardian?: boolean;
  route?: string;
  activeStep?: number;
}

const BuildFinished = () => {
  const state = useLoaderData() as Build;
  const isPropsPassed = state && Object.keys(state).length;

  useEffect(() => {
    if (state.route === "/build/finished") storage.remove("build");
  }, []);

  if (!isPropsPassed) return <Navigate to="/login" />;
  if (state.route !== "/build/finished")
    return <Navigate to={state.route || "/"} />;

  return (
    <div className="flex h-screen flex-col items-center justify-end">
      <div className="no-scrollbar flex h-screen items-center justify-center">
        <article className="prose text-center">
          <BsFillCheckCircleFill className="m-auto self-center text-center text-8xl text-primary" />
          <p>{constants.WILL_CONTACT_YOU_AFTER_12H}</p>
          <div className="mt-5 flex justify-center">
            <Button
              onClick={() => refreshPage("/")}
              outline
              className="!w-96 !rounded-full"
              color="btn-primary"
            >
              {constants.OK}
            </Button>
          </div>
        </article>
      </div>
    </div>
  );
};

export default BuildFinished;
