import api from "configs/api-v2";
import TStory from "types/TStory";
import apiClient from "./client-v2";

interface Props {
  younger: string;
  girl: string;
  younger_message?: string | undefined;
  girl_message?: string | undefined;
}

const add = (data: Props) => apiClient.post(api.endpoints.stories, data);

const get = (page: number) =>
  apiClient.get<TStory[]>(`${api.endpoints.stories}?page=${page}`);

const getForLanding = () =>
  apiClient.get<TStory[]>(api.endpoints.landing_stories);

export default {
  get,
  add,
  getForLanding,
};
