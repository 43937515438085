import { useQuery } from "@tanstack/react-query";
import users from "api/users";
import ms from "ms";
import Badges from "types/Badges";
import UserItem from "types/UserItem";
import queryClient from "./queryClient";

export const refreshVisitsQuery = () =>
  queryClient.invalidateQueries({ queryKey: ["visits"] });

const useVisits = () => {
  const query = useQuery({
    queryKey: ["visits"],
    queryFn: () => users.getVisits().then((res) => res.data),
    staleTime: ms("2h"),
  });

  // socketIo.on("visit", async (not) => {
  //   const visitsApi = await users.whoVisitMe();
  //   if (visitsApi.ok) setVisits(visitsApi.data.reverse());
  //   updatingNotifications(not);
  // });

  const setBadge = (set: (badges: Badges) => Badges) => {
    const badges = queryClient.getQueryData<Badges>(["badges"])!;
    queryClient.setQueryData<Badges>(["badges"], () => set(badges));
  };

  const addVisitor = (user: UserItem) => {
    queryClient.setQueryData<UserItem[]>(["visits"], (users) => [
      user,
      ...(users || []),
    ]);
    setBadge((b) => ({ ...b, visits: b.visits + 1 }));
  };

  const setVisits = (users: UserItem[]) => {
    queryClient.setQueryData<UserItem[]>(["visits"], () => users);
    setBadge((b) => ({ ...b, visits: users.length }));
  };

  return {
    addVisitor,
    setVisits,
    visits: query.data!,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export default useVisits;
