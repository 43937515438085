import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
} from "@tanstack/react-query";
import support from "api/support";
import constants from "configs/constants";
import dayjs from "dayjs";
import useToast from "hooks/useToast";
import { chunk } from "lodash";
import ms from "ms";
import Badges from "types/Badges";
import TSupport from "types/TSupport";
import queryClient from "./queryClient";

const LIMIT_CHAT_LIST = 20;

interface Add {
  id: string;
  message: string;
  subject?: string;
  is_from_user?: boolean;
}

const isQueryExist = () => {
  const hasQuery = queryClient.getQueryData<InfiniteData<Array<TSupport>>>([
    "support",
  ]);

  if (!hasQuery) return false;

  return !!hasQuery.pages;
};

export const refreshSupportQuery = () =>
  queryClient.invalidateQueries({ queryKey: ["support"] });

const setBadge = (set: (badges: Badges) => Badges) => {
  const badges = queryClient.getQueryData<Badges>(["badges"])!;
  queryClient.setQueryData<Badges>(["badges"], () => set(badges));
};

const useSupport = () => {
  const toast = useToast();

  const query = useInfiniteQuery({
    queryKey: ["support"],
    queryFn: ({ pageParam }) => support.get(pageParam).then((res) => res.data!),
    getNextPageParam: (lastPage, allPage) => {
      return lastPage?.length === LIMIT_CHAT_LIST
        ? allPage.length + 1
        : undefined;
    },
    select: (data) => data.pages.flatMap((x) => x),
    initialPageParam: 1,
    staleTime: ms("2h"),
  });

  const { mutate: create } = useMutation({
    mutationFn: support.create,

    onSuccess: (response) => {
      toast.success(constants.MESSAGE_SENT),
        queryClient.setQueryData<InfiniteData<Array<TSupport>>>(
          ["support"],
          (e) => {
            return {
              ...e!,
              pages: chunk(
                [response.data!, ...e!.pages.flat()],
                LIMIT_CHAT_LIST,
              ),
            };
          },
        );
    },
  });

  const { mutate: removeConversation } = useMutation({
    mutationFn: support.remove,
    onMutate: (id) => {
      queryClient.setQueryData<InfiniteData<Array<TSupport>>>(
        ["support"],
        (support) => {
          const result = support!.pages.flat().filter((s) => s._id !== id);
          return { ...support!, pages: chunk(result, LIMIT_CHAT_LIST) };
        },
      );
    },

    onSuccess: () => toast.success(constants.CONVERSATION_DELETED),
  });

  const { mutate: removeAll } = useMutation({
    mutationFn: support.removeAll,

    onSuccess: () => {
      refreshSupportQuery();
      toast.success(constants.ALL_CONVERSATION_DELETED);
    },
  });

  const { mutate: readMessage } = useMutation({
    mutationFn: support.read,

    onMutate: (id) => {
      setBadge((b) => ({ ...b, support: Math.max(0, b.support - 1) }));

      queryClient.setQueryData<InfiniteData<Array<TSupport>>>(
        ["support"],
        (support) => {
          const result = support!.pages
            .flat()
            .map((s) => (s._id === id ? { ...s, seen: true } : s));
          return { ...support!, pages: chunk(result, LIMIT_CHAT_LIST) };
        },
      );
    },
  });

  return {
    ...query,
    create,
    removeConversation,
    removeAll,
    readMessage,
    chats: query.data || [],
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
    isSuccess: query.isSuccess,
  };
};

export const addSupportMessage = (props: Add) => {
  if (!isQueryExist()) return;

  const { id, subject, message, is_from_user = false } = props;
  const support =
    queryClient
      .getQueryData<InfiniteData<Array<TSupport>>>(["support"])
      ?.pages.flat() || [];
  const isChatExist = !!support.flat().find((s) => s._id === id);
  const record = { text: message, time: dayjs().toISOString(), is_from_user };

  let result: TSupport[] = [];
  if (isChatExist) {
    result = support.map((s) =>
      s._id === id
        ? { ...s, seen: false, messages: [...s.messages, record] }
        : s,
    );
  } else if (subject) {
    result = [
      { _id: id, subject, seen: false, messages: [record] },
      ...support,
    ];
  }

  if (!is_from_user) {
    setBadge((b) => ({
      ...b,
      support: Math.max(
        0,
        result.filter(
          (s) => !s.seen && !s.messages[s.messages.length - 1].is_from_user,
        ).length,
      ),
    }));
  }

  queryClient.setQueryData<InfiniteData<Array<TSupport>>>(["support"], (s) => ({
    ...s!,
    pages: chunk(result, LIMIT_CHAT_LIST),
  }));
};

export default useSupport;
