import SelectPicker from "components/picker/Select";
import constants from "configs/constants";
import useStorage from "hooks/useStorage";
import { useState } from "react";
import TProps from "../../TProps";

interface Value {
  male: string[];
  female: string[];
}

interface Props {
  withoutAge?: boolean;
  user: TProps;
  setUser: (v: TProps) => void;
  errors: string[];
  setErrors: (v: string[]) => void;
}

const StepOne = ({
  withoutAge = false,
  user,
  setUser,
  errors,
  setErrors,
}: Props) => {
  const { options, countries } = useStorage();

  const gender = user.gender;
  const { nationality, age, country, city, clan, origin } = user;
  const [citiesList, SetCitiesList] = useState(
    countries.find((e) => e.label === country)?.cities || [],
  );

  const nationality_options =
    options.nationality_options[gender! as keyof Value];
  const clan_options = options.clan_options[gender! as keyof Value];
  const origin_options = options.origin_options[gender! as keyof Value];

  const countrySelected = (name: string) => {
    setUser({ ...user, country: name });
    validate("country");
    SetCitiesList(countries.find((e) => e.label === name)!.cities);
  };

  const ageList = () => Array.from({ length: 53 }, (_, i) => i + 18);

  const validate = (propName: string) => {
    if (errors.includes(propName))
      setErrors(errors.filter((e) => e !== propName));
  };

  return (
    <div className="px-4 py-2">
      <div className="mt-5 flex w-full max-md:mt-5 max-md:flex-col max-md:items-center">
        <div className="flex w-1/2 flex-col gap-5 max-md:w-[85%]">
          <SelectPicker
            withSearch
            options={nationality_options}
            label={constants.NATIONALITY}
            value={nationality!}
            error={errors.includes("nationality")}
            setValue={(v) => {
              validate("nationality");
              setUser({ ...user, nationality: v as string });
            }}
          />

          <SelectPicker
            withSearch
            options={countries.map((e) => e.label)}
            label={constants.COUNTRY}
            value={country!}
            error={errors.includes("country")}
            setValue={(v) => countrySelected(v as string)}
          />

          <SelectPicker
            withSearch
            options={citiesList}
            label={constants.CITY}
            value={city!}
            error={errors.includes("city")}
            setValue={(v) => {
              validate("city");
              setUser({ ...user, city: v as string });
            }}
          />
        </div>

        <div className="divider divider-horizontal mx-10 mt-5 max-md:hidden"></div>

        <div className="flex w-1/2 flex-col gap-5 max-md:mt-5 max-md:w-[85%]">
          {!withoutAge && (
            <SelectPicker
              withSearch
              options={ageList().map((e) => e.toString())}
              label={constants.AGE}
              value={age! as string}
              error={errors.includes("age")}
              setValue={(v) => {
                validate("age");
                setUser({ ...user, age: +v });
              }}
            />
          )}

          <SelectPicker
            options={clan_options}
            label={constants.CLAN}
            value={clan!}
            error={errors.includes("clan")}
            setValue={(v) => {
              validate("clan");
              setUser({ ...user, clan: v as string });
            }}
          />

          <SelectPicker
            options={origin_options}
            label={constants.ORIGIN}
            value={origin!}
            error={errors.includes("origin")}
            setValue={(v) => {
              validate("origin");
              setUser({ ...user, origin: v as string });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StepOne;
