interface Props {
  checked: boolean;
  toggle: (v: boolean) => void;
  title: string;
  className?: string;
  horizontal?: boolean;
  size?: "toggle-xs" | "toggle-sm" | "toggle-md" | "toggle-lg";
}

const Switch = ({
  title,
  checked,
  toggle,
  horizontal = false,
  size,
  className,
}: Props) => {
  const dir = horizontal ? "flex-row-reverse" : "flex-col";

  return (
    <div className={`flex ${dir} ${className} items-center gap-2`}>
      <label className="text-sm">{title}</label>
      <input
        type="checkbox"
        className={`toggle toggle-success ${size}`}
        onChange={(e) => toggle(e.target.checked)}
        checked={checked}
      />
    </div>
  );
};

export default Switch;
