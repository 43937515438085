import Avatar from "components/Avatar";
import { FC, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import EngageAction from "./EngageAction";
import {
  ACCEPT_MESSAGE,
  CANCEL_MESSAGE,
  REJECT_MESSAGE,
  STATUS_VALUES,
} from "./strings";

interface Props {
  title: string;
  subTitle: string;
  isMale: boolean;
  guardian?: boolean;
  status: string | null;
  reason?: string;
  image?: string;
  acceptAction?: () => void;
  cancelAction?: () => void;
  deleteAction?: () => void;
  resendAction?: () => void;
  getInfoAction?: () => void;
  marriageAction?: () => void;
  onMessage?: () => void;
  logoutAction?: () => void;
  Content?: FC;
  to: string;
  loading?: boolean;
  hover?: boolean;
}

const EngageListItem = (props: Props) => {
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const { to, title, subTitle, image, status = false } = props;
  const { getInfoAction, deleteAction, marriageAction, logoutAction } = props;
  const { acceptAction, cancelAction, Content } = props;
  const { onMessage, reason, resendAction, guardian } = props;

  const statusValue = STATUS_VALUES.find((e) => e.label === status)!.value;
  const statusColor = STATUS_VALUES.find((e) => e.label === status)!.color;
  const inEngagedNav = pathname.includes("engaged");

  const success = status === "success";
  const rejectAction = status === "rejected";
  const canceledStatus =
    status === "canceled" || (status === "rejected" && props.isMale);

  const isDeletedAction = deleteAction;
  const pendingHandling =
    !inEngagedNav && (!isDeletedAction || canceledStatus) && show;
  const canceledHandling =
    !inEngagedNav && isDeletedAction && !canceledStatus && show;

  // const isNotTouchable = inEngagedNav || (isDeletedAction && !canceledStatus);
  const isCollapsed = show || inEngagedNav;

  if (props.loading) {
    return (
      <div className=" mx-auto my-4 flex w-[90%] animate-pulse">
        <div className="flex-shrink-0">
          <span className="ml-2 mt-1 block h-16 w-16 rounded-full bg-base-300"></span>
        </div>

        <div className="ml-4 mt-1 w-full">
          <h3 className="mb-2 h-4 w-[40%] rounded-md bg-base-300"></h3>
          <h3 className="h-10 rounded-md bg-base-300"></h3>
        </div>
      </div>
    );
  }

  return (
    <div
      tabIndex={0}
      className={`${
        !inEngagedNav && "collapse collapse-arrow cursor-pointer"
      } ${
        isCollapsed ? "collapse-open" : "collapse-close"
      } mx-auto my-5 w-[90%] rounded-3xl border-[0.1rem] border-gray-200 bg-white p-1 shadow-md transition-all hover:border-gray-200 hover:opacity-95 hover:shadow-lg`}
    >
      <div
        onClick={!inEngagedNav ? () => setShow(!show) : undefined}
        className="collapse-title flex flex-row items-center p-1 text-xl font-medium md:pl-10"
      >
        <Link to={to} className="mt-1 cursor-pointer px-2 pl-3">
          <Avatar src={image} bordered={guardian} />
        </Link>
        <div className="flex-1">
          <h3 className="mb-2 w-3/4 truncate text-lg md:text-xl">{title}</h3>
          <div className="flex items-center gap-1 truncate text-xs">
            {subTitle}
          </div>
        </div>
        {status && (
          <div className={`z-10 flex flex-col items-center justify-center`}>
            <div
              className={`${statusColor} flex items-center justify-center truncate rounded-xl px-2 py-1 text-sm text-base-100`}
            >
              {statusValue}
            </div>
          </div>
        )}
      </div>

      <div className={`${isCollapsed && "collapse-content cursor-default"}`}>
        {isCollapsed && <div className="divider mx-8 my-0 mb-1"></div>}
        {canceledHandling && (
          <EngageAction
            acceptAction={acceptAction}
            cancelAction={cancelAction}
            deleteAction={deleteAction}
            getInfoAction={getInfoAction}
            marriageAction={marriageAction}
            isMale={props.isMale}
          />
        )}

        {inEngagedNav && (
          <div>
            {Content && <Content />}
            <EngageAction
              acceptAction={acceptAction}
              cancelAction={cancelAction}
              cancelTitle="الغاء"
              deleteAction={deleteAction}
              getInfoAction={getInfoAction}
              isMale={props.isMale}
              logoutAction={logoutAction}
              marriageAction={marriageAction}
              marriedTitle={success ? "كتابة تجربة" : undefined}
              messageAction={onMessage}
            />
          </div>
        )}

        {pendingHandling && (
          <div>
            <div>
              <span className="font-bold leading-7">
                {canceledStatus
                  ? !rejectAction
                    ? CANCEL_MESSAGE(reason!)
                    : REJECT_MESSAGE(title)
                  : ACCEPT_MESSAGE(title)}
              </span>
            </div>

            <div>
              <EngageAction
                acceptAction={acceptAction}
                cancelAction={cancelAction}
                deleteAction={deleteAction}
                getInfoAction={getInfoAction}
                marriageAction={marriageAction}
                resendAction={resendAction}
                isMale={props.isMale}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EngageListItem;
