import SelectPicker from "components/picker/Select";
import constants from "configs/constants";
import useStorage from "hooks/useStorage";
import TProps from "../../TProps";
import Props from "../../profileProps";

interface Value {
  male: string[];
  female: string[];
}

interface Props {
  user: TProps;
  setUser: (v: TProps) => void;
  errors: string[];
  setErrors: (v: string[]) => void;
}

const StepTow = ({ user, setUser, errors, setErrors }: Props) => {
  const { options } = useStorage();

  const gender = user.gender as keyof Value;
  const { relationship_status, children } = user;
  const { financial_status, health, occupation } = user;

  const childrenList = Props.children;
  const relationship_options = options.relationship_status_options[gender];
  const financial_status_options = options.financial_status_options[gender];
  const health_options = options.health_options[gender];
  const occupations_options = options.occupations_options[gender];

  const validate = (propName: string) => {
    if (errors.includes(propName))
      setErrors(errors.filter((e) => e !== propName));
  };

  return (
    <div className="px-4 py-2">
      <div className="mt-5 flex w-full max-md:mt-5 max-md:flex-col max-md:items-center max-md:pb-8">
        <div className="flex w-1/2 flex-col gap-5 max-md:w-[85%]">
          <SelectPicker
            options={relationship_options}
            label={constants.RELATIONSHIP_STATUS}
            value={relationship_status!}
            error={errors.includes("relationship_status")}
            setValue={(v) => {
              validate("relationship_status");
              setUser({ ...user, relationship_status: v as string });
            }}
          />

          <SelectPicker
            options={childrenList.map((e) => e.label.toString())}
            label={constants.CHILDREN}
            value={(children as { label: string })?.label || (children as null)}
            error={errors.includes("children")}
            setValue={(v) => {
              validate("children");
              setUser({
                ...user,
                children: childrenList.find((e) => e.label === v),
              });
            }}
          />

          <SelectPicker
            options={financial_status_options}
            label={constants.FINANCIAL_STATUS}
            value={financial_status!}
            error={errors.includes("financial_status")}
            setValue={(v) => {
              validate("financial_status");
              setUser({ ...user, financial_status: v as string });
            }}
          />
        </div>

        <div className="divider divider-horizontal mx-10 mt-5 max-md:hidden"></div>

        <div className="flex w-1/2 flex-col gap-5 max-md:mt-5 max-md:w-[85%]">
          <SelectPicker
            options={health_options}
            label={constants.HEALTH}
            value={health!}
            error={errors.includes("health")}
            setValue={(v) => {
              validate("health");
              setUser({ ...user, health: v as string });
            }}
          />

          <SelectPicker
            withSearch
            options={occupations_options}
            label={constants.OCCUPATION}
            value={occupation!}
            error={errors.includes("occupation")}
            setValue={(v) => {
              validate("occupation");
              setUser({ ...user, occupation: v as string });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StepTow;
