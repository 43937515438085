import { yupResolver } from "@hookform/resolvers/yup";
import auth from "api/auth";
import Avatar from "components/Avatar";
import Button from "components/core/Button";
import FormFiled from "components/forms/FormFiled";
import schema from "components/forms/schema";
import constants from "configs/constants";
import useForm from "hooks/useForm";
import useStorage from "hooks/useStorage";
import useToast from "hooks/useToast";
import useUser from "queries/user";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import hasKeys from "utils/hasKeys";

interface Props {
  email?: string;
  age?: number;
  profile_picture?: string;
  is_email_verified?: boolean;
}

interface Update {
  email: string;
  age: number;
}

const EditGeneral = () => {
  const { user, updateUser } = useUser();
  const { images } = useStorage();

  const [profile_picture, setPicture] = useState(user.profile_picture);
  const [error, setError] = useState("");
  const toast = useToast();
  const navigate = useNavigate();

  const { handleSubmit, errors, control } = useForm<Update>({
    resolver: yupResolver(schema.updateProfile),
    defaultValues: {
      name: user.name,
      email: user.email,
      age: "" + user.age,
    },
  });

  const onSubmit = async (values: Update) => {
    setError("");
    const props: Props = {};
    if (values.age !== user.age) props.age = values.age;
    if (values.email !== user.email) props.email = values.email;
    if (profile_picture && user.profile_picture !== profile_picture)
      props.profile_picture = profile_picture;

    if (hasKeys(props)) {
      if (props.email) {
        const response = await auth.checkUserExistence({ email: props.email });
        if (response.ok) props.is_email_verified = false;
        else {
          setError(response.data?.message || "");
          return setTimeout(() => setError(""), 2000);
        }
      }

      updateUser(props);
      toast.success(constants.SUCCESS.UPDATE);
      navigate("/");
    }
  };

  return (
    <div className="flex flex-col gap-y-5 px-16 lg:px-32">
      <p className="my-6 text-center text-xl font-bold">
        {constants.GENERAL_UPDATE}
      </p>

      <div className="self-center">
        <Avatar
          size="lg"
          images={images[user.gender as keyof typeof images]}
          src={profile_picture}
          onEdit={setPicture}
        />
      </div>

      <FormFiled
        type="text"
        name="email"
        control={control}
        error={errors.email}
        placeholder={constants.EMAIL}
      />
      <FormFiled
        type="number"
        control={control}
        name="age"
        error={errors.age}
        placeholder={constants.AGE}
      />

      {!!error && <p className="mr-3 text-xs text-error">{error}</p>}

      <Button color="btn-primary" outline onClick={handleSubmit(onSubmit)}>
        {constants.EDIT}
      </Button>
    </div>
  );
};

export default EditGeneral;
