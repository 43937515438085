import constants from "configs/constants";
import { FC } from "react";
import TProfile from "types/TProfile";

const ProfileLocation: FC<{ profile: TProfile }> = ({ profile }) => {
  return (
    <div className="flex flex-wrap justify-center gap-x-2">
      <div className="flex items-center gap-x-1">
        <span className="text-accent">{`${constants.NATIONALITY}:`}</span>
        <span className="text-primary">{profile.nationality}</span>
      </div>
      <span className="text-accent">|</span>
      <div className="flex items-center gap-x-1">
        <span className="text-accent">{`${constants.COUNTRY}:`}</span>
        <span className="text-primary">{profile.country}</span>
      </div>
      <span className="text-accent">|</span>

      <div className="flex items-center gap-x-1">
        <span className="text-accent">{`${constants.CITY}:`}</span>
        <span className="text-primary">{profile.city}</span>
      </div>
    </div>
  );
};

export default ProfileLocation;
